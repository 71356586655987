import React, { createContext, useState, useEffect } from "react";
import axios from "axios";
import { api_url, decodedToken, decodedUserID } from "../../api/config";

// Create a new context for managing wishlist items
export const ProductContext = createContext({});

// Custom provider component for the wishlist context
export const ProductContextProvider = ({ children }) => {
  const [showCreateProduct, setShowCreateProduct] = useState(false);
  const ls = typeof window !== "undefined" ? window.localStorage : null;
  const [wishlistItems, setWishlistItems] = useState(() => {
    // Initialize wishlistItems with data from local storage if available
    if (ls && ls.getItem('wishlist_items')) {
      return JSON.parse(ls.getItem('wishlist_items'));
    }
    return [];
  });

  useEffect(() => {
    // Update local storage with the updated wishlist items
    if (ls && wishlistItems.length >= 0) {
      ls.setItem('wishlist_items', JSON.stringify(wishlistItems));
    }
  }, [wishlistItems, ls]);

  useEffect(() => {

  const fetchWishlist = async () => {
    try {
      const response = await axios.get(`${api_url}/wishlists/${decodedUserID}`);
      const wishlist = response.data.wishList;
        
      console.log("wishlist", wishlist.products_wish_list)
      if (wishlist && wishlist.products_wish_list) {
        const formattedWish = wishlist.products_wish_list.map(item => ({
            productId: item.productId,
        }));
        setWishlistItems(formattedWish);
      } else {
        createWishlist([]);
        console.log("created a new wishlist")
      }
    } catch (error) {
      console.error("Error fetching wishlist:", error);
      if (error.response && error.response.status === 404) {
        createWishlist([]);
        console.log("created a new wishlist")
      }
    }
  };

  if (decodedUserID && decodedToken) {
    fetchWishlist();
  }
}, []);


  const createWishlist = async (products_wish_list) => {
        try {
            const response = await axios.post(`${api_url}/wishlists`,
                {
                    owner: decodedUserID,
                    products_wish_list,
                },
            );
            if (response.status !== 201) {
                console.error("Failed to create Wishlist:", response.data.error);
            }
        } catch (error) {
            console.error("Error creating Wishlist:", error);
        }
    };



const updateWishlist = async (products_wish_list) => {
    console.log("UpdateWishlist", products_wish_list)
    try {
      const response = await axios.put(`${api_url}/wishlists/${decodedUserID}`,
        {
          owner: decodedUserID,
          products_wish_list,
        },
      );
  
      if (response.status !== 200) {
        console.error("Failed to update Wishlist:", response.data.error);
      }
    } catch (error) {
      console.error("Error updating Wishlist:", error);
    }
  };


    const addToWishlist = (productId) => {
        const existingWishlist = wishlistItems.find(item => item.productId === productId);
        if(!existingWishlist){
            const updatedWishlist = [...wishlistItems, { productId }];
            setWishlistItems(updatedWishlist);
            updateWishlist(updatedWishlist)
        }
    };

    const removeFromWishlist = (productId) => {
        const existingWishlist = wishlistItems.find(item => item.productId === productId);
        if(existingWishlist){
            const updatedWishlist = wishlistItems.filter(item => item.productId !== productId);
            setWishlistItems(updatedWishlist);
            updateWishlist(updatedWishlist); // Update cart on the server
        }
    };

  return (
    <ProductContext.Provider
      value={{
        wishlistItems,
        addToWishlist,
        removeFromWishlist,
        showCreateProduct,
        setShowCreateProduct,
      }}
    >
      {children}
    </ProductContext.Provider>
  );
};
