import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Center from "../components/Center";
import BarsIcon from "../components/icons/Bars";
import SearchIcon from "../components/icons/SearchIcon";
import { isLogged, logout, isAdmin } from "../auth/AuthContext";
import Cookies from "js-cookie";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog, faHeart, faSignOutAlt, faUser, faAddressCard, faMoneyCheckDollar, faCirclePlus } from "@fortawesome/free-solid-svg-icons";
import SearchPage from "../pages/Search";

const Header = () => {
  const [mobileNavActive, setMobileNavActive] = useState(false);
  const [profileDropdownOpen, setProfileDropdownOpen] = useState(false);
  const [isUserAdmin, setIsUserAdmin] = useState(false);
  const [isSearchModalOpen, setIsSearchModalOpen] = useState(false); // State to control search modal visibility
  const location = useLocation();
  const navigate = useNavigate();
  const dropdownRef = useRef(null);

  const username = Cookies.get("username");
  const profile = Cookies.get("profile");

  const handleTabChange = (tab) => {
    navigate(`/account?tab=${tab}`);
  };

  const handleLogout = () => {
    logout();
  };

  const truncatedUsername = username ? (username.length > 7 ? username.slice(0, 7) + "..." : username) : "";

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setProfileDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (isLogged()) {
      setIsUserAdmin(isAdmin());
    }
  }, []);

  return (
    <header className="bg-gray-900 sticky top-0 z-30 shadow-lg bg-opacity-90">
      <Center>
        <div className="flex justify-between items-center py-5 px-4">
          <Link to="/" className="text-white no-underline font-bold text-2xl">
            PicLab
          </Link>
          <nav
            className={`${
              mobileNavActive ? "block" : "hidden"
            } fixed inset-0 bg-gray-900 p-5 pt-20 z-40 md:flex md:static md:p-0 md:gap-5 md:bg-transparent`}
          >
            <Link
              to="/"
              className={`block text-lg ${
                location.pathname === "/" ? "text-white" : "text-gray-400"
              } hover:text-white md:py-0 py-2`}
              aria-label="Home"
            >
              Home
            </Link>
            <Link
              to="/products"
              className={`block text-lg ${
                location.pathname === "/products" ? "text-white" : "text-gray-400"
              } hover:text-white md:py-0 py-2`}
              aria-label="All products"
            >
              All products
            </Link>
            <Link
              to="/categories"
              className={`block text-lg ${
                location.pathname === "/categories" ? "text-white" : "text-gray-400"
              } hover:text-white md:py-0 py-2`}
              aria-label="Categories"
            >
              Categories
            </Link>
            <Link
              to="/cart"
              className={`block text-lg ${
                location.pathname === "/cart" ? "text-white" : "text-gray-400"
              } hover:text-white md:py-0 py-2`}
              aria-label="Cart"
            >
              Cart
            </Link>
            <Link
              to="/aboutus"
              className={`block text-lg ${
                location.pathname === "/aboutus" ? "text-white" : "text-gray-400"
              } hover:text-white md:py-0 py-2`}
              aria-label="About us"
            >
              About us
            </Link>
            {isLogged() ? (
              <div className="relative" ref={dropdownRef}>
                <button
                  onClick={() => setProfileDropdownOpen(!profileDropdownOpen)}
                  className="flex items-center focus:outline-none"
                  aria-haspopup="true"
                  aria-expanded={profileDropdownOpen}
                >
                  <img src={profile} alt={username} className="w-8 h-8 rounded-full" />
                  <span className="ml-2 text-white">Welcome, {truncatedUsername}</span>
                </button>
                {profileDropdownOpen && (
                  <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg z-50">
                    <div className="py-2 font-semibold">
                      <button
                        onClick={() => {
                          setProfileDropdownOpen(false);
                          handleTabChange("Account details");
                        }}
                        className="block px-4 py-2 text-sm text-gray-700 hover:text-gray-900 hover:bg-gray-100 w-full text-left"
                      >
                        <FontAwesomeIcon icon={faUser} className="mr-2" />
                        Account Details
                      </button>
                      {isUserAdmin && (
                        <button
                          onClick={() => {
                            setProfileDropdownOpen(false);
                            handleTabChange("Dashboard");
                          }}
                          className="block px-4 py-2 text-sm text-gray-700 hover:text-gray-900 hover:bg-gray-100 w-full text-left"
                        >
                          <FontAwesomeIcon icon={faCog} className="mr-2" />
                          Dashboard
                        </button>
                      )}
                      <button
                        onClick={() => {
                          setProfileDropdownOpen(false);
                          handleTabChange("Create");
                        }}
                        className="block px-4 py-2 text-sm text-gray-700 hover:text-gray-900 hover:bg-gray-100 w-full text-left"
                      >
                        <FontAwesomeIcon icon={faCirclePlus} className="mr-2" />
                        Create
                      </button>
                      <button
                        onClick={() => {
                          setProfileDropdownOpen(false);
                          handleTabChange("Address");
                        }}
                        className="block px-4 py-2 text-sm text-gray-700 hover:text-gray-900 hover:bg-gray-100 w-full text-left"
                      >
                        <FontAwesomeIcon icon={faAddressCard} className="mr-2" />
                        Address
                      </button>
                      <button
                        onClick={() => {
                          setProfileDropdownOpen(false);
                          handleTabChange("Orders");
                        }}
                        className="block px-4 py-2 text-sm text-gray-700 hover:text-gray-900 hover:bg-gray-100 w-full text-left"
                      >
                        <FontAwesomeIcon icon={faMoneyCheckDollar} className="mr-2" />
                        Orders
                      </button>
                      <button
                        onClick={() => {
                          setProfileDropdownOpen(false);
                          handleTabChange("Wishlist");
                        }}
                        className="block px-4 py-2 text-sm text-gray-700 hover:text-gray-900 hover:bg-gray-100 w-full text-left"
                      >
                        <FontAwesomeIcon icon={faHeart} className="mr-2 text-red-500" />
                        Wishlist
                      </button>
                      <button
                        onClick={() => {
                          handleLogout();
                        }}
                        className="block px-4 py-2 text-sm text-gray-700 hover:text-gray-900 hover:bg-gray-100 w-full text-left"
                      >
                        <FontAwesomeIcon icon={faSignOutAlt} className="mr-2 text-red-600" />
                        Logout
                      </button>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <Link
                to="/login"
                className={`block text-lg ${
                  location.pathname === "/login" ? "text-white" : "text-gray-400"
                } hover:text-white md:py-0 py-2`}
                aria-label="Login"
              >
                <FontAwesomeIcon icon={faUser} className="w-8 h-8 rounded bg-gray-300"/>
              </Link>
            )}
          </nav>
          <div className="flex items-center space-x-3 z-40">
            <button onClick={() => setIsSearchModalOpen(true)} className="text-gray-400 hover:text-white" aria-label="Search">
              <SearchIcon className="w-4 h-4" />
            </button>
            <button
              onClick={() => setMobileNavActive((prev) => !prev)}
              className="text-white md:hidden"
              aria-label="Toggle Navigation"
            >
              <BarsIcon className="w-6 h-6" />
            </button>
          </div>
        </div>
      </Center>
      {isSearchModalOpen && <SearchPage onClose={() => setIsSearchModalOpen(false)} />} {/* Render the search modal */}
    </header>
  );
};

export default Header;
