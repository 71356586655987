import React from "react";
import camera from "../assets/camera.gif";
import Center from "./Center";

const Catching = () => {
  return (
    <div className="w-full bg-white px-4 py-6">
      <Center>
        <div className="mx-auto grid max-w-screen-xl md:grid-cols-2">
          <img className="mx-auto my-4 w-[500px]" src={camera} alt="Camera" />
          <div className="flex flex-col items-start justify-center">
            <p className="font-bold uppercase text-emerald-400">
              PICLAB Community
            </p>
            <h1 className="mt-2 text-2xl font-bold text-black sm:text-3xl md:text-4xl">
              Share and Sell Your Photos
            </h1>
            <p className="mt-2 text-black">
              Join PICLAB to connect with photographers, share your work, and sell your photos.
            </p>
            <div className="py-5 self-center">
              <button
                className="px-6 py-3 font-bold text-black bg-emerald-400 rounded-lg transition-transform transform hover:scale-105 hover:bg-emerald-500 active:bg-emerald-600"
              >
                Join Us
              </button>
            </div>
          </div>
        </div>
      </Center>
    </div>
  );
};

export default Catching;
