import React, { useEffect, useState } from 'react';
import { notification } from 'antd';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import { api_url, decodedToken } from '../../api/config';

const Success = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [paymentStatus, setPaymentStatus] = useState(null);

  async function checkPayment(sessionId) {
    try {
      const response = await axios.post(`${api_url}/checkout/check-payment`, {
        sessionId
      }, {
        headers: {
          'Content-Type': 'application/json',
          "Authorization": decodedToken,
        },
      });

      const data = await response.data;
    //   console.log('Payment status:', data.payment_status);

      setPaymentStatus(data.payment_status);

      if (data.payment_status === 'paid') {
        notification.success({ message: 'Payment successful! Redirecting to home page...' });
        setTimeout(() => {
          navigate('/');
        }, 5000);
      } else {
        notification.error({ message: 'Payment not completed. Order has been cancelled.' });
        await axios.post(`${api_url}/checkout/update-order-status`, {
          sessionId,
          status: 'pending'
        }, {
          headers: {
            'Content-Type': 'application/json',
            "Authorization": decodedToken,
          },
        });
      }
    } catch (error) {
    //   console.error('Error checking payment status:', error);
      notification.error({ message: 'Error checking payment status. Please try again.' });
      setTimeout(() => {
        navigate('/checkout');
      }, 5000);
    }
  }



  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const sessionId = urlParams.get('session_id');

    if (sessionId) {
      checkPayment(sessionId);
    }
  }, [location.search]);

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50">
    <div className="max-w-md w-full bg-white p-8 rounded shadow-md">
      <h1 className="text-3xl font-bold mb-6 text-black">
        {paymentStatus === 'paid' ? "Thank you for your purchase!" : "Payment Failed!"}
      </h1>
      <p className="text-lg mb-4 text-black">
        {paymentStatus === 'paid' ? (
          "Your payment was successful! Redirecting you to the home page..."
        ) : (
          "Your payment could not be completed. Redirecting you to the checkout page."
        )}
      </p>
    </div>
  </div>
  );
};

//http://localhost:3000/success?session_id=cs_test_b1zyXNwaLkkz65FyFKlXyVUTcbml5giAfVKam16aeQ6gnRFquCPion7tsn
//http://localhost:3000/success?session_id=cs_test_b1zyXNwaLkkz65FyFKlXyVUTcbml5giAfVKam16aeQ6gnRFquCPion7tsm

export default Success;
