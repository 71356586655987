import React, { useState, useEffect } from "react";
import styled, {keyframes} from "styled-components";
import ProductBox from "./ProductBox";

const dropAnimation = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const StyledRevealWrapper = styled.div`
  opacity: 0;
  animation: ${dropAnimation} 0.5s ease forwards;
  animation-delay: ${props => props.delay || "0s"};
`;


const StyledProductsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  @media screen and (min-width: 768px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
`;

export default function ProductsGrid({products,wishedProducts=[]}) {
  console.log("productsGrid", products);
  return (
    <StyledProductsGrid interval={100}>
      {products?.length > 0 && products.map((product,index) => (
        <StyledRevealWrapper key={product._id} delay={index*50}>
          <ProductBox {...product}
                      wished={wishedProducts.includes(product._id)} />
        </StyledRevealWrapper>
      ))}
    </StyledProductsGrid>
  );
}