import { useContext, useEffect } from 'react';
import { CartContext } from './CartContext';
import { AddressContext } from './contexts/AddressContext';
import { useLocation, useNavigate } from "react-router-dom";
import { notification } from 'antd';
import axios from 'axios';
import { api_url, decodedUserID, decodedToken } from '../api/config';

const CartCheckoutDetails = ({ products, productsTotal }) => {

  const { cartProducts } = useContext(CartContext);
  const { addresses } = useContext(AddressContext);

  const navigate = useNavigate();
  const location = useLocation();

  // Calculate the 10% commission fee
  const commissionFee = productsTotal * 0.05;
  // Calculate the final total amount
  const finalTotalAmount = productsTotal;

  const checkoutHandler = () => {
    if (location.pathname === "/cart") {
      navigate("/checkout");
    } else {
      if (addresses.length === 0) {
        notification.error({ message: "Please add an Address to Proceed." });
        return;
      }
      checkOut();
    }
  };

  async function checkOut() {
    const response = await axios.post(`${api_url}/checkout/create-payment`,
    {
      userId: decodedUserID,
      products: cartProducts,
      amount: finalTotalAmount,  // Use the final total amount with commission fee
      address: {
        name: addresses[0].name,
        country: addresses[0].country,
        address: addresses[0].address,
        city: addresses[0].city,
        postalCode: addresses[0].postalCode
      },
    },
    {
      headers: {
        "Authorization": decodedToken,
      },
    });
    console.log("checkout", response.data)
    if (response.data) {
      window.location = response.data;
    }
  }

  // // Function to check payment status
  // async function checkPayment(sessionId) {
  //   try {
  //       const response = await axios.post(`${api_url}/checkout/check-payment`, {
  //           sessionId
  //       }, {
  //           headers: {
  //               'Content-Type': 'application/json',
  //               "Authorization": decodedToken,
  //           },
  //       });

  //       const data = await response.data;
  //       console.log('Payment status:', data.payment_status);

  //       if (data.payment_status === 'paid') {
  //         notification.success({ message: 'Payment successful! Order placed.' });
  //       } else {
  //         notification.error({ message: 'Payment not completed. Please try again.' });
  //       }
  //   } catch (error) {
  //       console.error('Error checking payment status:', error);
  //       notification.error({ message: 'Error checking payment status. Please try again.' });
  //   }
  // }

  // useEffect(() => {
  //   const urlParams = new URLSearchParams(window.location.search);
  //   const sessionId = urlParams.get('session_id');

  //   if (sessionId) {
  //     checkPayment(sessionId);
  //   }
  // }, [location.search]);

  return (
    <div className="sticky top-20 bg-white rounded-lg p-6 shadow-md">
      <h2 className="text-black text-lg font-semibold mb-4">Price Summary</h2>
      <div className="mb-4">
        {products && products.map((product) => (
          <div className="flex justify-between mb-2" key={product._id}>
            <p className="text-sm text-gray-600">
              {product.title} x {cartProducts.filter(item => item.productId === product._id).reduce((total, item) => total + item.quantity, 0)}
            </p>
            <p className="text-sm text-gray-600">
              ${cartProducts
                .filter(item => item.productId === product._id)
                .reduce((total, item) => total + item.quantity * product.price, 0)}
            </p>
          </div>
        ))}
        <hr className="my-4" />
        <div className="flex justify-between mb-2">
          <p className="text-sm text-gray-600">Products Total</p>
          <p className="text-sm text-gray-600">${productsTotal}</p>
        </div>
        <div className="flex justify-between mb-2">
          <p className="text-sm text-gray-600">Commission Fee (5%) Included</p>
        </div>
        <hr className="my-4" />
        <div className="flex justify-between text-lg font-semibold text-gray-800">
          <p>Total Amount</p>
          <p>${finalTotalAmount.toFixed(2)}</p>
        </div>
      </div>
      <button
        className="w-full px-5 py-2.5 text-xs lg:text-sm font-bold text-center text-white bg-cyan-900 rounded-lg transition-transform transform hover:scale-105 hover:bg-cyan-950 active:ring-cyan-950"
        onClick={checkoutHandler}
      >
        {location.pathname === "/cart" ? "Check Out" : "Place Order"}
      </button>
    </div>
  );
};

export default CartCheckoutDetails;
