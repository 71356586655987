// src/pages/AccountPage.js
import React, { useContext, useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import { useLocation } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Tabs from "../components/Tabs";
import Spinner from "../components/Spinner";
import axios from "axios";
import Center from "../components/Center";
import ProductBox from "../components/ProductBox";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import { isAdmin, isLogged } from "../auth/AuthContext";
import AccountDetails from "../components/AccountDetail";
import { api_url } from "../api/config";
import { ProductContext } from "../components/contexts/ProductContext";
import { Button } from "antd";
import Address from "./User/Address";
import AddressForm from "./User/AddressForm";
import { AddressContext } from "../components/contexts/AddressContext";
import ProductManagement from "./User/ProductManagement";
import CreateProduct from "./User/CreateProduct";

const dropAnimation = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const StyledRevealWrapper = styled.div`
  opacity: 0;
  animation: ${dropAnimation} 0.5s ease forwards;
  animation-delay: ${(props) => props.delay || "0s"};
`;

const ColumnsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 40px;
  margin-top: 40px;
  margin-bottom: 40px;

  @media screen and (min-width: 768px) {
    grid-template-columns: 0.5fr 1.5fr;
  }
`;

const Box = styled.div`
  color: black;
  background-color: #fff;
  border-radius: 10px;
  padding: 30px;
`;

const WishedProductsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
`;

export default function AccountPage() {
  const location = useLocation();
  const { wishlistItems } = useContext(ProductContext);
  const { showAddForm, setShowAddForm } = useContext(AddressContext);
  const { showCreateProduct, setShowCreateProduct } = useContext(ProductContext);
  const [session, setSession] = useState(null);
  const [addressLoaded, setAddressLoaded] = useState(true);
  const [wishlistLoaded, setWishlistLoaded] = useState(true);
  const [orderLoaded, setOrderLoaded] = useState(true);
  const [wishedProducts, setWishedProducts] = useState([]);
  const [activeTab, setActiveTab] = useState("Account details");
  const [orders, setOrders] = useState([]);

  const fetchWishlist = async () => {
    setWishlistLoaded(false);

    try {
      const productIds = wishlistItems.map((p) => p.productId);
      const productDetailsPromises = productIds.map((id) =>
        axios.get(`${api_url}/products/find/${id}`)
      );
      const productsResponses = await Promise.all(productDetailsPromises);
      const products = productsResponses.map((response) => response.data);
      setWishedProducts(products);
    } catch (error) {
      console.error("Failed to fetch data", error);
    } finally {
      setWishlistLoaded(true);
    }
  };

  useEffect(() => {
    if (wishlistItems.length > 0 && activeTab === "Wishlist" && isLogged()) {
      fetchWishlist();
    } else {
      setWishedProducts([]);
    }
  }, [wishlistItems, activeTab]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const tab = queryParams.get("tab");
    if (tab) {
      setActiveTab(tab);
    }
  }, [location.search]);

  const renderTabs = () => {
    const tabs = ["Account details", "Create", "Address", "Orders", "Wishlist"];
    if (isAdmin()) {
      tabs.splice(1, 0, "Dashboard");
    }
    return tabs;
  };

  return (
    <>
      <div className="flex flex-col min-h-screen bg-gray-100">
        <Header />
        <main className="flex-grow ">
          <Center>
            <ColumnsWrapper>
              <StyledRevealWrapper delay={0}>
                <Box>
                  <Tabs
                    tabs={renderTabs()}
                    active={activeTab}
                    onChange={setActiveTab}
                  />
                </Box>
              </StyledRevealWrapper>
              <StyledRevealWrapper delay={100}>
                <Box>
                  {activeTab === "Account details" && (
                    isLogged() ? (
                      <AccountDetails />
                    ) : (
                      <>
                        <p>Please login to view account details</p>
                        <Link to="/login">
                          <button>Login</button>
                        </Link>
                      </>
                    )
                  )}
                  {activeTab === "Orders" && (
                    <>
                      {isLogged() ? (
                        <>
                          {!orderLoaded && <Spinner fullWidth={true} />}
                          {orderLoaded && (
                            <div>{orders.length === 0 && <p>No Order history</p>}</div>
                          )}
                        </>
                      ) : (
                        <>
                          <p>Please login to view orders</p>
                          <Link to="/login">
                            <button>Login</button>
                          </Link>
                        </>
                      )}
                    </>
                  )}
                  {activeTab === "Dashboard" && (
                    isAdmin() ? (
                      <Button href={'/admin-dash'}>
                        <button>Go to Dashboard</button>
                      </Button>
                    ) : (
                      <p>You don't have permission!</p>
                    )
                  )}
                  {activeTab === "Create" && (
                    isLogged() ? (
                      <ProductManagement />
                    ) : (
                      <>
                        <p>Please login to view details</p>
                        <Link to="/login">
                          <button>Login</button>
                        </Link>
                      </>
                    )
                  )}
                  {activeTab === "Address" && (
                    isLogged() ? (
                      <Address />
                    ) : (
                      <>
                        <p>Please login to view address details</p>
                        <Link to="/login">
                          <button>Login</button>
                        </Link>
                      </>
                    )
                  )}
                  {activeTab === "Wishlist" && (
                    <>
                      {isLogged() ? (
                        <>
                          {!wishlistLoaded && <Spinner fullWidth={true} />}
                          {wishlistLoaded && (
                            <>
                              <WishedProductsGrid>
                                {wishedProducts.length > 0 &&
                                  wishedProducts.map((wp) => (
                                    <ProductBox key={wp._id} {...wp} />
                                  ))}
                              </WishedProductsGrid>
                              {wishedProducts.length === 0 && (
                                <div className="text-center">
                                  <p>Your wishlist is empty</p>
                                  <Link to="/products">
                                    <button className="w-3/12 mt-3 px-5 py-2.5 text-xs lg:text-sm font-bold text-center text-white bg-cyan-900 rounded-lg transition-transform transform hover:scale-105 hover:bg-cyan-950 active:ring-cyan-950">
                                      Shop Now
                                    </button>
                                  </Link>
                                </div>
                              )}
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          <p>Please login to view wishlist</p>
                          <Link to="/login">
                            <button>Login</button>
                          </Link>
                        </>
                      )}
                    </>
                  )}
                </Box>
              </StyledRevealWrapper>
            </ColumnsWrapper>
          </Center>
        </main>
        <Footer />
      </div>

      {/* Address Form Modal */}
      {showCreateProduct && <CreateProduct />}

      {/* Address Form Modal */}
      {showAddForm && <AddressForm />}
    </>
  );
}
