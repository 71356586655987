import React, { createContext, useState, useEffect } from "react";
import axios from "axios";
import { api_url, decodedUserID, decodedToken } from "../../api/config";

export const AddressContext = createContext();

export const AddressContextProvider = ({ children }) => {
    const [showAddForm, setShowAddForm] = useState(false);
    const [addressToEdit, setAddressToEdit] = useState(null);
    const ls = typeof window !== "undefined" ? window.localStorage : null;
    const [addresses, setAddresses] = useState(() => {
        if (ls && ls.getItem("addresses")) {
            return JSON.parse(ls.getItem("addresses"));
        }
        return [];
    });

    useEffect(() => {
        if (ls && addresses.length >= 0) {
            ls.setItem("addresses", JSON.stringify(addresses));
        }
    }, [addresses, ls]);


    useEffect(() => {

        const fetchAddress = async () => {
          try {
            const response = await axios.get(`${api_url}/address/get/${decodedUserID}`, {
                headers: {
                    Authorization: decodedToken,
                },
            });
            const address = response.data;
              
            console.log("fetch address", address)
            if (address) {
              setAddresses(address);
            } 
          } catch (error) {
            console.error("Error fetching address:", error);
          }
        };
      
        if (decodedUserID && decodedToken) {
          fetchAddress();
        }
      }, []);



      const createAddress= async (addressData) => {
        try {
          const response = await axios.post(`${api_url}/address/`,
            {
                userId: decodedUserID,
                address: addressData.address,
                city: addressData.city,
                country: addressData.country,
                postalCode: addressData.postalCode,
            },
            {
              headers: {
                Authorization: decodedToken,
              },
            }
          );
          setAddresses([...addresses, response.data]);
      
          if (response.status !== 200) {
            console.error("Failed to create Address:", response.data.error);
          }
        } catch (error) {
          console.error("Error creating Address", error);
        }
      };


      const updateAddresses= async (addressData) => {

        try {
          const response = await axios.put(`${api_url}/address/${decodedUserID}`,
            {
              userId: decodedUserID,
              address: addressData.address,
              city: addressData.city,
              country: addressData.country,
              postalCode: addressData.postalCode,
            },
            {
              headers: {
                Authorization: decodedToken,
              },
            }
          );
      
          if (response.status !== 200) {
            console.error("Failed to update Address:", response.data.error);
          }
        } catch (error) {
          console.error("Error updating Address:", error);
        }
      };

    const deleteAddress = async (addressId) => {
        try {
          const response = await axios.delete(`${api_url}/address/${addressId}`, {
            headers: {
              Authorization: decodedToken,
            },
          });
        
          if (response.status !== 200) {
            console.error("Failed to delete Address:", response.data.error);
          }
        } catch (error) {
          console.error("Error deleting Address:", error);
        }
      };

    function saveAddress(addressData) {
        const existingAddress = addresses.find(item => item._id === addressData._id);
        if (!existingAddress) {
          createAddress(addressData);
        }
    }

    function updateAddress(updatedAddress, id) {
        setAddresses(
            addresses.map((address) =>
                address._id === id ? updatedAddress : address
            )
        );
        updateAddresses(updatedAddress);
    }

    function removeAddress(addressId) {
        setAddresses(addresses.filter((address) => address._id !== addressId));
        deleteAddress(addressId);
    }
    

    return (
        <AddressContext.Provider
            value={{
                addresses,
                saveAddress,
                updateAddress,
                removeAddress,
                showAddForm,
                setShowAddForm,
                setAddressToEdit,
                addressToEdit,
            }}
        >
            {children}
        </AddressContext.Provider>
    );
};

export default AddressContext;
