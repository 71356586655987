import React, { useCallback, useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import axios from "axios";
import debounce from "lodash.debounce";
import { Modal, Button, Upload, Progress, Typography } from "antd";
import { UploadOutlined, SearchOutlined, CloseOutlined } from "@ant-design/icons";
import { api_url } from "../api/config";
import ProductsGrid from "../components/ProductsGrid";
import Spinner from "../components/Spinner";

// Keyframes for drop-down effect
const dropDown = keyframes`
  from {
    transform: translateY(-100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
`;

const SearchInput = styled.input`
  background-color: white;
  color: black;
  padding: 10px 15px;
  border-radius: 5px;
  font-size: 1.4rem;
  max-width: 900px;
  width: 100%;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  transition: all 0.3s ease;

  &:focus {
    border-color: #007bff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  }
`;

const ImageCard = styled.div`
  display: flex;
  align-items: center;
  padding: 5px;
  background-color: #f5f5f5;
  border-radius: 5px;
  border: 1px solid #ccc;
  margin-top: 10px;

  img {
    max-width: 50px;
    max-height: 50px;
    border-radius: 5px;
    margin-right: 10px;
  }

  button {
    background: none;
    border: none;
    cursor: pointer;
    color: #ff4d4f;
  }
`;

const ImagePreviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
`;

const ImagePreview = styled.img`
  max-width: 100%;
  max-height: 300px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 10px;
`;

const RemoveImageButton = styled.button`
  background: none;
  border: none;
  color: #007bff;
  cursor: pointer;
  font-size: 1rem;
  text-decoration: underline;

  &:hover {
    color: #0056b3;
  }
`;

const ModalOverlay = styled.div`
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  max-width: 1000px;
  width: 90%;
  max-height: 80%;
  overflow-y: auto;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  animation: ${dropDown} 0.5s ease;
  position: relative;

  h2 {
    color: black;
    font-size: 1.5rem;
    margin-top: 20px;
  }

  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 1.5rem;
    border: none;
    background: none;
    color: #333;
    cursor: pointer;
    transition: color 0.3s ease;

    &:hover {
      color: #007bff;
    }

    z-index: 1001;
  }
`;

const SearchPage = ({ onClose }) => {
  const [phrase, setPhrase] = useState('');
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [image, setImage] = useState(null);
  const [imagePreview, setImagePreview] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0); // State to manage upload progress
  const [noProductsFound, setNoProductsFound] = useState(false); // State to manage no products found message

  const searchProducts = useCallback(debounce((phrase) => {
    axios.get(`${api_url}/products/search`, {
      headers: {
        "Content-Type": "application/json",
      },
      params: {
        phrase: encodeURIComponent(phrase)
      }
    })
    .then(response => {
      setProducts(response.data);
      setIsLoading(false);
      setNoProductsFound(response.data.length === 0);
    })
    .catch(error => {
      console.error('Error fetching products:', error);
      setIsLoading(false);
      setProducts([]);
      setNoProductsFound(true);
    });
  }, 500), []);

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
      searchProducts.cancel(); // Clean up the debounced function on unmount
    };
  }, [searchProducts]);

  useEffect(() => {
    if (phrase.trim().length > 0) {
      setIsLoading(true);
      searchProducts(phrase);
    } else {
      setIsLoading(false);
      setProducts([]);
      setNoProductsFound(false);
    }
  }, [phrase, searchProducts]);

  const handleImageChange = ({ file }) => {
    if (file) {
      const url = URL.createObjectURL(file);
      setImage(file);
      setImagePreview(url);
    }
  };

  const handleRemoveImage = () => {
    setImage(null);
    setImagePreview('');
    setPhrase('');
    setProducts([]);
    setNoProductsFound(false);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
    handleRemoveImage();
  };

  const handleImageSearch = () => {
    const formData = new FormData();
    formData.append('image', image);

    setIsLoading(true);
    setUploadProgress(0); // Reset progress to 0%

    // Gradually increase the progress to 90% over 1 second
    const interval = setInterval(() => {
      setUploadProgress(prevProgress => {
        if (prevProgress < 90) {
          return prevProgress + 10;
        } else {
          clearInterval(interval);
          return prevProgress;
        }
      });
    }, 50);

    axios.post(`${api_url}/products/search-by-image`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then(response => {
        setProducts(response.data.similarProducts);
        setUploadProgress(100); // Set progress to 100% on success
        setTimeout(() => {
          setIsModalVisible(false);
          setUploadProgress(0);
        }, 500); // Delay to ensure progress bar shows 100%
        setIsLoading(false);
        setNoProductsFound(response.data.similarProducts.length === 0);
      })
      .catch(error => {
        console.error('Error searching by image:', error);
        setIsLoading(false);
        setProducts([]);
        setIsModalVisible(false);
        setUploadProgress(0); // Reset progress on error
        setNoProductsFound(true);
      });
  };

  return (
    <>
      <ModalOverlay>
        <ModalContent>
          <button onClick={onClose} className="close-button">&times;</button>
          <Container>
            <SearchInput
              autoFocus
              value={phrase}
              onChange={ev => setPhrase(ev.target.value)}
              placeholder="Search for products..."
              disabled={!!image}
            />
            {image && (
              <ImageCard>
                <img src={imagePreview} alt="Uploaded Image" />
                <button onClick={handleRemoveImage}>
                  <CloseOutlined />
                </button>
              </ImageCard>
            )}
            {!image && (
              <Button 
                type="primary" 
                icon={<SearchOutlined />} 
                onClick={() => setIsModalVisible(true)}
                style={{ marginBottom: 20 }}
              >
                Search by Image
              </Button>
            )}
            {!isLoading && phrase !== '' && products.length === 0 && (
              <Typography.Text>No products found for query "{phrase}"</Typography.Text>
            )}
            {isLoading && (
              <Spinner size="large" />
            )}
            {!isLoading && products.length > 0 && (
              <ProductsGrid products={products} />
            )}
            {!isLoading && noProductsFound && image && (
              <Typography.Text>No products found for the uploaded image</Typography.Text>
            )}
          </Container>
        </ModalContent>
      </ModalOverlay>

      <Modal
        open={isModalVisible}
        title="Search by Image"
        footer={null}
        onCancel={handleCloseModal}
        closeIcon={<CloseOutlined />}
        style={{ padding: 20, textAlign: 'center' }}
      >
        <Upload
          beforeUpload={() => false}
          showUploadList={false}
          onChange={handleImageChange}
        >
          <Button icon={<UploadOutlined />} size="large">
            Click or drag to upload image
          </Button>
        </Upload>
        {imagePreview && (
          <ImagePreviewContainer>
            <ImagePreview src={imagePreview} alt="Image Preview" />
            <RemoveImageButton onClick={handleRemoveImage}>Remove image</RemoveImageButton>
          </ImagePreviewContainer>
        )}
        {image && (
          <Button 
            type="primary" 
            onClick={handleImageSearch}
            style={{ marginTop: 20 }}
          >
            Search
          </Button>
        )}
        {uploadProgress > 0 && (
          <div className="mt-4">
            <Progress percent={uploadProgress} />
          </div>
        )}
      </Modal>
    </>
  );
};

export default SearchPage;
