import React from "react";
import Center from "./Center";

const FeedBack = () => {
  return (
    <div className="w-full px-4 py-12 bg-gradient-to-r from-gray-900 via-gray-800 to-gray-900 text-white">
      <Center>
        <div className="mx-auto max-w-screen-xl lg:grid lg:grid-cols-5 gap-8 items-center">
          <div className="lg:col-span-3">
            <h1 className="text-xl font-bold sm:text-2xl md:text-3xl mb-4">
              Share your feedback with us!
            </h1>
            <p className="text-base font-medium text-gray-100">
              We value your feedback. Let us know how we can improve.
            </p>
          </div>
          <div className="lg:col-span-2 flex items-center justify-center sm:justify-end">
            <button
              className="px-6 py-3 font-bold text-black bg-emerald-500 rounded-lg shadow-lg transition-transform transform hover:scale-105 hover:bg-emerald-600 active:bg-emerald-700"
            >
              Provide Feedback
            </button>
          </div>
        </div>
      </Center>
    </div>
  );
};

export default FeedBack;
