import React, { useState, useEffect } from "react";
import { Outlet, Link } from "react-router-dom";
import { IoChevronBackSharp } from "react-icons/io5";
import { VscSignOut } from "react-icons/vsc";
import { PiGraduationCap } from "react-icons/pi";
import { LuUser } from "react-icons/lu";
import { IoHomeOutline } from "react-icons/io5";
import { IoNotificationsOutline } from "react-icons/io5";
import { IoMailOutline } from "react-icons/io5";
import { LuLayoutDashboard, LuAlignJustify } from "react-icons/lu";
import { isAdmin, isSeller, logout } from "../../../auth/AuthContext";
import {MdAccountCircle, MdCategory} from "react-icons/md";
import { HiMiniUserGroup, HiBuildingStorefront } from "react-icons/hi2";
import Logo from "../../../assets/piclab.png";
import Cookies from "js-cookie";
import {AiOutlineShoppingCart} from "react-icons/ai";
import {FaHome} from "react-icons/fa";

const AsideBar = () => {
  const [isOpen, setIsOpen] = useState(true);
  const [username, setUsername] = useState("");
  const [userProfile, setUserProfile] = useState("");
  const [userEmail, setUserEmail] = useState("");

  useEffect(() => {
    const name = Cookies.get("username");
    const profile = Cookies.get("profile");
    const email = Cookies.get("email");

    setUsername(name);
    setUserProfile(profile);
    setUserEmail(email);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setIsOpen(false);
      } else {
        setIsOpen(true);
      }
    };

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // handle close drawer and open drawer
  const handleDrawer = () => {
    setIsOpen(!isOpen);
  };

  const handleLogout = () => {
    logout();
  };

  return (
    <>
      <div className="flex flex-row dark:bg-white">
        <div>
          <div
            className={`fixed top-0 left-0 w-60 h-full bg-white drop-shadow-2xl transform transition-all duration-200 ${
              isOpen ? "translate-x-0" : "-translate-x-full"
            }`}
          >
            <button
              className="p-2 mt-20 flex float-right absolute text-white bg-white drop-shadow-md rounded-full hover:bg-gray-300 top-2 right-0 transform translate-x-1/2 -translate-y-1/2"
              onClick={handleDrawer}
            >
              {isOpen ? (
                <IoChevronBackSharp class="w-5 h-5 text-black" />
              ) : (
                <LuAlignJustify class="w-5 h-5 pl-2 text-black" />
              )}
            </button>

            <div className=" mt-4">
              <a href="/admin-dash" class="flex items-center ps-1 mb-5">
                <img
                  src={Logo}
                  class=" h-10 w-auto me-3 sm:h-7"
                  alt="piclab"
                />
                <p class="self-center pr-2 md:text-lg text-md font-bold whitespace-nowrap -200 text-black">
                  PicLab
                </p>
              </a>
              <div class="py-4 overflow-y-auto">
                <ul class="space-y-1 font-medium">
                  <h3 class="ms-3 font-medium mb-4 text-eee-500">PROFILE</h3>
                  <li>
                    <div class="flex flex-row mb-3 gap-2 px-2">
                      <img
                          class="w-9 h-9 p-1 rounded-full ring-2 ring-green-300 "
                          src={userProfile}
                          alt="user-profile"
                      />

                      <div class="flex flex-col">
                        <span className="font-bold text-sm text-black">
                          {username}
                        </span>
                        <span className="text-xs text-black whitespace-nowrap max-w-[100px]">
                          {userEmail}
                        </span>
                      </div>
                    </div>
                  </li>
                  <hr/>
                  {isAdmin() && (
                      <>
                        <h3 class="ms-3 font-medium mb-4 text-eee-500">ADMIN</h3>
                        <li>
                          <Link
                              to="/admin-dash"
                              class={`flex items-center p-2 text-slate-800 hover:bg-gray-300 group
                        `}
                          >
                            <LuLayoutDashboard class="w-5 h-5 text-black"/>
                            <span className="ms-3 text-sm font-medium text-black">
                            Dashboard
                          </span>
                          </Link>
                        </li>
                        <li>
                          <Link
                              to="/manage-users"
                              class={`flex items-center p-2 text-slate-800 hover:bg-gray-300 group
                        `}
                          >
                            <HiMiniUserGroup class="w-5 h-5 text-black"/>
                            <span className="ms-3 text-sm font-medium text-black">
                            Manage Users
                          </span>
                          </Link>
                        </li>
                        <li>
                          <Link
                              to="/manage-products"
                              class={`flex items-center p-2 text-slate-800 hover:bg-gray-300 group
                        `}
                          >
                            <AiOutlineShoppingCart class="w-5 h-5 text-black"/>
                            <span className="ms-3 text-sm font-medium text-black">
                            Manage Products
                          </span>
                          </Link>
                        </li>
                        <li>
                          <Link
                              to="/manage-category"
                              class={`flex items-center p-2 text-slate-800 hover:bg-gray-300 group
                        `}
                          >
                            <MdCategory class="w-5 h-5 text-black"/>
                            <span className="ms-3 text-sm font-medium text-black">
                            Manage Categories
                          </span>
                          </Link>
                        </li>
                        <hr/>
                      </>
                  )}

                  <h3 class="ms-3 font-medium mb-4  mt-2 text-eee-500">
                    PERSONAL
                  </h3>
                  <li>
                    <Link
                        to="/"
                        class="flex items-center p-2 text-slate-800 hover:bg-gray-300 group"
                    >
                      <FaHome class="w-5 h-5 text-black"/>
                      <span className="ms-3 text-sm font-medium text-black">
                        Home Page
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link
                        to="/account"
                        class="flex items-center p-2 text-slate-800 hover:bg-gray-300 group"
                    >
                      <MdAccountCircle class="w-5 h-5 text-black"/>
                      <span className="ms-3 text-sm font-medium text-black">
                        Account Details
                      </span>
                    </Link>
                  </li>
                  {/*<li>*/}
                  {/*  <Link*/}
                  {/*    to="/classes"*/}
                  {/*    class="flex items-center p-2 text-slate-800 hover:bg-gray-300 group"*/}
                  {/*  >*/}
                  {/*    <IoMailOutline class="w-5 h-5 text-black" />*/}
                  {/*    <span class="ms-3 text-sm font-medium text-black">*/}
                  {/*      Messages*/}
                  {/*    </span>*/}
                  {/*  </Link>*/}
                  {/*</li>*/}
                  <hr/>
                  <li>
                    <div
                        onClick={handleLogout}
                        class="flex items-center cursor-pointer p-2 text-slate-800 hover:bg-gray-300 group"
                    >
                      <VscSignOut class="w-5 h-5 text-black"/>
                      <span className="ms-3 text-sm font-medium text-black">
                        Logout
                      </span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div
            className={`flex-grow h-screen bg-white "
           ${isOpen ? " pl-52" : ""}`}
        >
            <Outlet/>
          <hr/>

          {/*<div>*/}
          {/*  <footer class="bg-white mt-20 sticky">*/}
          {/*    <div class="mx-auto w-full max-w-screen-xl p-4 py-6 lg:py-8">*/}
          {/*      <div class="md:flex md:justify-between">*/}
          {/*        <div class="mb-6 md:mb-0">*/}
          {/*          <a href="/client/public" class="flex items-center">*/}
          {/*            <img src={Logo} class="h-8 me-3" alt="FlowBite Logo" />*/}
          {/*            <span class="self-center text-2xl font-semibold whitespace-nowrap text-black ">*/}
          {/*              PicLab*/}
          {/*            </span>*/}
          {/*          </a>*/}
          {/*        </div>*/}
          {/*        <div class="grid grid-cols-2 gap-8 sm:gap-6 sm:grid-cols-3">*/}
          {/*          <div>*/}
          {/*            <h2 class="mb-6 text-sm font-semibold text-gray-900 uppercase ">*/}
          {/*              Resources*/}
          {/*            </h2>*/}
          {/*            <ul class="text-gray-500  font-medium">*/}
          {/*              <li class="mb-4">*/}
          {/*                <a*/}
          {/*                  href="https://flowbite.com/"*/}
          {/*                  class="hover:underline"*/}
          {/*                >*/}
          {/*                  Flowbite*/}
          {/*                </a>*/}
          {/*              </li>*/}
          {/*              <li>*/}
          {/*                <a*/}
          {/*                  href="https://tailwindcss.com/"*/}
          {/*                  class="hover:underline"*/}
          {/*                >*/}
          {/*                  Tailwind CSS*/}
          {/*                </a>*/}
          {/*              </li>*/}
          {/*            </ul>*/}
          {/*          </div>*/}
          {/*          <div>*/}
          {/*            <h2 class="mb-6 text-sm font-semibold text-gray-900 uppercase ">*/}
          {/*              Follow us*/}
          {/*            </h2>*/}
          {/*            <ul class="text-gray-500 font-medium">*/}
          {/*              <li class="mb-4">*/}
          {/*                <a*/}
          {/*                  href="https://github.com/themesberg/flowbite"*/}
          {/*                  class="hover:underline "*/}
          {/*                >*/}
          {/*                  Github*/}
          {/*                </a>*/}
          {/*              </li>*/}
          {/*              <li>*/}
          {/*                <a*/}
          {/*                  href="https://discord.gg/4eeurUVvTy"*/}
          {/*                  class="hover:underline"*/}
          {/*                >*/}
          {/*                  Discord*/}
          {/*                </a>*/}
          {/*              </li>*/}
          {/*            </ul>*/}
          {/*          </div>*/}
          {/*          <div>*/}
          {/*            <h2 class="mb-6 text-sm font-semibold text-gray-900 uppercase">*/}
          {/*              Legal*/}
          {/*            </h2>*/}
          {/*            <ul class="text-gray-500  font-medium">*/}
          {/*              <li class="mb-4">*/}
          {/*                <a href="#" class="hover:underline">*/}
          {/*                  Privacy Policy*/}
          {/*                </a>*/}
          {/*              </li>*/}
          {/*              <li>*/}
          {/*                <a href="#" class="hover:underline">*/}
          {/*                  Terms &amp; Conditions*/}
          {/*                </a>*/}
          {/*              </li>*/}
          {/*            </ul>*/}
          {/*          </div>*/}
          {/*        </div>*/}
          {/*      </div>*/}
          {/*      <hr class="my-6 border-gray-200 sm:mx-auto  lg:my-8" />*/}
          {/*      <div class="sm:flex sm:items-center sm:justify-between">*/}
          {/*        <span class="text-sm text-black sm:text-center">*/}
          {/*          © 2023{" "}*/}
          {/*          <a href="https://flowbite.com/" class="hover:underline">*/}
          {/*            PicLab™*/}
          {/*          </a>*/}
          {/*          . All Rights Reserved.*/}
          {/*        </span>*/}
          {/*        <div class="flex mt-4 sm:justify-center sm:mt-0">*/}
          {/*          <a*/}
          {/*            href="#"*/}
          {/*            class="text-black hover:text-gray-500  "*/}
          {/*          >*/}
          {/*            <svg*/}
          {/*              class="w-4 h-4"*/}
          {/*              aria-hidden="true"*/}
          {/*              xmlns="http://www.w3.org/2000/svg"*/}
          {/*              fill="currentColor"*/}
          {/*              viewBox="0 0 8 19"*/}
          {/*            >*/}
          {/*              <path*/}
          {/*                fill-rule="evenodd"*/}
          {/*                d="M6.135 3H8V0H6.135a4.147 4.147 0 0 0-4.142 4.142V6H0v3h2v9.938h3V9h2.021l.592-3H5V3.591A.6.6 0 0 1 5.592 3h.543Z"*/}
          {/*                clip-rule="evenodd"*/}
          {/*              />*/}
          {/*            </svg>*/}
          {/*            <span class="sr-only">Facebook page</span>*/}
          {/*          </a>*/}
          {/*          <a*/}
          {/*            href="#"*/}
          {/*            class="text-black hover:text-gray-500 ms-5"*/}
          {/*          >*/}
          {/*            <svg*/}
          {/*              class="w-4 h-4"*/}
          {/*              aria-hidden="true"*/}
          {/*              xmlns="http://www.w3.org/2000/svg"*/}
          {/*              fill="currentColor"*/}
          {/*              viewBox="0 0 21 16"*/}
          {/*            >*/}
          {/*              <path d="M16.942 1.556a16.3 16.3 0 0 0-4.126-1.3 12.04 12.04 0 0 0-.529 1.1 15.175 15.175 0 0 0-4.573 0 11.585 11.585 0 0 0-.535-1.1 16.274 16.274 0 0 0-4.129 1.3A17.392 17.392 0 0 0 .182 13.218a15.785 15.785 0 0 0 4.963 2.521c.41-.564.773-1.16 1.084-1.785a10.63 10.63 0 0 1-1.706-.83c.143-.106.283-.217.418-.33a11.664 11.664 0 0 0 10.118 0c.137.113.277.224.418.33-.544.328-1.116.606-1.71.832a12.52 12.52 0 0 0 1.084 1.785 16.46 16.46 0 0 0 5.064-2.595 17.286 17.286 0 0 0-2.973-11.59ZM6.678 10.813a1.941 1.941 0 0 1-1.8-2.045 1.93 1.93 0 0 1 1.8-2.047 1.919 1.919 0 0 1 1.8 2.047 1.93 1.93 0 0 1-1.8 2.045Zm6.644 0a1.94 1.94 0 0 1-1.8-2.045 1.93 1.93 0 0 1 1.8-2.047 1.918 1.918 0 0 1 1.8 2.047 1.93 1.93 0 0 1-1.8 2.045Z" />*/}
          {/*            </svg>*/}
          {/*            <span class="sr-only">Discord community</span>*/}
          {/*          </a>*/}
          {/*          <a*/}
          {/*            href="#"*/}
          {/*            class="text-black hover:text-gray-500 ms-5"*/}
          {/*          >*/}
          {/*            <svg*/}
          {/*              class="w-4 h-4"*/}
          {/*              aria-hidden="true"*/}
          {/*              xmlns="http://www.w3.org/2000/svg"*/}
          {/*              fill="currentColor"*/}
          {/*              viewBox="0 0 20 17"*/}
          {/*            >*/}
          {/*              <path*/}
          {/*                fill-rule="evenodd"*/}
          {/*                d="M20 1.892a8.178 8.178 0 0 1-2.355.635 4.074 4.074 0 0 0 1.8-2.235 8.344 8.344 0 0 1-2.605.98A4.13 4.13 0 0 0 13.85 0a4.068 4.068 0 0 0-4.1 4.038 4 4 0 0 0 .105.919A11.705 11.705 0 0 1 1.4.734a4.006 4.006 0 0 0 1.268 5.392 4.165 4.165 0 0 1-1.859-.5v.05A4.057 4.057 0 0 0 4.1 9.635a4.19 4.19 0 0 1-1.856.07 4.108 4.108 0 0 0 3.831 2.807A8.36 8.36 0 0 1 0 14.184 11.732 11.732 0 0 0 6.291 16 11.502 11.502 0 0 0 17.964 4.5c0-.177 0-.35-.012-.523A8.143 8.143 0 0 0 20 1.892Z"*/}
          {/*                clip-rule="evenodd"*/}
          {/*              />*/}
          {/*            </svg>*/}
          {/*            <span class="sr-only">Twitter page</span>*/}
          {/*          </a>*/}
          {/*          <a*/}
          {/*            href="#"*/}
          {/*            class="text-black hover:text-gray-500 ms-5"*/}
          {/*          >*/}
          {/*            <svg*/}
          {/*              class="w-4 h-4"*/}
          {/*              aria-hidden="true"*/}
          {/*              xmlns="http://www.w3.org/2000/svg"*/}
          {/*              fill="currentColor"*/}
          {/*              viewBox="0 0 20 20"*/}
          {/*            >*/}
          {/*              <path*/}
          {/*                fill-rule="evenodd"*/}
          {/*                d="M10 .333A9.911 9.911 0 0 0 6.866 19.65c.5.092.678-.215.678-.477 0-.237-.01-1.017-.014-1.845-2.757.6-3.338-1.169-3.338-1.169a2.627 2.627 0 0 0-1.1-1.451c-.9-.615.07-.6.07-.6a2.084 2.084 0 0 1 1.518 1.021 2.11 2.11 0 0 0 2.884.823c.044-.503.268-.973.63-1.325-2.2-.25-4.516-1.1-4.516-4.9A3.832 3.832 0 0 1 4.7 7.068a3.56 3.56 0 0 1 .095-2.623s.832-.266 2.726 1.016a9.409 9.409 0 0 1 4.962 0c1.89-1.282 2.717-1.016 2.717-1.016.366.83.402 1.768.1 2.623a3.827 3.827 0 0 1 1.02 2.659c0 3.807-2.319 4.644-4.525 4.889a2.366 2.366 0 0 1 .673 1.834c0 1.326-.012 2.394-.012 2.72 0 .263.18.572.681.475A9.911 9.911 0 0 0 10 .333Z"*/}
          {/*                clip-rule="evenodd"*/}
          {/*              />*/}
          {/*            </svg>*/}
          {/*            <span class="sr-only">GitHub account</span>*/}
          {/*          </a>*/}
          {/*          <a*/}
          {/*            href="#"*/}
          {/*            class="text-black hover:text-gray-500 ms-5"*/}
          {/*          >*/}
          {/*            <svg*/}
          {/*              class="w-4 h-4"*/}
          {/*              aria-hidden="true"*/}
          {/*              xmlns="http://www.w3.org/2000/svg"*/}
          {/*              fill="currentColor"*/}
          {/*              viewBox="0 0 20 20"*/}
          {/*            >*/}
          {/*              <path*/}
          {/*                fill-rule="evenodd"*/}
          {/*                d="M10 0a10 10 0 1 0 10 10A10.009 10.009 0 0 0 10 0Zm6.613 4.614a8.523 8.523 0 0 1 1.93 5.32 20.094 20.094 0 0 0-5.949-.274c-.059-.149-.122-.292-.184-.441a23.879 23.879 0 0 0-.566-1.239 11.41 11.41 0 0 0 4.769-3.366ZM8 1.707a8.821 8.821 0 0 1 2-.238 8.5 8.5 0 0 1 5.664 2.152 9.608 9.608 0 0 1-4.476 3.087A45.758 45.758 0 0 0 8 1.707ZM1.642 8.262a8.57 8.57 0 0 1 4.73-5.981A53.998 53.998 0 0 1 9.54 7.222a32.078 32.078 0 0 1-7.9 1.04h.002Zm2.01 7.46a8.51 8.51 0 0 1-2.2-5.707v-.262a31.64 31.64 0 0 0 8.777-1.219c.243.477.477.964.692 1.449-.114.032-.227.067-.336.1a13.569 13.569 0 0 0-6.942 5.636l.009.003ZM10 18.556a8.508 8.508 0 0 1-5.243-1.8 11.717 11.717 0 0 1 6.7-5.332.509.509 0 0 1 .055-.02 35.65 35.65 0 0 1 1.819 6.476 8.476 8.476 0 0 1-3.331.676Zm4.772-1.462A37.232 37.232 0 0 0 13.113 11a12.513 12.513 0 0 1 5.321.364 8.56 8.56 0 0 1-3.66 5.73h-.002Z"*/}
          {/*                clip-rule="evenodd"*/}
          {/*              />*/}
          {/*            </svg>*/}
          {/*            <span class="sr-only">Dribble account</span>*/}
          {/*          </a>*/}
          {/*        </div>*/}
          {/*      </div>*/}
          {/*    </div>*/}
          {/*  </footer>*/}
          {/*</div>*/}
        </div>
      </div>
    </>
  );
};

export default AsideBar;