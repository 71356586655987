import React, { useState } from "react";
import { MoonLoader } from "react-spinners";
import piclab from "../../../assets/piclab.png";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { api_url } from "../../../api/config";

const ResetPassword = () => {
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const onForgotPassword = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await axios.post(`${api_url}/auth/pass-reset-req-otp`, {
        email,
      });

      if (response.status === 200) {
        setIsLoading(false);
        Swal.fire({
          icon: 'success',
          title: 'OTP Sent!',
          text: 'The OTP has been sent to your email.',
        }).then(() => {
          navigate("/verify-otp", { state: { email } });
        });
      }
    } catch (error) {
      setIsLoading(false);
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error.response?.data?.message || 'Email not found!',
      });
    }
    setEmail('');
  };

  return (
    <div className="min-h-screen bg-white flex flex-col justify-center sm:px-6 lg:px-8">
      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md px-2">
        <div className="bg-white py-4 px-4 shadow border border-1 rounded-xl sm:px-10">
          <div className="sm:mx-auto sm:w-full mb-2 sm:max-w-md flex justify-center">
            <img src={piclab} alt="Logo" className="block h-16" />
          </div>
          <p className="flex text-gray-700 font-bold text-xl justify-center items-center mb-2">
            Reset your password
          </p>
          <form onSubmit={onForgotPassword} className="relative">
            <div className="mt-2">
              <label htmlFor="email" className="block text-sm font-medium leading-5 text-gray-700">
                Email address
              </label>
              <div className="mt-1 relative rounded-md shadow-sm">
                <input
                  id="email"
                  name="email"
                  type="email"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Enter your email"
                  className="mt-1 appearance-none block w-full px-3 py-2 border text-gray-800 border-gray-300 rounded-md placeholder-gray-400 focus:outline-none  focus:border-blue-100 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                />
              </div>
            </div>

            <div className="mt-6">
              <span className="block w-full rounded-md shadow-sm">
                <button
                  type="submit"
                  className="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out"
                >
                  {isLoading ? (
                    <div className="flex flex-row justify-center items-center gap-1">
                      <MoonLoader size={20} color="#fff" loading={true} />
                      <span className="text-xs">Verifying...</span>
                    </div>
                  ) : (
                    "Verify Email"
                  )}
                </button>
              </span>
              <p className="mt-2 text-center text-sm leading-5 text-gray-500 max-w">
                Don't have an account yet?&nbsp;
                <a
                  href="/register"
                  className="font-medium text-blue-600 hover:text-blue-500 focus:outline-none focus:underline transition ease-in-out duration-150"
                >
                  Register Now
                </a>
              </p>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
