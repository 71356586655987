import React, { useContext } from 'react';
import { AddressContext } from '../components/contexts/AddressContext';
import { FaTrash } from 'react-icons/fa';

const AddressCard = ({ address }) => {
  const { removeAddress } = useContext(AddressContext);
  console.log("addresscard", address) 

  const handleRemoveAddress = () => {
    removeAddress(address._id);
  };

  return (
    <div className="p-4 mb-4 bg-gray-100 rounded-lg shadow-md flex justify-between items-center">
      <div>
        <p className="text-base text-gray-600">{address.address}</p>
        <p className="text-base text-gray-600">{address.city}, {address.postalCode}</p>
        <p className="text-base text-gray-600">{address.country}</p>
      </div>
      <button
        onClick={handleRemoveAddress}
        className="flex items-center justify-center p-2 text-white bg-red-600 rounded-full hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500"
      >
        <FaTrash />
      </button>
    </div>
  );
};

export default AddressCard;
