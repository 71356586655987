import React, { useEffect, useState } from "react";
import axios from "axios";
import Header from "../components/Header";
import Center from "../components/Center";
import ProductBox from "../components/ProductBox";
import styled, { keyframes } from "styled-components";
import { Link } from "react-router-dom";
import Footer from "../components/Footer";
import { api_url } from "../api/config";
import WavingHand from "../components/loader/WavingHand";

const dropAnimation = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const StyledRevealWrapper = styled.div`
  opacity: 0;
  animation: ${dropAnimation} 0.5s ease forwards;
  animation-delay: ${props => props.delay || "0s"};
`;

const CategoryGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  @media screen and (min-width: 768px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
`;

const CategoryTitle = styled.div`
  display: flex;
  margin-top: 10px;
  margin-bottom: 0;
  align-items: center;
  gap: 10px;
  h2 {
    color: black;
    margin-bottom: 10px;
    margin-top: 10px;
    font-size: 1.2em;
  }
  a {
    color: #555;
    display: inline-block;
  }
`;
const CategoryWrapper = styled.div`
  margin-bottom: 40px;
`;

const ShowAllSquare = styled(Link)`
  background-color: #ddd;
  height: 160px;
  border-radius: 10px;
  align-items: center;
  display: flex;
  justify-content: center;
  color: #555;
  text-decoration: none;
  font-size: 1.2em; /* Changed from text-size */
`;

const Categories = () => {
  
    const [mainCategories, setMainCategories] = useState([]);
    const [categoriesProducts, setCategoriesProducts] = useState({});
    const [wishedProducts, setWishedProducts] = useState([]);
    const [loading, setLoading] = useState(true);

    const fetchCategoriesAndProducts = async () => {
      try {
          const [categoriesResponse, allProductsResponse] = await Promise.all([
              axios.get(`${api_url}/categories/`),
              axios.get(`${api_url}/products/`, {
                  headers: {
                      "Content-Type": "application/json",
                  },
              })
          ]);
  
          const fetchedCategories = categoriesResponse.data;
          const allProducts = allProductsResponse.data;
  
          const productsMap = {};
          for (const category of fetchedCategories) {
              const categoryId = category._id.toString();
              const categoryProducts = allProducts.filter(product => product.categories.includes(categoryId) && product.status === "approved");
              productsMap[categoryId] = categoryProducts;
          }
          setMainCategories(fetchedCategories);
          setCategoriesProducts(productsMap);
          setLoading(false);
      } catch (error) {
          console.error('Error fetching data:', error);
      }
  };
  

    useEffect(() => {
        fetchCategoriesAndProducts();
    }, []);

    
    useEffect(() => {
        document.title = "Products | PICLAB";
        const WavingHand = setTimeout(() => {
          setLoading(false);
        }, 1000);
        return () => clearTimeout(WavingHand);
      }, []);
    
        if (loading) return <WavingHand />;

    return (
        <>
            <div className="flex flex-col min-h-screen bg-gray-100">
                <Header />
                <main className="flex-grow">
                    <Center>
                        {mainCategories.map((category) => (
                            <CategoryWrapper key={category._id}>
                                <CategoryTitle>
                                    <h2>{category.name}</h2>
                                    <div>
                                        <Link to={'/categories/'+ category._id}>Show all</Link>
                                    </div>
                                </CategoryTitle>
                                <CategoryGrid>
                                    {categoriesProducts[category._id] && categoriesProducts[category._id].map((product, index) => (
                                        <StyledRevealWrapper delay={index*50} key={product._id}>
                                            <ProductBox {...product} wished={wishedProducts.includes(product._id)} />
                                        </StyledRevealWrapper>
                                    ))}
                                    <StyledRevealWrapper delay={categoriesProducts[category._id]?.length*50}>
                                        <ShowAllSquare to={'/categories/'+ category._id}>
                                            Show all &rarr;
                                        </ShowAllSquare>
                                    </StyledRevealWrapper>
                                </CategoryGrid>
                            </CategoryWrapper>
                        ))}
                    </Center>
                </main>
                <Footer />
            </div>
        </>
    );
};

export default Categories;
