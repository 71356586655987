import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import Center from "../components/Center";
import ProductsGrid from "../components/ProductsGrid";
import Title from "../components/Title";
import Footer from "../components/Footer";
import axios from "axios";
import WavingHand from "../components/loader/WavingHand";
import { api_url, decodedToken, decodedproductID } from "../api/config";

const Products = () => {
    const [products, setProducts] = useState([]);
    const [wishedProducts, setWishedProducts] = useState([]);
    const [loading, setLoading] = useState(true);

    // Fetch data from the server
    const fetchData = async () => {
        try {
            const response = await axios.get(`${api_url}/products/`, {
                headers: {
                    "Content-Type": "application/json",
                },
            });
            const approvedProducts = response.data.filter(product => product.status === "approved");
            setProducts(approvedProducts);
            setLoading(false);
            console.log(approvedProducts);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        fetchData(); // Call fetchData when the component mounts
    }, []);

    useEffect(() => {
        document.title = "Products | PICLAB";
        const WavingHand = setTimeout(() => {
          setLoading(false);
        }, 1000);
        return () => clearTimeout(WavingHand);
      }, []);
    
        if (loading) return <WavingHand />;

    return (
        <>
            <div className="flex flex-col min-h-screen bg-gray-100">
                <Header />
                <main className="flex-grow mt-5 mb-10">
                    <Center>
                        <div className="mb-5">
                            <Title>All products</Title>
                        </div>
                        <ProductsGrid products={products} wishedProducts={wishedProducts} />
                    </Center>
                </main>
                <Footer />
            </div>
        </>
    );
};

export default Products;
