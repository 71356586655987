import React from 'react';
import { Table, Row, Col } from 'antd';

export default function Output(props) {
    const { outputs = [], imageToPredict } = props;

    const columns = [
        {
            title: '#',
            dataIndex: 'index',
            key: 'index',
            render: (text, record, index) => index + 1
        },
        {
            title: 'Label',
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: 'Probability',
            dataIndex: 'value',
            key: 'value'
        }
    ];

    return (
        <Row gutter={16} className="mt-3">
            <Col span={24} lg={8} md={12} sm={24} xs={24}>
                <div className="mb-3 row justify-content-center align-items-flex-start">
                    {imageToPredict ? (
                        <img
                            src={imageToPredict}
                            style={{
                                width: '100%',
                                maxWidth: '400px',
                                height: 'auto'
                            }}
                            alt="image-to-predict"
                        />
                    ) : (
                        <div>Image to predict will be shown here</div>
                    )}
                </div>
            </Col>
            <Col span={24} lg={16} md={12} sm={24} xs={24}>
                <Table
                    columns={columns}
                    dataSource={outputs}
                    rowKey={(record, index) => index}
                    pagination={false}
                />
            </Col>
        </Row>
    );
}
