import React from "react";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import { logout } from "../auth/AuthContext";
import { HiOutlineUser, HiOutlineLogout } from "react-icons/hi";
import { useNavigate } from "react-router-dom";

export default function AccountDetailsPage() {
  const username = Cookies.get("username");
  const userEmail = Cookies.get("email");
  const userProfile = Cookies.get("profile");
  const userRole = Cookies.get("role");
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
  };

  return (
    <div className="bg-gradient-to-r from-blue-50 to-indigo-100 flex flex-col items-center py-10 min-h-3.5">
      <div className="bg-white shadow-lg rounded-lg w-full max-w-md p-8 flex flex-col items-center border border-gray-200">
        <h1 className="text-2xl font-bold text-gray-700 mb-6">Profile</h1>
        <div className="flex flex-col items-center mb-6">
          <img src={userProfile} className="w-24 h-24 rounded-full mb-4 border-4 border-indigo-500 shadow-lg" alt="User profile" />
          <div className="w-full mb-4">
            <label htmlFor="username" className="block text-sm font-bold text-gray-700">
              Name
            </label>
            <input
              id="username"
              value={username}
              readOnly
              className="font-semibold mt-1 block w-60 p-2 bg-white border border-gray-500 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
            />
          </div>
          <div className="w-full mb-4">
            <label htmlFor="email" className="block text-sm font-bold text-gray-700">
              Email
            </label>
            <input
              id="email"
              value={userEmail}
              readOnly
              className="font-semibold mt-1 block w-full p-2 bg-white border border-gray-500 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
            />
          </div>
          <div className="w-full mb-4">
            <label htmlFor="role" className="block text-sm font-bold text-gray-700">
              Member Role
            </label>
            <input
              id="role"
              value={userRole}
              readOnly
              className="font-semibold mt-1 block w-full p-2 bg-white border border-gray-500 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
            />
          </div>
        </div>
        <div className="flex flex-col lg:flex-row items-center w-full">
          <div className="flex items-center mb-4 lg:mb-0 lg:mr-4 w-full">
            <HiOutlineUser className="w-5 h-5 mr-2 text-indigo-500" />
            <button
              onClick={() => navigate("/edit-profile")}
              className="w-full py-2 px-4 bg-indigo-500 text-white font-semibold rounded-md shadow-lg hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 transition duration-150"
            >
              Edit Profile
            </button>
          </div>
          <div className="flex items-center w-full">
            <HiOutlineLogout className="w-5 h-5 mr-2 text-red-500" />
            <button
              onClick={handleLogout}
              className="w-full py-2 px-4 bg-red-500 text-white font-semibold rounded-md shadow-lg hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500 transition duration-150"
            >
              Logout
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
