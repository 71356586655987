import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { CartContextProvider } from './components/CartContext';
import { ProductContextProvider } from './components/contexts/ProductContext';
import { AddressContextProvider } from './components/contexts/AddressContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <CartContextProvider>
      <ProductContextProvider>
          <AddressContextProvider>
            <App />
          </AddressContextProvider>
      </ProductContextProvider>
    </CartContextProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();