import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { api_url, decodedToken } from '../../api/config';
import Spinner from '../../components/Spinner';
import { ProductContext } from '../../components/contexts/ProductContext';
import ProductDetailModal from './ProductDetailModal';
import { Table, Tag, Button, Space } from 'antd';
import { CheckCircleOutlined, ClockCircleOutlined } from '@ant-design/icons';

const ProductManagement = () => {
  const { setShowCreateProduct } = useContext(ProductContext);
  const [productRequests, setProductRequests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [isDetailModalVisible, setIsDetailModalVisible] = useState(false);
  const [categories, setCategories] = useState({});

  useEffect(() => {
    const fetchProductRequests = async () => {
      try {
        const response = await axios.get(`${api_url}/products/get-by-role`, {
          headers: {
            Authorization: decodedToken,
          },
        });
        setProductRequests(response.data);

        const categoryIds = [...new Set(response.data.flatMap(request => request.categories))];
        console.log(categoryIds);
        // Fetch category names by their IDs
        const categoriesMap = {};
        await Promise.all(categoryIds.map(async (id) => {
          const categoryResponse = await axios.get(`${api_url}/categories/${id}`, {
            headers: {
              Authorization: decodedToken,
            },
          });
          categoriesMap[id] = categoryResponse.data.name;
        }));
        console.log(categoriesMap);
        setCategories(categoriesMap);

      } catch (error) {
        console.error('Error fetching product requests or categories:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchProductRequests();
  }, []);



  useEffect(() => {
    if (isDetailModalVisible) {
      document.body.classList.add('overflow-hidden');
    } else {
      document.body.classList.remove('overflow-hidden');
    }
    return () => {
      document.body.classList.remove('overflow-hidden');
    };
  }, [isDetailModalVisible]);


  const handleCreateProduct = () => {
    setShowCreateProduct(true);
  };

  const handleCancelRequest = async (requestId) => {
    try {
      await axios.delete(`${api_url}/products/${requestId}`);
      setProductRequests((prevRequests) => prevRequests.filter((request) => request._id !== requestId));
      alert('Product request cancelled successfully.');
    } catch (error) {
      console.error('Error cancelling product request:', error);
    }
  };

  const handleViewInfo = (request) => {
    setSelectedRequest(request);
    setIsDetailModalVisible(true);
  };

  const handleCloseDetailModal = () => {
    setIsDetailModalVisible(false);
    setSelectedRequest(null);
  };


  const columns = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      align: 'center',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      render: (status) => (
        <Tag icon={status === 'pending' ? <ClockCircleOutlined /> : <CheckCircleOutlined />} color={status === 'pending' ? 'orange' : 'green'}>
          {status.charAt(0).toUpperCase() + status.slice(1)}
        </Tag>
      ),
    },
    {
      title: 'Actions',
      key: 'actions',
      align: 'center',
      render: (_, request) => (
        <Space size="middle">
          <Button type="primary" onClick={() => handleViewInfo(request)}>
            View
          </Button>
        </Space>
      ),
    },
  ];
  
  return (
    <div className="mx-auto p-2">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-3xl font-semibold text-gray-800">Product Requests</h1>
        <button
          className="px-5 py-2.5 text-xs lg:text-sm font-bold text-center text-white bg-cyan-900 rounded-lg transition-transform transform hover:scale-105 hover:bg-cyan-950 active:ring-cyan-950"
          onClick={handleCreateProduct}
        >
          Create Product
        </button>
      </div>
      {loading ? (
        <Spinner fullWidth={true} />
      ) : (
        <Table
          columns={columns}
          dataSource={productRequests}
          rowKey="_id"
          onRow={(record) => ({
            onClick: () => handleViewInfo(record),
          })}
          pagination={{ pageSize: 5 }}
          className="shadow-md rounded-lg"
        />
      )}
      {selectedRequest && (
        <ProductDetailModal
          visible={isDetailModalVisible}
          onClose={handleCloseDetailModal}
          request={selectedRequest}
          categories={categories}
        />
      )}
    </div>
  );
};

export default ProductManagement;
