import React, { useEffect, useState } from "react";
import axios from "axios";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Featured from "../components/Featured";
import NewProducts from "../components/NewProducts";
import { api_url } from "../api/config";
import Cards from "../components/Cards";
import FeedBack from "../components/FeedBack";
import Catching from "../components/Catching";
import Welcome from "../components/Welcome";
import WavingHand from "../components/loader/WavingHand";


const Home = () => {
    const [newProducts, setNewProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    

    // Fetch new products from the server
    const fetchNewProducts = async () => {
        try {
            
            const response = await axios.get(`${api_url}/products/`, {
                headers: {
                    "Content-Type": "application/json",
                },
            });
            const sortedProducts = response.data.sort((a, b) => b._id - a._id); // Sorting products by _id in descending order
            const limitedProducts = sortedProducts.slice(0, 4); // Limiting to 2 products
            setNewProducts(limitedProducts);
            console.log("Fetched new products:", limitedProducts);
        } catch (error) {
            setError("Error fetching new products. Please try again later.");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchNewProducts();
    }, []);

    useEffect(() => {
        document.title = "Home | PICLAB";
        const WavingHand = setTimeout(() => {
          setLoading(false);
        }, 1000);
        return () => clearTimeout(WavingHand);
      }, []);
    
        if (loading) return <WavingHand />;

    return (
        <>
        <div className="flex flex-col min-h-screen bg-white">
            <Header />
            <main className="flex-grow">
                {loading ? (
                    <div className="flex justify-center items-center h-full">
                        <div className="text-2xl text-gray-600">Loading...</div>
                    </div>
                ) : error ? (
                    <div className="flex justify-center items-center h-full">
                        <div className="text-2xl text-red-500">{error}</div>
                    </div>
                ) : (
                    <>
                        <Featured products={newProducts} />
                        <NewProducts products={newProducts} />
                        <Welcome />
                        <Catching />
                        <FeedBack />
                        <Cards />
                    </>
                )}
            </main>
            <Footer />
        </div>
        </>
    );
};

export default Home;
