// src/components/InputImage.js
import React, { useState } from 'react';
import axios from 'axios';
import { Input, Button, Radio, Upload, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { api_url } from '../../api/config';

const inputOptions = [
  {
    name: 'Image URL',
    value: 'imageURL',
  },
  {
    name: 'Upload Image',
    value: 'uploadImage',
  },
];

export default function InputImage({ setOutputs, setImageToPredict }) {
  const [inputOption, setInputOption] = useState('imageURL');
  const [imageUrl, setImageUrl] = useState('');
  const [fileObj, setFileObj] = useState(null);

  const handleChangeImageUrl = (e) => {
    setImageUrl(e.target.value);
  };
  console.log(imageUrl)

  const predictImage = () => {
    setOutputs([]);
    setImageToPredict(imageUrl);
    axios
      .post(`${api_url}/predict`, { imageUrl })
      .then((res) => {
        setOutputs(res.data.results);
      })
      .catch((err) => {
        console.error(err);
        message.error('Failed to predict image. Please check the console for more details.');
      });
  };

  const predictImageViaUpload = () => {
    setOutputs([]);
    const formData = new FormData();
    formData.append('file', fileObj);
    const reader = new FileReader();
    reader.addEventListener('load', function () {
      setImageToPredict(reader.result);
    });

    if (fileObj) {
      reader.readAsDataURL(fileObj);
    }

    axios
      .post('/api/predict/upload', formData)
      .then((res) => {
        setOutputs(res.data.results);
      })
      .catch((err) => {
        console.error(err);
        message.error('Failed to predict image via upload. Please check the console for more details.');
      });
  };

  const handleFileFormControlOnChange = (info) => {
    if (info.fileList.length) {
      setFileObj(info.fileList[0].originFileObj);
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
      <Radio.Group
        options={inputOptions}
        onChange={(e) => setInputOption(e.target.value)}
        value={inputOption}
        optionType="button"
        buttonStyle="solid"
      />

      {inputOption === 'imageURL' ? (
        <div>
          <Input value={imageUrl} placeholder="Image URL" onChange={handleChangeImageUrl} />
          <Button type="primary" onClick={predictImage} style={{ marginTop: '8px' }}>
            Submit
          </Button>
        </div>
      ) : (
        <div>
          <Upload
            beforeUpload={() => false}
            onChange={handleFileFormControlOnChange}
            accept="image/*"
            showUploadList={false}
          >
            <Button icon={<UploadOutlined />}>Click to Upload</Button>
          </Upload>
          <Button type="primary" onClick={predictImageViaUpload} style={{ marginTop: '8px' }}>
            Submit
          </Button>
        </div>
      )}
    </div>
  );
}
