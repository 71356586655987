import styled from 'styled-components';

const WhiteBox = styled.div`
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  width: 100%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

export default WhiteBox;