import React, { useContext } from "react";
import { AddressContext } from "../../components/contexts/AddressContext";
import AddressCard from "../../components/AddressCard";
import { useNavigate, useLocation } from "react-router-dom";
// If using Font Awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

const Address = () => {
  const { addresses, setShowAddForm, setAddressToEdit } = useContext(AddressContext);
  const navigate = useNavigate();
  const location = useLocation();
  console.log("addresses-test", addresses[0]);

  const formHandler = (address = null) => {
    console.log("address", address);
    setAddressToEdit(addresses[0]);
    setShowAddForm(true);
  };

  const isDifferentURL = location.pathname !== "/checkout"; // replace '/expected-path' with the actual path

  return (
    <div className="top-0">
      <div className="flex justify-between my-4">
        {!isDifferentURL && (
          <button
            onClick={() => navigate(-1)}
            className="px-2 py-1 text-xs lg:text-sm font-medium text-center text-white bg-gray-500 rounded-lg transition-transform transform hover:scale-105 hover:bg-gray-600 active:ring-gray-600"
            type="button"
          >
            <FontAwesomeIcon icon={faArrowLeft} /> Back
          </button>
        )}
        <div className="flex-1 flex justify-end">
          <button
            onClick={formHandler}
            className="px-5 py-2.5 text-xs lg:text-sm font-medium text-center text-white bg-cyan-900 rounded-lg transition-transform transform hover:scale-105 hover:bg-cyan-950 active:ring-cyan-950"
            type="button"
          >
            {addresses.length === 0 ? "Add Address" : "Update Address"}
          </button>
        </div>
      </div>
      <div className="rounded-lg">
        {addresses && addresses.length > 0 ? (
          addresses.map((address) => (
            <AddressCard key={address.id} address={address} />
          ))
        ) : (
          <p>No addresses available.</p>
        )}
      </div>
    </div>
  );
};

export default Address;
