import React, { useContext, useEffect, useState } from "react";
import Header from "../components/Header";
import styled, { keyframes } from "styled-components";
import Center from "../components/Center";
import Button from "../components/Button";
import { CartContext } from "../components/CartContext";
import axios from "axios";
import Table from "../components/Table";
import Footer from "../components/Footer";
import { api_url } from "../api/config";
import CartCheckoutDetail from "../components/CartCheckoutDetails";
import WavingHand from "../components/loader/WavingHand";
import { notification } from "antd";
import { isLogged } from "../auth/AuthContext";
import { Link } from "react-router-dom";

const dropAnimation = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const StyledRevealWrapper = styled.div`
  opacity: 0;
  animation: ${dropAnimation} 0.5s ease forwards;
  animation-delay: ${(props) => props.delay || "0s"};
`;

const ColumnsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  @media screen and (min-width: 768px) {
    grid-template-columns: 1.2fr 0.8fr;
  }
  gap: 20px;
  margin-top: 40px;
  margin-bottom: 40px;
  table thead tr th:nth-child(3),
  table tbody tr td:nth-child(3),
  table tbody tr.subtotal td:nth-child(2) {
    text-align: right;
  }
  table tr.subtotal td {
    padding: 15px 0;
  }
  table tbody tr.subtotal td:nth-child(2) {
    font-size: 1.4rem;
  }
  tr.total td {
    font-weight: bold;
  }
`;

const Box = styled.div`
  color: black;
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
`;

const ProductInfoCell = styled.td`
  color: black;
  padding: 10px 0;
  button {
    padding: 0 !important;
  }
`;

const ProductImageBox = styled.div`
  width: 70px;
  height: 100px;
  padding: 2px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  img {
    max-width: 60px;
    max-height: 60px;
  }
  @media screen and (min-width: 768px) {
    padding: 10px;
    width: 100px;
    height: 100px;
    img {
      max-width: 80px;
      max-height: 80px;
    }
  }
`;

const QuantityLabel = styled.span`
  color: black;
  padding: 0 15px;
  display: block;
  @media screen and (min-width: 768px) {
    display: inline-block;
    padding: 0 6px;
  }
`;

const CityHolder = styled.div`
  display: flex;
  gap: 5px;
`;

const CartPage = () => {
  const { cartProducts, addProduct, removeProduct, clearCart } = useContext(CartContext);
  const [session, setSession] = useState(null);
  const [products, setProducts] = useState([]);
  const [isSuccess, setIsSuccess] = useState(false);
  const [loading, setLoading] = useState(true);

  console.log("in cart products", products);

  useEffect(() => {
    const fetchProductsByIds = async () => {
      try {
        const response = await axios.get(`${api_url}/products/`);
        const filteredProducts = response.data.filter(product =>
          cartProducts.map(item => item.productId).includes(product._id)
        );
        setProducts(filteredProducts);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    if (cartProducts.length > 0) {
      fetchProductsByIds();
    } else {
      setProducts([]);
    }
  }, [cartProducts]);

  useEffect(() => {
    if (typeof window === "undefined") {
      return;
    }
    if (window?.location.href.includes("success")) {
      setIsSuccess(true);
      clearCart();
    }
  }, []);

  useEffect(() => {
    if (!session) {
      return;
    }
  }, [session]);

  const moreOfThisProduct = (id) => {
      // addProduct(id);
      notification.error({ message: "You can't add more of this product" });
  };

  const lessOfThisProduct = (id) => {
    removeProduct(id);
    notification.success({ message: "Product removed from cart" });
  };

  let productsTotal = 0;
  for (const cartItem of cartProducts) {
    const product = products.find(p => p._id === cartItem.productId);
    if (product) {
      productsTotal += product.price * cartItem.quantity;
    }
  }

  const formattedTotal = productsTotal.toFixed(2);
  productsTotal = parseFloat(formattedTotal);


  useEffect(() => {
    document.title = "Cart | PICLAB";
    const wavingHand = setTimeout(() => {
      setLoading(false);
    }, 1000);
    return () => clearTimeout(wavingHand);
  }, []);

  if (loading) return <WavingHand />;

  
  if (isSuccess) {
    return (
      <>
        <Header />
        <Center>
          <ColumnsWrapper>
            <Box>
              <h1>Thanks for your order!</h1>
              <p>We will email you when your order is shipped.</p>
            </Box>
          </ColumnsWrapper>
        </Center>
      </>
    );
  }

  return (
    <>
      <div className="flex flex-col min-h-screen bg-gray-100">
        <Header />
        <main className="flex-grow">
          <Center>
            <ColumnsWrapper>
              <StyledRevealWrapper delay={0}>
                <Box>
                  <h2 className="text-center text-lg font-semibold mb-4">Your Cart</h2>
                  {!isLogged() ? (
                    <div className="text-center">
                      <p>This function is not available, Please Login</p>
                      <Link to="/login">
                        <button className="w-3/12 mt-3 px-5 py-2.5 text-xs lg:text-sm font-bold text-center text-white bg-cyan-900 rounded-lg transition-transform transform hover:scale-105 hover:bg-cyan-950 active:ring-cyan-950">
                          Login
                        </button>
                      </Link>
                    </div>
                  ) : (
                    cartProducts.length === 0 ? (
                      <div className="text-center">
                        <p>Your cart is empty</p>
                        <Link to="/products">
                          <button className="w-3/12 mt-3 px-5 py-2.5 text-xs lg:text-sm font-bold text-center text-white bg-cyan-900 rounded-lg transition-transform transform hover:scale-105 hover:bg-cyan-950 active:ring-cyan-950">
                            Shop Now
                          </button>
                        </Link>
                      </div>
                    ) : (
                      products?.length > 0 && (
                        <Table>
                          <thead>
                            <tr>
                              <th>Product</th>
                              <th>Quantity</th>
                              <th>Price</th>
                            </tr>
                          </thead>
                          <tbody>
                            {products && products.map((product) => (
                              <tr key={product._id}>
                                <ProductInfoCell>
                                  <Link to={`/products/${product._id}`}>
                                  <ProductImageBox>
                                    <img src={product.image} alt={product.title} />
                                  </ProductImageBox>
                                  </Link>
                                  {product.title}
                                </ProductInfoCell>
                                <td>
                                  <Button onClick={() => lessOfThisProduct(product._id)}>
                                    -
                                  </Button>
                                  <QuantityLabel>
                                    {cartProducts.filter(item => item.productId === product._id).reduce((total, item) => total + item.quantity, 0)}
                                  </QuantityLabel>
                                  <Button onClick={() => moreOfThisProduct(product._id)}>
                                    +
                                  </Button>
                                </td>
                                <td>
                                  ${cartProducts
                                    .filter(item => item.productId === product._id)
                                    .reduce((total, item) => total + item.quantity * product.price, 0).toFixed(2)
                                  }
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      )
                    )
                  )}
                </Box>
              </StyledRevealWrapper>
              {!!cartProducts?.length && isLogged() && (
                <StyledRevealWrapper delay={100}>
                  <CartCheckoutDetail
                    products={products}
                    productsTotal={productsTotal}
                  />
                </StyledRevealWrapper>
              )}
            </ColumnsWrapper>
          </Center>
        </main>
        <Footer />
      </div>
    </>
  );
};

export default CartPage
