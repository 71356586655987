import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import MoonLoader from "react-spinners/MoonLoader";
import Swal from "sweetalert2";
import { login } from "../../auth/AuthContext";
import styled, { keyframes } from "styled-components";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Lottie from "lottie-react";
import loginAsset from '../../assets/loginAsset.json'; // Adjust the path as needed
import { FaEye, FaEyeSlash } from "react-icons/fa";
import Turnstile from 'react-turnstile';
import axios from 'axios';
import { api_url } from "../../api/config";

const dropAnimation = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const StyledRevealWrapper = styled.div`
  opacity: 0;
  animation: ${dropAnimation} 0.5s ease forwards;
  animation-delay: ${(props) => props.delay || "0s"};
`;

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [turnstileToken, setTurnstileToken] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const errors = {};

    if (!email.trim()) {
      errors.email = "Email is required";
    }

    if (!password.trim()) {
      errors.password = "Password is required";
    }

    if (!turnstileToken) {
      errors.turnstile = "Verification is required";
    }

    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      setIsLoading(false);
      return;
    }

    try {
      const turnstileResponse = await axios.post(`${api_url}/turnstile/verify-turnstile`, { 
        token: turnstileToken 
      });
      if (turnstileResponse.data.success === false) {
        setErrors({ turnstile: 'Verification failed' });
        setIsLoading(false);
        return;
      }

      await login({
        email,
        password,
        setIsLoading,
        setErrors,
        navigate,
      });
    } catch (error) {
      console.error("Login Error:", error);
      setIsLoading(false);
      Swal.fire({
        icon: "error",
        title: "Login Error",
        text: "An error occurred while logging in. Please try again.",
      });
    }
  };

  return (
    <div className="flex flex-col min-h-screen bg-gray-50">
      <Header />
      <div className="flex-grow flex justify-center items-center lg:pr-10">
        <div className="hidden lg:block w-1/2 lg:pl-40">
          <Lottie animationData={loginAsset} loop={true} />
        </div>
        <StyledRevealWrapper className="relative z-10 mt-24 mb-24 sm:mx-auto sm:w-full sm:max-w-md px-2">
          <div className="w-full max-w-sm shadow-2xl bg-white py-4 px-4  border border-1 rounded-xl sm:px-10 ">
            <p className="flex text-gray-700 font-bold text-xl justify-center items-center mb-4 mt-2">
              Login to your account
            </p>
            <form method="POST" onSubmit={handleSubmit} className="relative">
              <div className="mt-6">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium leading-5 text-gray-700"
                >
                  Email address
                </label>
                <div className="mt-1 relative rounded-md shadow-sm">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    required
                    placeholder="Enter your email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className={`mt-1 appearance-none block w-full px-3 py-2 border text-gray-800 border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5 ${
                      errors.email ? "border-red-500" : ""
                    }`}
                  />
                  {errors.email && (
                    <p className="text-xs text-red-600 mt-1">
                      {errors.email}
                    </p>
                  )}
                </div>
              </div>

              <div className="mt-6">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium leading-5 text-gray-700"
                >
                  Password
                </label>
                <div className="mt-1 relative rounded-md shadow-sm">
                  <input
                    id="password"
                    name="password"
                    placeholder="********"
                    type={showPassword ? "text" : "password"}
                    required
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className={`mt-1 appearance-none block w-full px-3 py-2 border text-gray-800 border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5 ${
                      errors.password ? "border-red-500" : ""
                    }`}
                  />
                  {errors.password && (
                    <p className="text-xs text-red-600 mt-1">
                      {errors.password}
                    </p>
                  )}
                  <button
                      type="button"
                    onClick={(e) => {
                      e.preventDefault();
                      setShowPassword(!showPassword);
                    }}
                    className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5"
                  >
                    {showPassword ? <FaEye /> : <FaEyeSlash />}
                  </button>
                </div>
              </div>

              <div className="mt-6">
                <Turnstile
                  sitekey={process.env.REACT_APP_TURNSTILE_SITE_KEY}
                  onVerify={(token) => {
                    setTurnstileToken(token);
                  }}
                  theme="light"  // Set theme to light
                />
                {errors.turnstile && (
                  <p className="text-xs text-red-600 mt-1">
                    {errors.turnstile}
                  </p>
                )}
              </div>

              <div className="mt-6 flex items-center justify-between">
                <a
                  href="/verify-reset-password"
                  className="text-sm font-medium text-blue-500 hover:text-blue-400 focus:outline-none focus:underline transition ease-in-out duration-150"
                >
                  Forgot Password?
                </a>
              </div>

              <div className="mt-4">
                <button
                  type="submit"
                  className="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out"
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <div className="flex items-center gap-1">
                      <MoonLoader size={20} color="#fff" loading={true} />
                      <span className="text-xs">Checking...</span>
                    </div>
                  ) : (
                    "Login"
                  )}
                </button>
              </div>

              <p className="mt-2 text-center text-sm leading-5 text-gray-500 max-w">
                Don't have an account yet?&nbsp;
                <a
                  href="/register"
                  className="font-medium text-blue-600 hover:text-blue-500 focus:outline-none focus:underline transition ease-in-out duration-150"
                >
                  Register Now
                </a>
              </p>
            </form>
          </div>
        </StyledRevealWrapper>
      </div>
      <Footer />
    </div>
  );
};

export default Login;
