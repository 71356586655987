import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import FlyingButton from "./FlyingButton";
import WishlistButton from "./buttons/WishlistButton";
import { api_url, decodedToken, decodedUserID } from "../api/config";
import { ProductContext } from "./contexts/ProductContext";
import { FaStar } from 'react-icons/fa';
import { Popover, Tooltip, Button, Modal, message, Row, Col, Card } from "antd";
import { 
  ShareAltOutlined, 
  DownloadOutlined, 
  CopyOutlined, 
  FacebookOutlined, 
  TwitterOutlined, 
  LinkedinOutlined, 
  WhatsAppOutlined, 
  SendOutlined 
} from '@ant-design/icons';
import QRCode from 'qrcode.react';
import styled from "styled-components";

const IconButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 5px;
  width: 5px;
  margin: 5px;
  border-radius: 50%;
  border: none;
  &:hover {
    opacity: 0.8;
  }
`;

const DownloadButton = styled(Button)`
  background-color: #ff4d4f;
  border: none;
  &:hover {
    background-color: #ff7875;
  }
`;

const CopyButton = styled(Button)`
  background-color: #52c41a;
  border: none;
  &:hover {
    background-color: #73d13d;
  }
`;

const ModalContent = styled.div`
  text-align: center;
`;

const QRWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
`;

const SocialMediaWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 20px;
`;

const SocialMediaButton = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const SocialMediaIcon = styled.div`
  font-size: 24px;
  margin-bottom: 4px;
`;

const GuideText = styled.p`
  font-size: 1rem;
  color: #555;
  margin-bottom: 20px;
`;

const ProductBox = ({
  _id,
  title,
  price,
  image,
  wished = false,
  fromWishlist = false,
  sell_status,
}) => {
  const url = `/products/${_id}`;
  let productUrl = "";
  const [isWished, setIsWished] = useState(wished);
  const [wishlistId, setWishlistId] = useState(null);
  const [totalRating, setTotalRating] = useState(0);
  const { removeFromWishlist } = useContext(ProductContext);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const pathname = window.location.pathname;

  if (pathname === "/") {
    productUrl = window.location.href + `products/${_id}`;
  } else if (pathname === "/products") {
    productUrl = window.location.href + `/${_id}`;
  }

  const fetchWishlist = async () => {
    try {
      const response = await axios.get(`${api_url}/wishlists/${decodedUserID}`);
      const wishlist = response.data.wishList;

      if (wishlist && wishlist.products_wish_list) {
        setWishlistId(wishlist._id);
      }
    } catch (error) {
      console.error("Error fetching wishlist:", error);
    }
  };

  const fetchTotalRating = async () => {
    try {
      const response = await axios.get(`${api_url}/products/${_id}/reviews`, {
        headers: {
          Authorization: decodedToken,
        },
      });
      const reviews = response.data;
      const totalRating = reviews.reduce((acc, review) => acc + review.stars, 0) / reviews.length || 0;
      setTotalRating(totalRating);
    } catch (error) {
      console.error("Error fetching reviews:", error);
    }
  };

  useEffect(() => {
    if (decodedUserID) {
      fetchWishlist();
    }
    fetchTotalRating();
  }, []);

  useEffect(() => {
    if (isModalVisible) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isModalVisible]);

  const handleRemoveFromWishlist = (e) => {
    e.stopPropagation(); // Prevent event bubbling
    removeFromWishlist({ _id, title, price, image }); // Call the removeFromWishList function
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleCopyLink = () => {
    navigator.clipboard.writeText(productUrl);
    message.success('Link copied to clipboard');
  };

  const handleDownloadQR = () => {
    const canvas = document.querySelector('canvas');
    const pngUrl = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream');
    const downloadLink = document.createElement('a');
    downloadLink.href = pngUrl;
    downloadLink.download = 'product-qr-code.png';
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  return (
    <div className="w-full text-center md:text-left">
      <div className="bg-white w-full h-30 flex items-center justify-center rounded-lg relative shadow-md transition-transform duration-300 ease-in-out transform hover:-translate-y-1">
        <Link to={url} className="block w-full h-full">
          <div className="relative w-full h-full flex items-center justify-center">
            <img src={image} alt={title} className="w-52 h-30 object-cover rounded-lg" style={{ width: "400px", height: "130px" }} />
          </div>
        </Link>
        {!fromWishlist && <WishlistButton product={{ _id, title, price, image }} />}
      </div>
      <div className="text-black mt-1 text-left">
        <div className="flex items-center justify-between">
          <Popover content={title}>
            <Link to={url} className="tracking-tight font-poppins font-medium text-base text-black no-underline">
              {title.length > 15 ? `${title.slice(0, 15)}...` : title}
            </Link>
          </Popover>
          <Tooltip title="Share Product">
            <IconButton shape="circle" icon={<ShareAltOutlined />} onClick={showModal} />
          </Tooltip>
        </div>
        <div className="flex items-center py-0.5 mt-1 md:mt-0 md:ml-auto">
          <span className="flex items-center text-xs mr-3 font-semibold px-2 py-0.5 rounded bg-cyan-900 bg-opacity-80 text-gray-100">
            <FaStar className="mr-1" />{totalRating.toFixed(1)}
          </span>
        </div>
        {sell_status !== "false" && (
          <div className="flex flex-col md:flex-row md:gap-1 justify-between mt-0.5">
            <div className="text-black text-sm md:text-lg font-bold md:font-bold">
              ${price}
            </div>
            <div className="mt-2 md:mt-0 md:ml-auto">
              <FlyingButton _id={_id} src={image} />
            </div>
          </div>
        )}
        <Modal title="Share Product" open={isModalVisible} onCancel={handleCancel} footer={null} centered>
          <ModalContent>
            <Card style={{ marginBottom: 20 }}>
              <GuideText>Scan the QR code to share this product:</GuideText>
              <QRWrapper>
                <QRCode value={productUrl} size={150} />
              </QRWrapper>
              <Row justify="center" gutter={16}>
                <Col>
                  <Tooltip title="Download QR Code">
                    <DownloadButton type="primary" shape="circle" icon={<DownloadOutlined />} onClick={handleDownloadQR} />
                  </Tooltip>
                  <div>Download</div>
                </Col>
                <Col>
                  <Tooltip title="Copy Link">
                    <CopyButton type="primary" shape="circle" icon={<CopyOutlined />} onClick={handleCopyLink} />
                  </Tooltip>
                  <div>Copy Link</div>
                </Col>
              </Row>
            </Card>
            <Card>
              <GuideText>Share this product on social media:</GuideText>
              <SocialMediaWrapper>
                <SocialMediaButton>
                  <Tooltip title="Share on Facebook">
                    <IconButton shape="circle" icon={<FacebookOutlined />} href={`https://www.facebook.com/sharer/sharer.php?u=${productUrl}`} target="_blank" />
                  </Tooltip>
                  <div>Facebook</div>
                </SocialMediaButton>
                <SocialMediaButton>
                  <Tooltip title="Share on Twitter">
                    <IconButton shape="circle" icon={<TwitterOutlined />} href={`https://twitter.com/intent/tweet?url=${productUrl}`} target="_blank" />
                  </Tooltip>
                  <div>Twitter</div>
                </SocialMediaButton>
                <SocialMediaButton>
                  <Tooltip title="Share on LinkedIn">
                    <IconButton shape="circle" icon={<LinkedinOutlined />} href={`https://www.linkedin.com/sharing/share-offsite/?url=${productUrl}`} target="_blank" />
                  </Tooltip>
                  <div>LinkedIn</div>
                </SocialMediaButton>
                <SocialMediaButton>
                  <Tooltip title="Share on Telegram">
                    <IconButton shape="circle" icon={<SendOutlined />} href={`https://t.me/share/url?url=${productUrl}`} target="_blank" />
                  </Tooltip>
                  <div>Telegram</div>
                </SocialMediaButton>
                <SocialMediaButton>
                  <Tooltip title="Share on WhatsApp">
                    <IconButton shape="circle" icon={<WhatsAppOutlined />} href={`https://api.whatsapp.com/send?text=${productUrl}`} target="_blank" />
                  </Tooltip>
                  <div>WhatsApp</div>
                </SocialMediaButton>
              </SocialMediaWrapper>
            </Card>
          </ModalContent>
        </Modal>
      </div>
    </div>
  );
};

export default ProductBox;
