import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import About from "../components/About";
import WavingHand from "../components/loader/WavingHand";

const AboutUs = () => {

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    document.title = "Cart | PICLAB";
    const WavingHand = setTimeout(() => {
      setLoading(false);
    }, 500);
    return () => clearTimeout(WavingHand);
  }, []);

    if (loading) return <WavingHand />;

  return (
    <>
      <div className="flex flex-col min-h-screen">
        <Header />
        <main className="flex-grow">
          <About />
        </main>
        <Footer />
      </div>
    </>
  );
};

export default AboutUs;
