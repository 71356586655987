import styled from "styled-components";

const StyledTabs = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: fit-content;
  margin-bottom: 20px;
`;
const StyledTab = styled.span`
  font-size: 1.1rem;
  cursor: pointer;
  ${props => props.active ? `
    color: black;
    border-bottom: 2px solid black;
  ` : `
    color: #999;
  `}
`;

export default function Tabs({tabs, active, onChange}) {
  return (
    <StyledTabs>
      {tabs.map(tabName => (
        <StyledTab 
          key={tabName}
          onClick={() => { onChange(tabName) }}
          active={tabName === active}
        >
          {tabName}
        </StyledTab>
      ))}
    </StyledTabs>
  );
}
