import React, { useState, useEffect } from "react";
import AdminCard from "../../components/AdminCard";
import { api_url, decodedToken } from "../../api/config";
import ScaleLoader from "react-spinners/ScaleLoader";
import Cookies from "js-cookie";
import {isAdmin, isSeller} from "../../auth/AuthContext";
import SellerCard from "../seller/SellerCard";
import Footer from "../../components/Footer";

const Dashboard = () => {
  const [user, setUser] = useState([]);
  const [Products, setProducts] = useState([]);
  const [Categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);

  // const token = process.env.REACT_APP_TOKEN || decodedToken();
  const org_token = Cookies.get("token");
  const token = atob(org_token);
  useEffect(() => {
    fetch(`${api_url}/users/all`, {
      method: "GET",
      headers: {
        "Authorization": token
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setUser(data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    fetch(`${api_url}/products/`, {
      method: "GET",
      headers: {
        "Authorization": token,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setProducts(data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    fetch(`${api_url}/categories/`, {
      method: "GET",
      headers: {
        "Authorization": token,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setLoading(false);
        setCategories(data);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);
  const ProductsCount = Products.length;
  const CategoriesCount = Categories.length;




  const userCounts = user.length;

  // admin count
  // const adminCount = user.filter((usr) => usr.role === "admin").length;

  // user count
  // const userCount = user.filter((usr) => usr.role === "user").length;

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <ScaleLoader color="#c4c4c4" loading={loading} height={35} width={4} />
      </div>
    );
  }


  let adminContent = null;
  if (isAdmin()) {
    const userCounts = user.length;
    const verifiedUser = user.filter((usr) => usr.verified === true);
    const unverifiedUser = user.filter((usr) => usr.verified === false);


    const verifiedUserCount = user.filter((usr) => usr.verified).length;
    const unverifiedUserCount = user.filter((usr) => !usr.verified).length;
    const adminCount = user.filter((usr) => usr.role === "admin").length;
    const userCount = user.filter((usr) => usr.role === "user").length;

    adminContent = (
        <AdminCard
            total_Products={ProductsCount}
            total_all_users={userCounts}
            total_categories={CategoriesCount}
            total_verified={verifiedUserCount}
            total_unverified={unverifiedUserCount}
            total_admin={adminCount}
            total_users={userCount}
        />
    );
  }

  return (
    // <>
    //   <main className="pl-8 bg-white mb-10">
    //     <h1 className=" text-2xl text-black font-medium p-3">ADMIN DASHBOARD</h1>
    //     <hr />
    //     <section className="flex mt-5 w-auto justify-center items-center">
    //       <AdminCard
    //         total_Products={ProductsCount}
    //         total_all_users={userCounts}
    //         total_categories={CategoriesCount}
    //         total_verified={verifiedUserCount}
    //         total_unverified={unverifiedUserCount}
    //         total_admin={adminCount}
    //         total_users={userCount}
    //       />
    //     </section>
    //   </main>
    // </>
      <>
          <main className="pl-8 bg-white mb-10">
              <h1 className=" text-2xl text-black font-medium p-3">DASHBOARD</h1>
              <hr />
              <section className="flex mt-5 w-auto justify-center items-center">
                  {isAdmin() ? (
                      adminContent
                  ) : isSeller() ? (
                      <SellerCard
                            total_Products={ProductsCount}
                            total_categories={CategoriesCount}
                      />
                  ) : (
                      <p>You don't have permission to view this page.</p>
                  )}
              </section>
          </main>
      </>
  );
};

export default Dashboard;
