import React, { useState, useEffect } from "react";
import { api_url, decodedToken, decodedproductID } from "../../api/config";
import ReactPaginate from "react-paginate";
import { GrFormNext } from "react-icons/gr";
import { GrFormPrevious } from "react-icons/gr";
import Swal from "sweetalert2";
import { format } from "date-fns";
import {Link, BrowserRouter as Router, Route, Routes, useNavigate} from 'react-router-dom';
import { LuLayoutDashboard } from "react-icons/lu";
import { IoPeopleOutline } from "react-icons/io5";
import ScaleLoader from "react-spinners/ScaleLoader";
import { FaCaretDown } from "react-icons/fa";
import axios from "axios";
import Cookies from "js-cookie";
import WavingHand from "../../components/loader/WavingHand";
import ProductDetail from "./ProductDetail";


const ManageProducts = () => {
  const [product, setProduct] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [query, setQuery] = useState("");
  const itemsPerPage = 10;
  // const token = process.env.REACT_APP_TOKEN;
  const org_token = Cookies.get("token");
  const token = atob(org_token);
  const [showAddForm, setShowAddForm] = useState(false);
  const [showEditForm, setShowEditForm] = useState(false);
  const [selectedProductId, setSelectedProductId] = useState(false);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');

  useEffect(() => {
    fetchProducts();
  }, []);


  const navigate = useNavigate();
  const handleDetail = (productId) => {
    navigate(`/product-approve/${productId}`);
  };

  const fetchProducts = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${api_url}/products/get-by-role`, {
        method: 'GET',
        headers: {
          Authorization: token,
        },
      });
      const data = await response.json();
      // Fetch category names for each product
      const productsWithCategories = await Promise.all(
          data.map(async (product) => {
            const categoryNamesPromises = product.categories.map((categoryId) =>
                fetchCategoryName(categoryId)
            );
            const categoryNames = await Promise.all(categoryNamesPromises);
            return { ...product, categoryNames };
          })
      );
      // console.log(productsWithCategories);
      setProduct(productsWithCategories);
      setLoading(false);
    } catch (err) {
      console.error('Error fetching products:', err);
      setLoading(false);
    }
  };

  const handleAdd = async (e) => {
    e.preventDefault();
    console.log(document.getElementById("title").value);
    console.log(document.getElementById("desc").value);
    console.log(document.getElementById("price").value);
    console.log(document.getElementById("size").value);
    console.log(selectedCategory);
    console.log(document.getElementById("image").value);
    console.log(document.getElementById("file").files[0]);

    try {
      const formData = new FormData();
      formData.append('title', document.getElementById("title").value);
      formData.append('desc', document.getElementById("desc").value);
      formData.append('price', document.getElementById("price").value);
      formData.append('size', document.getElementById("size").value);
      formData.append('categories', selectedCategory);
      formData.append('image', document.getElementById("file").files[0]);

      // Log FormData entries
      // for (let pair of formData.entries()) {
      //   console.log(pair[0] + ': ' + pair[1]);
      // }

      // console.log(formData);

      const response = await fetch(`${api_url}/products/add-product`, {
        method: "POST",
        headers: {
          Authorization: token,
        },
        body: formData,
      });
      // console.log("Response:", response);

      if (!response.ok) {
        throw new Error("Failed to add product");
      }

      // Clear form fields on successful submission
      document.getElementById("title").value = "";
      document.getElementById("desc").value = "";
      document.getElementById("price").value = "";
      document.getElementById("size").value = "";
      document.getElementById("file").value = "";

      // Reset selectedCategory state
      setSelectedCategory('');

      setShowAddForm(false);
      window.location.reload();
      // Handle success, e.g., show success message
    } catch (error) {
      console.error("Error adding product:", error.message);
      // Handle error, e.g., show error message to user
    }
  };




  const handleSearch = (e) => {
    e.preventDefault();
    // console.log('Search query:', query);
    if (query.trim() === "") {
      fetchProducts();
      return;
    }
    setLoading(true);
    fetch(`${api_url}/products/search?phrase=${query}`, {
      method: "GET",
      headers: {
        Authorization: token,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        // console.log('Data received:', data);
        setProduct(data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const currentproducts = product.slice(
    currentPage * itemsPerPage,
    (currentPage + 1) * itemsPerPage
  );

  // const currentproducts = product;
  const handleDelete = (productId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        fetch(`${api_url}/products/${productId}`, {
          method: "DELETE",
          headers: {
            Authorization: token,
          },
        })
          .then((res) => res.json())
          .then((data) => {
            Swal.fire("Deleted!", data.message, "success");
            setProduct(product.filter((product) => product._id !== productId));
          })
          .catch((err) => {
            console.log(err);
            Swal.fire("Error!", "An error occurred", "error");
          });
      }
    });
  };

  const handleEdit = (productId) => {
    setSelectedProductId(productId); // Set the selected product ID
    setShowEditForm(true); // Show the edit form
  };

  const handleUpdate = async (updatedProductData) => {
    // Implement logic to update the product details using the updatedProductData
    console.log("Updated product data:", updatedProductData);
    // Clear the selected product ID to hide the edit form
    // Reload the products list
    fetchProducts();
  };

  function formatDate(date) {
    return format(new Date(date), "dd/MM/yyyy");
  }

  const fetchCategoryName = async (categoryId) => {
    try {
      const response = await axios.get(`${api_url}/categories/${categoryId}`, {
        headers: {
          Authorization: token,
        },
      });
      return response.data.name; // Assuming the category name is in the 'name' field
    } catch (error) {
      console.error("Error fetching category:", error);
      return "Unknown Category"; // Default category name if fetch fails
    }
  };

  useEffect(() => {
    // Fetch categories from the API
    const fetchCategories = async () => {
      try {
        const response = await fetch(`${api_url}/categories`);
        const data = await response.json();
        setCategories(data);
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };
    console.log(fetchCategories());
  }, []);

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    handleAdd(event, selectedCategory);
  };

  if (loading) return <WavingHand />;

  return (
    <>
          <main className="bg-white px-3 mb-20 ">
        <div className="container mx-auto px-4 sm:px-8">
          <div className="py-2">
            <div className="my-2">
              {/* <div className=" breadcrumbs">
                <ul>
                  <li className="text-lg">
                    products
                  </li>
                </ul>
              </div> */}
              <div className="bg-white rounded-lg border border-1 shadow-md p-2 md:p-4">
                <div className="flex justify-between items-center">
                  <h3 className="text-2xl font-medium text-black">
                    Product Management
                  </h3>
                  <form
                    onSubmit={handleSearch}
                    class="flex items-center max-w-sm mx-auto"
                  >
                    <label for="simple-search" class="sr-only">
                      Search
                    </label>
                    <div class="relative w-full">
                      <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                        <svg
                          class="w-4 h-4 text-gray-500"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 18 20"
                        >
                          <path
                            stroke="currentColor"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M3 5v10M3 5a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm0 10a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm12 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm0 0V6a3 3 0 0 0-3-3H9m1.5-2-2 2 2 2"
                          />
                        </svg>
                      </div>
                      <input
                        type="text"
                        id="simple-search"
                        value={query}
                        onChange={(e) => setQuery(e.target.value)}
                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5  "
                        placeholder="Search..."
                      />
                    </div>
                    <button
                      type="submit"
                      class="p-2.5 ms-2 text-sm font-medium text-white bg-blue-700 rounded-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300"
                    >
                      <svg
                        class="w-4 h-4"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 20 20"
                      >
                        <path
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                        />
                      </svg>
                      <span class="sr-only">Search</span>
                    </button>
                  </form>
                  <ReactPaginate
                    itemsPerPage={itemsPerPage}
                    totalItems={product.length}
                    currentPage={currentPage}
                    previousLabel={<GrFormPrevious />}
                    nextLabel={<GrFormNext />}
                    breakLabel={".."}
                    breakClassName={"break-me"}
                    pageCount={Math.ceil(product.length / itemsPerPage)}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={3}
                    onPageChange={({ selected }) => setCurrentPage(selected)}
                    containerClassName={
                      "pagination flex justify-center mt-4 mb-4"
                    }
                    pageClassName={"mx-1"}
                    pageLinkClassName={
                      "px-2 py-1 rounded bg-white text-black hover:bg-gray-300 hover:text-white transition-colors duration-200"
                    }
                    activeLinkClassName={"bg-blue-500 text-black"}
                    previousLinkClassName={
                      "px-1  flex justify-center py-1 rounded text-black hover:bg-gray-300 hover:text-white transition-colors duration-200"
                    }
                    nextLinkClassName={
                      "px-1 py-1  flex justify-center rounded text-black hover:bg-gray-300 hover:text-white transition-colors duration-200"
                    }
                    disabledClassName={"opacity-50 cursor-not-allowed"}
                    activeClassName={"activePage"}
                  />
                </div>

                <div className="mt-2">
                  <table className="table-auto w-full">
                    <thead>
                    <tr className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
                      <th className="py-3 px-3 text-left">Image</th>
                      <th className="py-3 px-3 text-left">Title</th>
                      <th className="py-3 px-3 text-left">
                        Description Name
                      </th>
                      <th className="py-3 px-3 text-left">Categories</th>
                      <th className="py-3 px-3 text-center">Size</th>
                      <th className="py-3 px-3 text-center">Price</th>
                      <th className="py-3 px-3 text-center">Created at</th>
                      <th className="py-3 px-3 text-center">Status</th>
                      <th className="py-3 px-3 text-center">Actions</th>
                      <th className="py-1 px-3 text-left">
                        <button
                            onClick={() => setShowAddForm(true)}
                            className="px-4 py-2 bg-blue-500 text-white rounded-md shadow hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        >
                          Add Product
                        </button>
                      </th>
                    </tr>
                    </thead>
                    <tbody className="text-gray-600 text-sm font-light">
                      {loading ? (
                        <tr>
                          <td colspan="7" className="text-center py-4">
                            <div className="container mx-auto px-4 sm:px-8">
                              <div
                                style={{
                                  height: "615px",
                                }}
                                className="flex justify-center items-center"
                              >
                                <ScaleLoader
                                  color="#c4c4c4"
                                  loading={loading}
                                />
                              </div>
                            </div>
                          </td>
                        </tr>
                      ) : product.length === 0 ? (
                        <tr>
                          <td colSpan="5" className="text-center py-4">
                            No products found
                          </td>
                        </tr>
                      ) : (
                        currentproducts.map((product, index) => (
                            <tr
                                key={index}
                                className="border-b border-gray-200 hover:bg-gray-100"
                            >
                              <td className="py-1 px-3 text-left whitespace-nowrap">
                                <div className="flex items-center">
                                  <img
                                      alt="user"
                                      className="h-16 w-16 rounded-full"
                                      src={product.image}
                                  />
                                </div>
                              </td>
                              <td className="py-1 px-3 text-left whitespace-nowrap">
                                <div className="flex items-center">
                                <span className="font-medium">
                                  {product.title}
                                </span>
                                </div>
                              </td>
                              <td className="py-1 px-3 text-left whitespace-nowrap">
                                <div className="flex items-center">
                                <span className="font-medium">
                                  {product.desc}
                                </span>
                                </div>
                              </td>
                              <td className="py-1 px-3 text-left">
                                <div className="flex items-center font-medium">
                                  <span>{product.categoryNames.join(", ")}</span>
                                </div>
                              </td>
                              <td className="py-1 px-3 text-left">
                                <div className="flex items-center font-medium">
                                  <span>{product.size}</span>
                                </div>
                              </td>
                              <td className="py-1 px-3 text-left">
                                <div className="flex items-center font-medium">
                                  <span>{product.price}</span>
                                </div>
                              </td>
                              <td className="py-1 px-3 text-center">
                              <span className=" py-1 px-3 rounded-full text-xs font-medium">
                                {formatDate(product.createdAt)}
                              </span>
                              </td>
                              <td className="py-1 px-3 text-left">
                                <div className="flex items-center font-medium">
                                  <span>{product.status}</span>
                                </div>
                              </td>
                              <td>
                              <tr>
                                  <td className="py-1 px-3 text-center">
                                    <button
                                        onClick={() => handleEdit(product._id)}
                                        className="bg-green-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded"
                                    >
                                      Edit
                                    </button>
                                  </td>
                                  <td className="py-1 px-3 text-center">
                                    <button
                                        onClick={() => handleDelete(product._id)}
                                        className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded"
                                    >
                                      Delete
                                    </button>
                                  </td>
                                  <td className="py-1 px-3 text-center">
                                    <button
                                        onClick={() => handleDetail(product._id)}
                                        className="bg-green-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded"
                                    >
                                      Detail
                                    </button>
                                  </td>
                                  
                                </tr>
                              </td>
                                
                            </tr>
                        ))
                      )}
                      <br/>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>

      {/* Add product form */}
      {showAddForm && (
          <div className="fixed z-50 inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <div
                  className="fixed inset-0 transition-opacity"
                  aria-hidden="true"
              >
                <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
              </div>

              <span
                  className="hidden sm:inline-block sm:align-middle sm:h-screen"
                  aria-hidden="true"
              >
              &#8203;
            </span>

              <div
                  className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                <div className="mt-8 flex text-3xl justify-center items-center ">Add Product</div>
                {/* Add product form */}
                <form onSubmit={handleAdd} className="p-6" encType="multipiart/form-data">
                  {/* Form fields */}
                  <div className="mb-4">
                    <label
                        htmlFor="title"
                        className="block text-sm font-medium text-gray-700"
                    >
                      Title
                    </label>
                    <input
                        type="text"
                        name="title"
                        id="title"
                        placeholder="Enter product title"
                        className="py-2 px-4 text-black focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-500 rounded-md bg-gray-200 dark:bg-gray-200"
                    />
                  </div>
                  <div className="mb-4">
                    <label
                        htmlFor="desc"
                        className="block text-sm font-medium text-gray-700"
                    >
                      Description
                    </label>
                    <input
                        type="text"
                        name="desc"
                        id="desc"
                        placeholder="Enter product description"
                        className="py-2 px-4 text-black focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-500 bg-gray-200 dark:bg-gray-200 rounded-md"
                    />
                  </div>
                  <div className="mb-4">
                    <label
                        htmlFor="price"
                        className="block text-sm font-medium text-gray-700"
                    >
                      Price
                    </label>
                    <input
                        type="text"
                        name="price"
                        id="price"
                        placeholder="Enter product price"
                        className="py-2 px-4 text-black focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-500 rounded-md bg-gray-200 dark:bg-gray-200"
                    />
                  </div>
                  <div className="mb-4">
                    <label
                        htmlFor="size"
                        className="block text-sm font-medium text-gray-700"
                    >
                      Size
                    </label>
                    <input
                        type="text"
                        name="size"
                        id="size"
                        placeholder="Enter product size"
                        className="py-2 px-4 text-black focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-500 rounded-md bg-gray-200 dark:bg-gray-200"
                    />
                  </div>
                  <div className="mb-4">
                    <label htmlFor="category" className="block text-sm font-medium text-gray-700">Category</label>
                    <select name="category" id="category" value={selectedCategory} onChange={handleCategoryChange}
                            className="py-2 px-4 text-black focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-500 rounded-md bg-gray-200 dark:bg-gray-200">
                      <option value="" disabled>Select category</option>
                      {categories.map(category => (
                          <option key={category._id} value={category.name}>{category.name}</option>
                      ))}
                    </select>
                  </div>
                  <div className="mb-4">
                    <label
                        htmlFor="file"
                        className="block text-sm font-medium text-gray-700"
                    >
                      Upload File
                    </label>
                    <input
                        type="file"
                        id="file"
                        name="file"
                        accept=".jpg, .jpeg, .png"
                        className="py-2 px-4 text-black focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-500 rounded-md bg-gray-200 dark:bg-gray-200"
                    />
                  </div>
                  <div className="mb-4">
                    <label
                        htmlFor="image"
                        className="block text-sm font-medium text-gray-700"
                    >
                      Image URL
                    </label>
                    <input
                        type="text"
                        id="image"
                        name="image"
                        className="py-2 px-4 text-black focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-500 rounded-md bg-gray-200 dark:bg-gray-200"
                    />
                  </div>

                  <div className="mt-4 flex justify-end">
                    <button
                        type="button"
                        onClick={() => setShowAddForm(false)}
                        className="mr-2 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-500 hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                    >
                      Cancel
                    </button>
                    <button
                        type="submit"
                        className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    >
                      Save
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
      )}

      {showEditForm && (
          <div className="fixed z-50 inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <div
                  className="fixed inset-0 transition-opacity"
                  aria-hidden="true"
              >
                <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
              </div>

              <span
                  className="hidden sm:inline-block sm:align-middle sm:h-screen"
                  aria-hidden="true"
              >
              &#8203;
            </span>

              <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-4xl sm:w-full">
              <div className="mt-8 flex text-3xl justify-center items-center ">Edit Product </div>
              <form onSubmit={handleUpdate} className="p-6">
                {/* Form fields */}
                <div className="mb-4">
                  <label
                    htmlFor="title"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Title Edit
                  </label>
                  <input
                    type="text"
                    name="title"
                    id="title"
                    defaultValue={
                      product.find(
                        (product) => product._id === selectedProductId
                      ).title
                    }
                    placeholder="Enter product title"
                    className="py-2 px-4 text-black focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-500 rounded-md bg-gray-200 dark:bg-gray-200"
                  />
                </div>
                <div className="mb-4">
                  <label
                    htmlFor="desc"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Description
                  </label>
                  <input
                    type="text"
                    name="desc"
                    id="desc"
                    defaultValue={
                      product.find(
                        (product) => product._id === selectedProductId
                      ).desc
                    }
                    placeholder="Enter product description"
                    className="py-2 px-4 text-black focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-500 bg-gray-200 dark:bg-gray-200 rounded-md"
                  />
                </div>
                <div className="mb-4">
                  <label
                    htmlFor="price"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Price
                  </label>
                  <input
                    type="text"
                    name="price"
                    id="price"
                    defaultValue={
                      product.find(
                        (product) => product._id === selectedProductId
                      ).price
                    }
                    placeholder="Enter product price"
                    className="py-2 px-4 text-black focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-500 rounded-md bg-gray-200 dark:bg-gray-200"
                  />
                </div>
                <div className="mb-4">
                  <label
                    htmlFor="size"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Size
                  </label>
                  <input
                    type="text"
                    name="size"
                    id="size"
                    defaultValue={
                      product.find(
                        (product) => product._id === selectedProductId
                      ).size
                    }
                    placeholder="Enter product size"
                    className="py-2 px-4 text-black focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-500 rounded-md bg-gray-200 dark:bg-gray-200"
                  />
                </div>
                <div className="mb-4">
                  <label
                    htmlFor="categories"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Category
                  </label>
                  <input
                    type="text"
                    name="categories"
                    id="categories"
                    defaultValue={
                      product.find(
                        (product) => product._id === selectedProductId
                      ).categories
                    }
                    placeholder="Enter product category"
                    className="py-2 px-4 text-black focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-500 rounded-md bg-gray-200 dark:bg-gray-200"
                  />
                </div>
                {/* <div className="mb-4">
                  <label
                    htmlFor="file"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Upload File
                  </label>
                  <input
                    type="file"
                    id="file"
                    name="file"
                    accept=".jpg, .jpeg, .png"
                    defaultValue={product.find(product => product._id === selectedProductId).image}
                    className="py-2 px-4 text-black focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-500 rounded-md bg-gray-200 dark:bg-gray-200"
                  />
                </div> */}
                <div className="mb-4">
                  <label
                    htmlFor="image"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Image
                  </label>
                  <div className="flex-row items-center">
                    <img
                      src={
                        product.find(
                          (product) => product._id === selectedProductId
                        ).image
                      } // Set the src attribute to the image URL
                      alt="Product Image"
                      className="h-20 w-20 object-contain p-2" // Adjust the height and width as needed
                    />
                    <input
                      type="text"
                      id="image"
                      name="image"
                      defaultValue={
                        product.find(
                          (product) => product._id === selectedProductId
                        ).image
                      }
                      className="py-2 px-4 text-black focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-500 rounded-md bg-gray-200 dark:bg-gray-200"
                    />
                  </div>
                </div>

                <div className="mt-4 flex justify-end">
                  <button
                    type="button"
                    onClick={() => setShowEditForm(false)}
                    className="mr-2 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-500 hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                  >
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ManageProducts;
