import React from "react";
import { PiStudent } from "react-icons/pi";
import { PiChalkboardTeacher } from "react-icons/pi";
import PieCharts from "./PieCharts";
import { HiMiniUserGroup, HiBuildingStorefront } from "react-icons/hi2";
import { BiSolidCategoryAlt } from "react-icons/bi";

const AdminCard = ({
      total_users,
      total_categories,
      total_Products,
      total_verified,
      total_unverified,
}) => {
  return (
    <>
      <section className="flex w-auto flex-col ">
        <main className="flex gap-2 justify-center">
          <div className="grid grid-cols-1 md:grid-cols-4 gap-2 mt-3">

            <a href="/manage-users">
              <div
                  className="bg-white border border-1 md:w-64 w-80 hover:bg-purple-100 hover:border hover:border-purple-300  h-24 rounded-lg shadow-md flex items-center justify-center">
                <div className="flex px-2 flex-row w-full items-center justify-center gap-3">
                  <div className=" bg-pink-100 rounded-full p-2">
                    <HiMiniUserGroup className="text-5xl h-12 w-12 flex pr-0  p-1  md:text-6xl text-black"/>
                  </div>
                  <div className="flex w-52 flex-col">
                    <p className="text-lg md:text-lg text-black font-medium">
                      User
                    </p>
                    <p className="text-2xl text-pink-500 md:text-md font-medium">
                      {total_users}
                    </p>
                  </div>
                </div>
              </div>
            </a>


            <a href="/manage-products">
              <div
                  className="bg-white border border-1 md:w-64 w-80 hover:bg-purple-100 hover:border hover:border-purple-300  h-24 rounded-lg shadow-md flex items-center justify-center">
                <div className="flex px-2 flex-row w-full items-center justify-center gap-3">
                  <div className=" bg-yellow-100 rounded-full p-2">
                    <HiBuildingStorefront className="text-5xl h-12 w-12 flex pr-0  p-1  md:text-6xl text-black"/>
                  </div>
                  <div className="flex w-52 flex-col">
                    <p className="text-lg md:text-lg text-black font-medium">
                      Products
                    </p>
                    <p className="text-2xl md:text-md text-yellow-500 font-medium">
                      {total_Products}
                    </p>
                  </div>
                </div>
              </div>
            </a>

            <a href="/manage-category">
              <div
                  className="bg-white border border-1 md:w-64 w-80 hover:bg-purple-100 hover:border hover:border-purple-300 h-24 rounded-lg shadow-md flex items-center justify-center">
                <div className="flex px-2 flex-row w-full items-center justify-center gap-3">
                  <div className=" bg-green-100 rounded-full p-2">
                    <BiSolidCategoryAlt className="text-5xl h-12 w-12 flex pr-0  p-1  md:text-6xl text-black"/>
                  </div>
                  <div className="flex w-52 flex-col">
                    <p className="text-lg md:text-lg text-black font-medium">
                      Categories
                    </p>
                    <p className="text-2xl md:text-md text-green-500 font-medium">
                      {total_categories}
                    </p>
                  </div>
                </div>
              </div>
            </a>

            <a href="/manage-users">
              <div
                  className="bg-white border border-1 md:w-64 w-80 hover:bg-purple-100 hover:border hover:border-purple-300  h-24 rounded-lg shadow-md flex items-center justify-center">
                <div className="flex px-2 flex-row w-full items-center justify-center gap-3">
                  <div className=" bg-pink-100 rounded-full p-2">
                    <HiMiniUserGroup className="text-5xl h-12 w-12 flex pr-0  p-1  md:text-6xl text-black"/>
                  </div>
                  <div className="flex w-52 flex-col">
                    <p className="text-lg md:text-lg text-black font-medium">
                      Total Verified
                    </p>
                    <p className="text-2xl text-pink-500 md:text-md font-medium">
                      {total_verified}
                    </p>
                  </div>
                </div>
              </div>
            </a>

            <a href="/manage-users">
              <div
                  className="bg-white border border-1 md:w-64 w-80 hover:bg-purple-100 hover:border hover:border-purple-300  h-24 rounded-lg shadow-md flex items-center justify-center">
                <div className="flex px-2 flex-row w-full items-center justify-center gap-3">
                  <div className=" bg-pink-100 rounded-full p-2">
                    <HiMiniUserGroup className="text-5xl h-12 w-12 flex pr-0  p-1  md:text-6xl text-black"/>
                  </div>
                  <div className="flex w-52 flex-col">
                    <p className="text-lg md:text-lg text-black font-medium">
                      Total Unverified
                    </p>
                    <p className="text-2xl text-pink-500 md:text-md font-medium">
                      {total_unverified}
                    </p>
                  </div>
                </div>
              </div>
            </a>
          </div>
        </main>

        {/* <div className="bg-white relative border border-1 mt-5 md:w-96 w-120 h-60 px-5 md:px-0 rounded-lg shadow-md flex items-center justify-center">
            <div className=" mt-1 absolute top-1 text-sm bg-yellow-100 px-2 rounded-xl text-eee-500 left-2">
              <p>Pie charts for user</p>
            </div>
            <div className="flex mt-5 px-4 flex-row w-full items-center justify-center">
              <PieCharts
                total_users={total_users}
                total_catagories={total_categories}
                total_products={total_Products}
                // total_verified={total_verified}
                // total_unverified={total_unverified}
              />
            </div>
          </div> */}
        {/* <div className="bg-white border border-1 relative mt-5 md:w-96 w-80 h-60 px-5 md:px-0 rounded-lg shadow-md flex items-center justify-center">
            <div className=" mt-1 absolute top-1 text-sm bg-cyan-100 px-2 rounded-xl text-eee-500 left-2">
              <p>Pie charts for user verified and not verified</p>
            </div>
            <div className="flex mt-5 px-2 flex-row w-full items-center justify-center">
              <UserActivityChart
                userActive={total_verified}
                userNonactive={total_unverified}
              />
            </div>
          </div> */}
      </section>
    </>
  );
};

export default AdminCard;