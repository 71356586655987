import Cookies from "js-cookie";
import { api_url, decodedToken, decodedUserID } from "../api/config";
import Swal from "sweetalert2";
import axios from "axios";
import { message } from "antd";

const login = async (data) => {
  const { email, password, setIsLoading, setErrors } = data;
  let response;
  const loginData = {
    email,
    password,
  };

  try {
    response = await fetch(`${api_url}/auth/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(loginData),
    });

    const result = await response.json();
    console.log(result);

    if (!response.ok) {
      setIsLoading(false);
      setErrors({
        message: result.error || "An error occurred. Please try again",
      });
        if (result.error === "User not found") {
        Swal.fire({
          icon: 'error',
          title: 'Account does not exist',
          text: result.error,
        });
        } Swal.fire({
          icon: 'error',
          title: 'Password or Email is Incorrect',
          text: result.error,
        });
    } else {
      setIsLoading(false);
      // encode the token
      const tokens = btoa(result.token);
      const userId = btoa(result.user._id);

      Cookies.set("token", tokens, { expires: 1});
      Cookies.set("userId", userId, { expires: 1 });
      Cookies.set("role", result.user.role, { expires: 1 });
      Cookies.set("username", result.user.username, { expires: 1 });
      Cookies.set("email", result.user.email, { expires: 1 });
      Cookies.set("profile", result.user.profile, { expires: 1 });

      
      // Show success message with SweetAlert
      message.success('Login successful.');

      // Redirect to Home page
      setTimeout(() => {
        window.location.href = '/';
      }, 1000);
    }
  } catch (error) {
    console.log(error);
    setIsLoading(false);
    // Show error message with SweetAlert
    Swal.fire({
      icon: 'error',
      title: 'Account verify failed',
      text: 'An error occurred. Please try again later.',
    });


    if (response.status === 500) {
      setErrors({ message: "Server error, try again later" });
    } else if (response.status === 401) {
      setErrors({ message: "Invalid credentials" });
    } else if (response.status === 404) {
      setErrors({ message: "User not found" });
    } else if (response.status === 400) {
      setErrors({ message: "Your email is not verified" });
    } else if (response.status === 403) {
      setErrors({ message: "Forbidden" });
    } else {
      setErrors({ message: "An error occurred, try again later" });
    }
    
  }
};

// regsiter
const register = async (data) => {
  const { username, email, password, setIsLoading, setErrors } = data;

  try {
    const response = await axios.post(`${api_url}/auth/register`, {
      username,
      email,
      password,
    });

    console.log('Registration successful:', response.data);
      Swal.fire({
        icon: 'success',
        title: 'Registration Successful',
        text: 'Please Verify Your Email Address.',
        timer: 1500,
        timerProgressBar: true,
        didClose: () => {
          Cookies.set('emailToVerify', email, { expires: 1 }); // Store email in a cookie with a 1-day expiry
          window.location.href = '/verify-email'; // Redirect to OTP verification page
        }
      });

    setIsLoading(false);
    // Send OTP to email
    const otpResponse = await axios.post(`${api_url}/auth/email-otp`, {
      email,
    });

    if (otpResponse.status === 200) {
      console.log('OTP sent successfully');
      return true; // Indicates successful registration and OTP sending
    } else {
      throw new Error('Failed to send OTP');
    }

  } catch (error) {
    console.log('Registration error:', error);
    setIsLoading(false);
    // Handle registration error
    if (error.response && error.response.status === 400 && error.response.data.message) {
      // Email already exists, show error message using SweetAlert
      Swal.fire({
        icon: 'error',
        title: 'Account creation failed. Account already exists.',
        text: error.response.data.message,
      });
    } else {
      // Other registration errors
      Swal.fire({
        icon: 'error',
        title: 'Account already exists.',
        text: 'Please try again with a different email address.',
      });
    }
    return false; // Indicates failed registration
  }
};


// logout
const logout = () => {
  Swal.fire({
    title: "Are you sure?",
    text: "You are about to logout",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, logout",
  }).then((result) => {
    if (result.isConfirmed) {
      fetch(`${api_url}/auth/logout/${decodedUserID}`, {
        method: "POST",
        headers: {
          "auth-token": decodedToken,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          Cookies.remove("token");
          Cookies.remove("userId");
          Cookies.remove("role");
          Cookies.remove("username");
          Cookies.remove("email");
          Cookies.remove("profile");
          localStorage.removeItem("cart");
          localStorage.removeItem("wishlist_items");
          localStorage.removeItem("addresses");
          window.location.reload();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  });
};

const hasRole = (targetRole) => {
  const role = Cookies.get("role");
  return role === targetRole;
};

const isAdmin = () => hasRole("admin");
const isUser = () => hasRole("user");
const isLogged = () => !!decodedToken;
const isNotLogged = () => !decodedToken;
const isSeller = () => hasRole("seller")

export { isAdmin, isSeller, isUser, isLogged, login, register, isNotLogged, logout };

