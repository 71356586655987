import "./App.css";
import Login from "./pages/auth/Login";	
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import {isSeller, isAdmin, isUser, isLogged, isNotLogged } from "./auth/AuthContext";
import Home from "./pages/Home";
import Register from "./pages/auth/Register";
import ResetPassword from "./pages/auth/resetPassword/ResetPassword";
import OtpVerify from "./pages/auth/resetPassword/OtpVerify";
import AboutUs from "./pages/aboutus";
import Products from "./pages/Products";
import Categories from "./pages/Categories";
import ProductId from "./pages/Product/ProductId";
import CategoryId from "./pages/category/CategoriesId";
import Cart from "./pages/Cart";
import Checkout from "./pages/Checkout";
import Account from "./pages/Account";
import EditProfile from "./pages/EditProfile";
import SetNewPassword from "./pages/auth/resetPassword/SetNewPassword";
import VerifyEmail from "./pages/auth/VerifyEmail";
import SideBar from "./pages/admin/components/SideBar";
import Dashboard from "./pages/admin/Dashboard";
import ManageUsers from "./pages/admin/ManageUsers";
import ManageProducts from "./pages/admin/ManageProducts";
import ManageCategories from "./pages/admin/ManageCategories";
import CreateProduct from "./pages/User/CreateProduct";
import ProductDetail from "./pages/admin/ProductDetail";
import Recognition from "./pages/imageRecognition/Recognition";
import SuccessPage from "./pages/Stripe/paymentSuccess";

function App() {
  return (
    <>
    <Router>
      <Routes>
        <Route
          path="/login"
          element={isNotLogged() ? <Login /> : <Navigate to="/" />}
        />
        <Route path="/register" element={<Register />}/>
        <Route path="/verify-email" element={<VerifyEmail/>}/>
        <Route path="/verify-reset-password" element={<ResetPassword />}/>
        <Route path="/verify-otp" element={<OtpVerify />} />
        <Route path="/set-new-password" element={<SetNewPassword />}/>
        <Route path="/" element={<Home />}/>
        <Route path="/products" element={<Products />}/>
        <Route path="/products/:id" element={<ProductId />}/>
        <Route path="/categories" element={<Categories />}/>
        <Route path="/categories/:id" element={<CategoryId />}/>
        <Route path="/cart" element={<Cart />}/>
        <Route path="/checkout" element={<Checkout />}/>
        <Route path="/aboutus" element={<AboutUs />}/>
        <Route path="/account" element={<Account />}/>
        <Route path="/edit-profile" element={<EditProfile />}/>
        <Route path="/create-product" element={<CreateProduct />}/>
        <Route path="/product-approve/:id" element={<ProductDetail />}/>
        <Route path="/image-recognition" element={<Recognition />}/>
          <Route path="/success" element={<SuccessPage />}/>
        <Route element={<SideBar />}>
          <Route
              path="/dashboard"
              element={
                isLogged() && (isUser() || isSeller()) ? (
                    <Dashboard />
                ) : (
                    <Navigate to="/login" />
                )
              }
          />

          {/* Admin Router */}
          <Route
              path="/admin-dash"
              element={
                isLogged() && isAdmin()  || isUser() || isSeller()? (
                    <Dashboard />
                ) : (
                    <Navigate to="/login" />
                )
              }
          />
          <Route
              path="/manage-users"
              element={
                isLogged() && isAdmin() ? (
                    <ManageUsers />
                ) : (
                    <Navigate to="/login" />
                )
              }
          />
          <Route
              path="/manage-products"
              element={
                isLogged() && isAdmin() || isSeller() ? (
                    <ManageProducts/>
                ) : (
                    <Navigate to="/login" />
                )
              }
          />
          <Route
              path="/manage-category"
              element={
                isLogged() && isAdmin() || isSeller()? (
                    <ManageCategories/>
                ) : (
                    <Navigate to="/login" />
                )
              }
          />
        </Route>
      </Routes>
    </Router>
    </>
  );
}

export default App;
