import React, { useState, useRef, useEffect } from "react";
import AuthCode from 'react-auth-code-input';
import MoonLoader from "react-spinners/MoonLoader";
import piclab from "../../assets/piclab.png";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Cookies from "js-cookie";
import { api_url } from "../../api/config";

const OtpVerify = () => {
    const navigate = useNavigate();

    const AuthInputRef = useRef(null);
    const [otp, setOTP] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState('');
    const [resendCooldown, setResendCooldown] = useState(0); // Countdown timer in seconds
    const [resendDisabled, setResendDisabled] = useState(false);

    const email = Cookies.get('emailToVerify');

    const handleOnChange = (value) => {
        setOTP(value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (resendCooldown === 0) {
            setIsLoading(true);
            setErrors('');

            try {
                const response = await axios.post(`${api_url}/auth/verify-otp`, {
                    email,
                    otp,
                });
                Cookies.remove('emailToVerify'); // Remove email cookie after successful verification
                if (response.data) {
                    setIsLoading(false);
                    // Show success message using SweetAlert
                    Swal.fire({
                        icon: 'success',
                        title: 'Success!',
                        text: 'Email Verified Successfully.',
                        timer: 1500,
                        timerProgressBar: true,
                        didClose: () => {
                            // Redirect to login page after successful email verification
                            navigate('/login');
                        }
                    });
                } else {
                    setIsLoading(false);
                    setErrors('Invalid OTP. Please try again.');
                }
            } catch (error) {
                setIsLoading(false);
                if (error.response && error.response.status === 400) {
                    setErrors('Invalid OTP. Please try again.');
                } else {
                    setErrors('An error occurred. Please try again later.');
                }
            }
        }
    };

    const handleSendMail = async () => {
        setIsLoading(false);
        setResendDisabled(true); // Disable the Resend button during cooldown
        try {
            const otpResponse = await axios.post(`${api_url}/auth/email-otp`, {
                email,
            });
    
            if (otpResponse.status === 200) {
                setIsLoading(false);
                Swal.fire({
                    icon: 'success',
                    title: 'OTP Resent!',
                    text: 'A new OTP has been sent to your email.',
                });

                // Start a 2-minute countdown timer
                setResendCooldown(120); // 2 minutes in seconds
                const timerInterval = setInterval(() => {
                    setResendCooldown((prevCount) => prevCount - 1);
                }, 1000);

                // Clear the countdown timer after 2 minutes
                setTimeout(() => {
                    clearInterval(timerInterval);
                    setResendCooldown(0);
                    setResendDisabled(false); // Re-enable the Resend button
                }, 120000); // 2 minutes in milliseconds
            } else {
                setIsLoading(false);
                throw new Error('Failed to resend OTP');
            }
        } catch (error) {
            console.log('OTP resending error:', error);
            setIsLoading(false);
            setResendDisabled(false); // Re-enable the Resend button on error
            Swal.fire({
                icon: 'error',
                title: 'OTP Resend Failed',
                text: 'An error occurred while resending the OTP. Please try again later.',
            });
        }
    };

    useEffect(() => {
        if (resendCooldown > 0) {
            const timerInterval = setInterval(() => {
                setResendCooldown((prevCount) => prevCount - 1);
            }, 1000);

            return () => clearInterval(timerInterval);
        }
    }, [resendCooldown]);

    return (
        <div className="min-h-screen bg-white flex flex-col justify-center sm:px-6 lg:px-8">
            <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md px-2">
                <div className="bg-white py-4 px-4 shadow border border-1 rounded-xl sm:px-10">
                    <div className="sm:mx-auto sm:w-full mb-2 sm:max-w-md flex justify-center">
                        <img src={piclab} alt="Logo" className="block h-16" />
                    </div>
                    <p className="flex text-gray-700 font-bold text-xl justify-center items-center mb-2">
                        OTP Verification
                    </p>
                    <form onSubmit={handleSubmit} className="relative">
                        <div className="mt-6">
                            <div className="flex justify-center">
                                <div>
                                    <div className="text-center text-black">
                                        <h2>Enter the code</h2>
                                    </div>
                                    <div>
                                        <AuthCode
                                            key='otp'
                                            allowedCharacters='numeric'
                                            ref={AuthInputRef}
                                            onChange={handleOnChange}
                                            containerClassName='flex justify-center'
                                            inputClassName='text-black w-12 h-12 px-0 text-2xl text-center mr-3 uppercase border border-gray-300 rounded-md bg-white focus:ring-blue-500 focus:border-blue-500 focus:ring-1 focus:outline-none'
                                        />
                                        <p className="text-center text-sm text-gray-700">
                                            A message with a verification code has been sent to your email.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-6">
                                <span className="block w-full rounded-md shadow-sm">
                                    <button
                                        type="submit"
                                        disabled={resendCooldown > 0} // Disable Verify button during cooldown
                                        className="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out"
                                    >
                                        {isLoading ? (
                                            <div className="flex flex-row justify-center items-center gap-1">
                                                <MoonLoader size={20} color="#fff" loading={true} />
                                                <span className="text-sm text-gray-100">Verifying...</span>
                                            </div>
                                        ) : (
                                            "Verify OTP"
                                        )}
                                    </button>
                                </span>
                                {errors && (
                                    <p className="mt-2 text-center text-sm leading-5 text-red-600">
                                        {errors}
                                    </p>
                                )}
                            </div>

                            <div className="flex flex-row items-center justify-center text-center text-sm font-medium space-x-1 text-gray-500">
                                <p>Didn't receive the code?</p>{" "}
                                {resendCooldown > 0 ? (
                                    <p className="flex flex-row items-center text-blue-600 cursor-pointer">
                                        Resend in {Math.floor(resendCooldown / 60)}:{(resendCooldown % 60).toString().padStart(2, '0')}
                                    </p>
                                ) : (
                                    <p
                                        onClick={handleSendMail}
                                        className={`flex flex-row items-center text-blue-600 cursor-pointer ${resendDisabled ? 'pointer-events-none opacity-50' : ''}`}
                                    >
                                        Resend
                                    </p>
                                )}
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default OtpVerify;
