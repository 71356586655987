import React from 'react';
import { Modal, Button, Image, Tag, Divider } from 'antd';
import { CheckCircleOutlined, ClockCircleOutlined } from '@ant-design/icons';

const ProductDetailModal = ({ visible, onClose, request, categories }) => {
  if (!request) return null;

  const statusIcon = request.status === 'pending' ? (
    <ClockCircleOutlined style={{ color: 'orange' }} />
  ) : (
    <CheckCircleOutlined style={{ color: 'green' }} />
  );

  const statusTag = request.status === 'pending' ? (
    <Tag icon={statusIcon} color="orange">Pending</Tag>
  ) : (
    <Tag icon={statusIcon} color="green">Approved</Tag>
  );

  if (request) {
    document.body.style.overflow = 'hidden';
  } else {
    document.body.style.overflow = 'auto';
  }

  const formattedUploadDate = new Date(request.createdAt).toLocaleString(undefined, {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit'
});


  const categoryNames = request.categories.map((categoryId) => categories[categoryId]).join(', ');

  return (
    <Modal
      title="Product Request Details"
      open={visible}
      onCancel={onClose}
      footer={[
        <Button key="close" onClick={onClose}>
          Close
        </Button>,
      ]}
    >
      <div style={{ textAlign: 'center', marginBottom: '20px' }}>
        <Image
          width={200}
          src={request.image} // Using the image from request data
          alt="Product Image"
        />
      </div>
      <Divider />
      <p><strong>Title:</strong> {request.title}</p>
      <p><strong>Description:</strong> {request.desc}</p>
      <p><strong>Price:</strong> ${request.price}</p>
      <p><strong>Size:</strong> {request.size}</p>
      <p><strong>Categories:</strong> {categoryNames}</p>
      <p><strong>Status:</strong> {statusTag}</p>
      <p><strong>Created At:</strong> {formattedUploadDate}</p>
    </Modal>
  );
};

export default ProductDetailModal;
