import { createContext, useEffect, useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { api_url, decodedUserID, decodedToken } from "../api/config";

export const CartContext = createContext({});

export function CartContextProvider({ children }) {
  const ls = typeof window !== "undefined" ? window.localStorage : null;

  const [cartProducts, setCartProducts] = useState(() => {
    // Initialize cartProducts with data from local storage if available
    if (ls && ls.getItem('cart')) {
      return JSON.parse(ls.getItem('cart'));
    }
    return [];
  });

  useEffect(() => {
    if (cartProducts.length >= 0) {
      ls?.setItem('cart', JSON.stringify(cartProducts));
    } 
  }, [cartProducts, ls]);

  useEffect(() => {
    if (ls && ls.getItem('cart')) {
      const storedCart = JSON.parse(ls.getItem('cart'));
      setCartProducts(storedCart);
    }
  }, [ls]);

  useEffect(() => {
    const fetchCart = async () => {
      try {
        const response = await axios.get(`${api_url}/carts/find/${decodedUserID}`, {
          headers: {
            Authorization: decodedToken,
          },
        });
        console.log("Cart response", response)
        if (response.data && response.data.products) {
          const formattedCart = response.data.products.map(item => ({
            productId: item.productId._id,
            quantity: item.quantity,
          }));
          setCartProducts(formattedCart);
        } else {
          console.log("No cart found for user:", decodedUserID);
          createCart([]);
        }
      } catch (error) {
        console.error("Error fetching cart:", error);
      }
    };

    if (decodedUserID && decodedToken) {
      fetchCart();
    }
  }, []);

  const addProduct = (productId) => {
    const existingProduct = cartProducts.find(item => item.productId === productId);
    if (!existingProduct) {
      const addCart = [...cartProducts, { productId, quantity: 1 }];
      setCartProducts(addCart);
      updateCart(addCart)
    } else {
      const updatedCart = cartProducts.map(item =>
        item.productId === productId ? { ...item, quantity: item.quantity + 1 } : item
      );
      setCartProducts(updatedCart);
      updateCart(updatedCart);
    }
  }

  const createCart = async (products) => {
    try {
      const response = await axios.post(`${api_url}/carts/add-to-cart`,
        {
          owner: decodedUserID,
          products,
        },
        {
          headers: {
            Authorization: decodedToken,
          },
        }
      );
      if (response.status !== 200) {
        console.error("Failed to create cart:", response.data.error);
      }
    } catch (error) {
      console.error("Error creating cart:", error);
    }
  };

  const updateCart = async (products) => {
    try {
      const response = await axios.put(`${api_url}/carts/update/${decodedUserID}`,
        {
          owner: decodedUserID,
          products,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: decodedToken,
          },
        }
      );
      if (response.status !== 200) {
        console.error("Failed to update cart:", response.data.error);
      }
    } catch (error) {
      console.error("Error updating cart:", error);
    }
  };

  const removeProduct = (productId) => {
    const existingProduct = cartProducts.find(item => item.productId === productId);
    let updatedCart;
    if (existingProduct) {
      if (existingProduct.quantity > 1) {
        // If the product quantity is more than 1, decrease the quantity by 1
        updatedCart = cartProducts.map(item =>
          item.productId === productId ? { ...item, quantity: item.quantity - 1 } : item
        );
        setCartProducts(updatedCart);
        updateCart(updatedCart); // Update cart on the server
      } else {
        // If the product quantity is 1, remove the product from the cart
        updatedCart = cartProducts.filter(item => item.productId !== productId);
        console.log("Updated cart", updatedCart);
        setCartProducts(updatedCart);
        updateCart(updatedCart); // Update cart on the server
      }
    } else {
      console.log("Product ID not found in cart:", productId);
    }
  }

  const clearCart = () => {
    setCartProducts([]);
  }

  return (
    <CartContext.Provider value={{ cartProducts, addProduct, removeProduct, clearCart }}>
      {children}
    </CartContext.Provider>
  );
}
