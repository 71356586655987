import React, { useEffect, useState, Suspense } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import styled, { keyframes } from "styled-components";
import { api_url, decodedToken } from "../../api/config";
import { Button, Row, Col, Modal, message, Tooltip, Card, Popover } from 'antd';
import { FacebookOutlined, TwitterOutlined, LinkedinOutlined, WhatsAppOutlined, CopyOutlined, DownloadOutlined, SendOutlined, ShareAltOutlined } from '@ant-design/icons';
import QRCode from 'qrcode.react';
import Center from "../../components/Center";
import Header from "../../components/Header";
import WhiteBox from "../../components/WhiteBox";
import ProductImages from "../../components/ProductImages";
import FlyingButton from "../../components/FlyingButton";
import ProductReviews from "../../components/ProductReviews";
import Spinner from "../../components/Spinner";
import WishlistButton from "../../components/buttons/WishlistButton";
import WavingHand from "../../components/loader/WavingHand";
import { CheckCircleIcon, CalendarIcon } from "@heroicons/react/24/solid";
import { FaStar } from 'react-icons/fa';
import { set } from "date-fns";

const dropAnimation = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const StyledRevealWrapper = styled.div`
  opacity: 0;
  animation: ${dropAnimation} 0.5s ease forwards;
  animation-delay: ${({ delay = "0s" }) => delay};
`;

const ColWrapper = styled.div`
  display: grid;
  grid-template-columns: 1.2fr;
  @media screen and (min-width: 768px) {
    grid-template-columns: 1.2fr 1fr;
  }
  gap: 40px;
  margin: 40px 0px;
`;

const PriceRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Price = styled.span`
  font-family: "Poppins", sans-serif;
  font-size: 1.7rem;
  color: black;
`;

const ShareIconButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
  background-color: #1890ff;
  border: none;
  &:hover {
    background-color: #40a9ff;
  }
`;

const IconButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 50px;
  margin: 5px;
  border-radius: 50%;
  border: none;
  &:hover {
    opacity: 0.8;
  }
`;

const DownloadButton = styled(Button)`
  background-color: #ff4d4f;
  border: none;
  &:hover {
    background-color: #ff7875;
  }
`;

const CopyButton = styled(Button)`
  background-color: #52c41a;
  border: none;
  &:hover {
    background-color: #73d13d;
  }
`;

const ModalContent = styled.div`
  text-align: center;
`;

const QRWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
`;

const SocialMediaWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 20px;
`;

const SocialMediaButton = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const SocialMediaIcon = styled.div`
  font-size: 24px;
  margin-bottom: 4px;
`;

const GuideText = styled.p`
  font-size: 1rem;
  color: #555;
  margin-bottom: 20px;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  max-width: 100%;
`;

const TruncatedTitle = styled.h1`
  max-width: calc(100% - 50px);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline-block;
`;

const ProductPage = () => {
  const { id } = useParams();
  const [category, setCategory] = useState([]);
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [totalRating, setTotalRating] = useState(0);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [popoverVisible, setPopoverVisible] = useState(false);

  const getProductOwner = async (ownerId) => {
    try {
      const response = await axios.get(`${api_url}/users/find/${ownerId}`, {
        headers: {
          Authorization: decodedToken,
        },
      });
      return response.data.username;
    } catch (err) {
      console.error("Error fetching owner:", err);
      return "Unknown Owner";
    }
  };
  
  useEffect(() => {
    if (isModalVisible) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isModalVisible]);

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await axios.get(`${api_url}/products/find/${id}`);
        const productData = response.data;

        const categoryPromises = productData.categories.map(async (categoryId) => {
          const categoryResponse = await axios.get(`${api_url}/categories/${categoryId}`);
          return categoryResponse.data;
        });

        const categories = await Promise.all(categoryPromises);

        const ownerUsername = await getProductOwner(productData.owner);
        setProduct({ ...productData, ownerUsername });
        setCategory(categories);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    if (id) {
      fetchProduct();
    }
  }, [id]);

  useEffect(() => {
    document.title = "Products | PICLAB";
    const wavingHandTimeout = setTimeout(() => {
      setLoading(false);
    }, 1000);
    return () => clearTimeout(wavingHandTimeout);
  }, []);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleCopyLink = () => {
    navigator.clipboard.writeText(window.location.href);
    message.success('Link copied to clipboard');
  };

  const handleDownloadQR = () => {
    const canvas = document.querySelector('canvas');
    const pngUrl = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream');
    const downloadLink = document.createElement('a');
    downloadLink.href = pngUrl;
    downloadLink.download = 'product-qr-code.png';
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const handlePopoverVisibility = (visible) => {
    if (!visible) {
      setPopoverVisible(false);
    } else {
      setPopoverVisible(visible);
    }
  };

  if (loading) return <WavingHand />;

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!product) {
    return <div>Product not found.</div>;
  }

  const productUrl = window.location.href;
  const formattedUploadDate = new Date(product.createdAt).toLocaleDateString(undefined, {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });

  const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) {
      return text;
    }
    return text.substring(0, maxLength) + '...';
  };

  const truncatedTitle = truncateText(product.title, 15);

  return (
    <div className="flex flex-col min-h-screen bg-gray-100">
      <Header />
      <Center>
        <StyledRevealWrapper>
          <ColWrapper>
            <WhiteBox>
              <ProductImages image={product.image} />
            </WhiteBox>
            <div className="text-black">
              <h2 className="relative text-sm tracking-widest text-gray-500 title-font">
                Owned by: {product.ownerUsername}
                <div className="absolute right-0 bottom-4">
                  <WishlistButton product={product} />
                </div>
              </h2>
              <Popover 
                content={product.title} 
                placement="top"
                open={popoverVisible}
                onOpenChange={handlePopoverVisibility}
              >
                <TitleContainer>
                  <TruncatedTitle className="mb-1 text-3xl font-medium black title-font">
                    {truncatedTitle}
                  </TruncatedTitle>
                  <ShareIconButton shape="circle" icon={<ShareAltOutlined />} onClick={showModal} style={{ marginLeft: '8px' }} />
                </TitleContainer>
              </Popover>
              <div className="flex mb-4">
                <span className="flex items-center">
                  <span className="flex items-center text-xs mr-3 font-semibold px-2.5 py-1 rounded bg-cyan-900 bg-opacity-80 text-gray-100">
                    <FaStar className=" mr-1" /> {totalRating.toFixed(1)}
                  </span>
                  <span className="mx-1 text-gray-600">|</span>
                  <span className="text-gray-600">
                    {category.map(cat => cat.name).join(", ")}
                  </span>
                </span>
              </div>
              <p className="leading-relaxed">{product.desc}</p>
              {product.sell_status !== "false" && (
                <PriceRow>
                  <div>
                    <Price>${product.price}</Price>
                  </div>
                  <div className="">
                    <FlyingButton main _id={product._id} src={product.images} />
                  </div>
                </PriceRow>
              )}
              <Modal title="Share Product" open={isModalVisible} onCancel={handleCancel} footer={null} centered>
                <ModalContent>
                  <Card style={{ marginBottom: 20 }}>
                    <GuideText>Scan the QR code to share this product:</GuideText>
                    <QRWrapper>
                      <QRCode value={productUrl} size={150} />
                    </QRWrapper>
                    <Row justify="center" gutter={16}>
                      <Col>
                        <Tooltip title="Download QR Code">
                          <DownloadButton type="primary" shape="circle" icon={<DownloadOutlined />} onClick={handleDownloadQR} />
                        </Tooltip>
                        <div>Download</div>
                      </Col>
                      <Col>
                        <Tooltip title="Copy Link">
                          <CopyButton type="primary" shape="circle" icon={<CopyOutlined />} onClick={handleCopyLink} />
                        </Tooltip>
                        <div>Copy Link</div>
                      </Col>
                    </Row>
                  </Card>
                  <Card>
                    <GuideText>Share this product on social media:</GuideText>
                    <SocialMediaWrapper>
                      <SocialMediaButton>
                        <Tooltip title="Share on Facebook">
                          <IconButton shape="circle" icon={<FacebookOutlined />} href={`https://www.facebook.com/sharer/sharer.php?u=${productUrl}`} target="_blank" />
                        </Tooltip>
                        <div>Facebook</div>
                      </SocialMediaButton>
                      <SocialMediaButton>
                        <Tooltip title="Share on Twitter">
                          <IconButton shape="circle" icon={<TwitterOutlined />} href={`https://twitter.com/intent/tweet?url=${productUrl}`} target="_blank" />
                        </Tooltip>
                        <div>Twitter</div>
                      </SocialMediaButton>
                      <SocialMediaButton>
                        <Tooltip title="Share on LinkedIn">
                          <IconButton shape="circle" icon={<LinkedinOutlined />} href={`https://www.linkedin.com/sharing/share-offsite/?url=${productUrl}`} target="_blank" />
                        </Tooltip>
                        <div>LinkedIn</div>
                      </SocialMediaButton>
                      <SocialMediaButton>
                        <Tooltip title="Share on Telegram">
                          <IconButton shape="circle" icon={<SendOutlined />} href={`https://t.me/share/url?url=${productUrl}`} target="_blank" />
                        </Tooltip>
                        <div>Telegram</div>
                      </SocialMediaButton>
                      <SocialMediaButton>
                        <Tooltip title="Share on WhatsApp">
                          <IconButton shape="circle" icon={<WhatsAppOutlined />} href={`https://api.whatsapp.com/send?text=${productUrl}`} target="_blank" />
                        </Tooltip>
                        <div>WhatsApp</div>
                      </SocialMediaButton>
                    </SocialMediaWrapper>
                  </Card>
                </ModalContent>
              </Modal>
            </div>
          </ColWrapper>
          <div className="flex flex-col mt-4 p-6 bg-gray-100 rounded-lg shadow">
            <div className="flex flex-col mb-4">
              <span className="text-sm font-medium text-gray-700 mb-1">Product Information:</span>
              <span className="text-sm text-gray-700">{product.info}</span>
            </div>
            <div className="flex justify-between items-center">
              <div className="flex flex-col items-center">
                <div className="flex items-center">
                  <CheckCircleIcon className="w-6 h-5 text-green-500 mr-2" />
                  <span className="text-sm font-medium text-gray-700">Approved Status:</span>
                </div>
                <span className="text-sm text-gray-700 ml-7">{product.status}</span>
              </div>
              <div className="flex flex-col items-center">
                <div className="flex items-center">
                  <CalendarIcon className="w-6 h-5 text-blue-500 mr-2" />
                  <span className="text-sm font-medium text-gray-700">Upload Date:</span>
                </div>
                <span className="text-sm text-gray-700 ml-7">{formattedUploadDate}</span>
              </div>
            </div>
          </div>
          <div className="flex flex-wrap mx-auto mt-10 border-t border-gray-700 lg:max-w-5xl">
            <h2 className="my-2 text-sm tracking-widest text-gray-500 title-font">
              Reviews Section
            </h2>
            <Suspense fallback={<Spinner />}>
              <ProductReviews product={product} setTotalRating={setTotalRating} />
            </Suspense>
          </div>
        </StyledRevealWrapper>
      </Center>
    </div>
  );
};

export default ProductPage;
