import React from "react";
import styled, { keyframes } from "styled-components";
import { ButtonStyle } from "./Button";
import { primary } from "../utils/colors";
import { CartContext } from "./CartContext";
import { useContext, useEffect, useRef, useState } from "react";
import Swal from 'sweetalert2'; // Import SweetAlert
import { isNotLogged } from "../auth/AuthContext";
import { notification } from "antd";
import { useNavigate } from "react-router-dom";
import { FiShoppingCart } from "react-icons/fi";


const FlyingButtonWrapper = styled.div`
  .flying-button {
    width: 100%;
    padding: 10px 20px;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    color: white;
    background-color: #047481;
    border-radius: 8px;
    transition: transform 0.3s ease, background-color 0.3s ease;
    border: none;
    cursor: pointer;
    outline: none;

    &:hover {
      transform: scale(1.05);
      background-color: #036e73;
    }

    &:active {
      background-color: #036e73;
      ring-color: #036e73;
    }
  }

  img {
    display: none;
    max-width: 100px;
    max-height: 100px;
    opacity: 1;
    position: fixed;
    z-index: 5;
    animation: fly 1s;
    border-radius: 10px;

    @keyframes fly {
      100% {
        top: 0;
        left: 65%;
        opacity: 0;
        display: none;
        max-width: 50px;
        max-height: 50px;
      }
    }
  }
`;

export default function FlyingButton(props) {
  const { addProduct, cartProducts } = useContext(CartContext);
  const imgRef = useRef(null);
  const [isNotLoggedIn, setIsNotLoggedIn] = useState(false);
  const navigate = useNavigate();
  const isInCart = cartProducts.some((item) => item.productId === props._id);

  useEffect(() => {
    if (isNotLoggedIn) {
      Swal.fire({
        title: "Please Login",
        text: "You need to login to add this product to cart.",
        icon: "info",
        showCancelButton: true,
        confirmButtonText: "Login",
        cancelButtonText: "Cancel",
        reverseButtons: true,
      }).then(result => {
        if (result.isConfirmed) {
          // Redirect to login page or trigger your login logic
          window.location.href = "/login";
        }
        // Reset isNotLoggedIn state after handling the alert
        setIsNotLoggedIn(false);
      });
    }
  }, [isNotLoggedIn]);

  const handleAddProduct = () => {
    if (isNotLogged()) {
      setIsNotLoggedIn(true);
    } else {
      if (isInCart) {
        // Navigate to cart if the product is already in the cart
        navigate("/cart");
    } else {
      if (addProduct) {
        addProduct(props._id);
        sendImageToCart({ clientX: window.innerWidth / 2, clientY: window.innerHeight / 2 });
        notification.success({
          message: "Product added to cart!",
          description: "Successfully added the product to your cart.",
          placement: "topRight",
        });
      }
    }
  }
};

  function sendImageToCart(ev) {
    if (imgRef.current) {
      imgRef.current.style.display = "inline-block";
      imgRef.current.style.left = ev.clientX - 50 + "px";
      imgRef.current.style.top = ev.clientY - 50 + "px";
      setTimeout(() => {
        if (imgRef.current) {
          imgRef.current.style.display = "none";
        }
      }, 1000);
    }
  }

  useEffect(() => {
    const interval = setInterval(() => {
      const reveal = imgRef.current?.closest("div[data-sr-id]");
      if (reveal?.style.opacity === "1") {
        reveal.style.transform = "none";
      }
    }, 100);

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <FlyingButtonWrapper
        white={props.white}
        main={props.main}
        onClick={handleAddProduct}
      >
        <img src={props.src} alt="" ref={imgRef} />
        <button className="w-full right-0 px-4 py-2 text-xs lg:text-sm font-medium text-center text-white bg-cyan-900 rounded-lg transition-transform transform hover:scale-105 hover:bg-cyan-950 active:ring-cyan-950">
          <FiShoppingCart className="inline mb-1 mr-1" /> {isInCart ? "GO TO CART" : "Add to Cart"}
        </button>
      </FlyingButtonWrapper>
    </>
  );
};