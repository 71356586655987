import React from "react";
import fast from "../assets/fast.png";
import reliability from "../assets/reliability.png";
import community from "../assets/community.png";
import Center from "./Center";

class CardInfo {
  constructor(imageSource, title, description1, description2) {
    this.imageSource = imageSource;
    this.title = title;
    this.description1 = description1;
    this.description2 = description2;
  }
}

const Card = ({ cardInfo }) => {
  return (
    <div className="my-4 w-full rounded-xl bg-white p-6 shadow-lg duration-300 hover:scale-105 hover:shadow-2xl">
      <img src={cardInfo.imageSource} alt={cardInfo.title} className="mx-auto w-20" />
      <h2 className="py-4 text-center text-2xl font-bold text-gray-700">{cardInfo.title}</h2>
      <div className="mt-4 text-center text-gray-600">
        <ul>
          <li>{cardInfo.description1}</li>
          <li>{cardInfo.description2}</li>
        </ul>
      </div>
    </div>
  );
};

const Cards = () => {
  const cardDataArray = [
    new CardInfo(
      fast,
      "Fast",
      "Fast Service",
      "Responsive Website"
    ),
    new CardInfo(
      reliability,
      "Reliable",
      "Easy to Use",
      "Improves User Experience"
    ),
    new CardInfo(
      community,
      "Community",
      "Feedback and Support",
      "Improves User Experience"
    ),
  ];

  return (
    <div className="w-full bg-gradient-to-r from-blue-50 via-purple-50 to-pink-50 px-4 py-10">
      <h1 className="text-4xl font-bold text-center mb-10 text-gray-800">Features</h1>
      <Center>
        <div className="mx-auto grid max-w-screen-xl gap-8 md:grid-cols-3">
          {cardDataArray.map((cardData, index) => (
            <Card key={index} cardInfo={cardData} />
          ))}
        </div>
      </Center>
    </div>
  );
};

export default Cards;
