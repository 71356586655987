import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { api_url, decodedToken, decodedUserID } from '../../api/config';
import { ProductContext } from '../../components/contexts/ProductContext';
import { Upload, message, Modal, Radio, Steps, Progress } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import Turnstile from 'react-turnstile'; // Import Turnstile component

const { Step } = Steps;

const CreateProduct = () => {
  const { setShowCreateProduct, showCreateProduct } = useContext(ProductContext);
  const [step, setStep] = useState(0); // To manage the steps of the form
  const [productData, setProductData] = useState({
    title: '',
    desc: '',
    price: '',
    categories: '',
    sell_status: 'true',
  });
  const [categories, setCategories] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [loading, setLoading] = useState(false); // State to manage loading status
  const [uploadProgress, setUploadProgress] = useState(0); // State to manage upload progress
  const [turnstileToken, setTurnstileToken] = useState('');
  const [errors, setErrors] = useState({}); // State for errors

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(`${api_url}/categories/`);
        setCategories(response.data);
      } catch (error) {
        console.error('Failed to fetch categories', error);
      }
    };

    fetchCategories();

    document.body.style.overflow = showCreateProduct ? 'hidden' : 'auto';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [showCreateProduct]);

  const handleChange = (e) => {
    const { id, value } = e.target;
    setProductData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  const handleRadioChange = (e) => {
    const { value } = e.target;
    setProductData((prevData) => ({
      ...prevData,
      sell_status: value,
    }));
  };

  const handleUploadChange = ({ fileList }) => {
    setFileList(fileList.length > 1 ? [fileList[0]] : fileList);
  };

  const handleAdd = async (e) => {
    e.preventDefault();
    setLoading(true); // Start loading
    setUploadProgress(0); // Reset overall progress

    // Gradually increase the progress to 90% over 1 second
    const interval = setInterval(() => {
      setUploadProgress(prevProgress => {
        if (prevProgress < 90) {
          return prevProgress + 10;
        } else {
          clearInterval(interval);
          return prevProgress;
        }
      });
    }, 150);

    try {
      if (!turnstileToken) {
        setErrors({ turnstile: 'Please complete the Turnstile verification' });
        setLoading(false);
        return;
      }

      const turnstileResponse = await axios.post(`${api_url}/turnstile/verify-turnstile`, { 
        token: turnstileToken 
      });
      if (turnstileResponse.data.success === false) {
        setErrors({ turnstile: 'Verification failed' });
        setLoading(false);
        return;
      }

      const formData = new FormData();
      for (const key in productData) {
        formData.append(key, productData[key]);
      }
      if (fileList.length > 0) {
        formData.append('image', fileList[0].originFileObj);
      }

      formData.append('productOwner', decodedUserID);

      const response = await axios.post(`${api_url}/products/add-product`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: decodedToken,
        },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          if (percentCompleted >= 90) {
            clearInterval(interval);
            setUploadProgress(90); // Cap progress to 90% during upload
          }
        },
      });

      if (response.status === 200) {
        setUploadProgress(100); // Complete progress bar on success
        message.success('Product requested successfully!');
        setProductData({
          title: '',
          desc: '',
          price: '',
          categories: '',
          sell_status: 'true',
        });
        setFileList([]);
        setTimeout(() => {
          setShowCreateProduct(false);
          setUploadProgress(0);
        }, 500); // Delay to ensure progress bar shows 100%
        setStep(0); // Reset step to initial
        window.location.reload(); // Reload the page
      } else {
        message.error('Failed to create product');
      }
    } catch (error) {
      console.error('Failed to create product', error);
      message.error('Failed to create product');
    } finally {
      setUploadProgress(100); // Complete upload progress
      setLoading(false); // Stop loading
      window.location.reload(); // Reload the page
    }
  };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
  };

  const handleCancel = () => setPreviewVisible(false);

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const validateImage = (file) => {
    const isImage = file.type.startsWith('image/');
    const maxSizeInMB = 10; // Max size in MB
    const maxSizeInBytes = maxSizeInMB * 1024 * 1024;

    if (!isImage) {
      message.error('You can only upload image files!');
      return Promise.reject();
    }

    if (file.size > maxSizeInBytes) {
      message.error(`Image must be smaller than ${maxSizeInMB}MB!`);
      return Promise.reject();
    }

    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = URL.createObjectURL(file);
      img.onload = () => {
        const { width, height } = img;
        if (width < 800 || height < 800) {
          message.error('Image dimensions should be at least 800x800 pixels!');
          reject();
        } else {
          resolve();
        }
      };
      img.onerror = () => {
        message.error('Invalid image file!');
        reject();
      };
    });
  };

  const handleNext = () => {
    if (step === 0 && productData.sell_status === '') {
      message.error('Please select a sell status');
      return;
    }
    if (step === 1) {
      if (!productData.title || !productData.desc || (productData.sell_status === 'true' && !productData.price) || !productData.categories || fileList.length === 0) {
        message.error('Please fill in all required fields');
        return;
      }
    }
    setStep(step + 1);
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  return (
    <Modal
      open={showCreateProduct}
      title="Create Product"
      footer={null}
      onCancel={() => setShowCreateProduct(false)}
      width={600}
      centered
      style={{ padding: '20px' }}
    >
      <Steps current={step} className="mb-4">
        <Step title="Sell Status" />
        <Step title="Product Details" />
        <Step title="Review & Submit" />
      </Steps>
      {step === 0 ? (
        <div className="text-center">
          <h2 className="text-lg font-semibold mb-4">Do you want to sell your photo now or later?</h2>
          <Radio.Group
            value={productData.sell_status}
            onChange={handleRadioChange}
            className="mb-4"
          >
            <Radio value="true">Now</Radio>
            <Radio value="false">Later</Radio>
          </Radio.Group>
          <div className="mt-6 flex justify-center space-x-3">
            <button
              onClick={() => setShowCreateProduct(false)}
              type="button"
              className="px-4 py-2 text-sm font-medium text-gray-600 rounded-lg bg-gray-100 focus:ring-4 focus:outline-none hover:bg-gray-200 focus:ring-gray-200"
            >
              Cancel
            </button>
            <button
              onClick={handleNext}
              type="button"
              className="px-4 py-2 text-sm font-medium text-white rounded-lg bg-cyan-900 focus:ring-4 focus:outline-none hover:bg-cyan-950 focus:ring-cyan-950"
            >
              Next
            </button>
          </div>
        </div>
      ) : (
        <>
          {loading && (
            <div className="mt-4 flex justify-center">
              <Progress
                type="circle"
                percent={uploadProgress}
                size={80}
              />
            </div>
          )}
          {!loading && (
            <form onSubmit={handleAdd}>
              {step === 1 && (
                <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
                  <div className="sm:col-span-2 mb-4">
                    <label
                      htmlFor="image"
                      className="block text-sm font-semibold leading-6 text-gray-700 text-center"
                    >
                      Images
                    </label>
                    <div className="mt-2.5 flex justify-center">
                      <Upload
                        name="file"
                        listType="picture-card"
                        fileList={fileList}
                        onChange={handleUploadChange}
                        onPreview={handlePreview}
                        accept=".jpg, .jpeg, .png"
                        beforeUpload={(file) => validateImage(file)}
                      >
                        {fileList.length >= 1 ? null : uploadButton}
                      </Upload>
                      <Modal
                        open={previewVisible}
                        title={previewTitle}
                        footer={null}
                        onCancel={handleCancel}
                      >
                        <img alt="preview" style={{ width: '100%' }} src={previewImage} />
                      </Modal>
                    </div>
                  </div>
                  <div className="sm:col-span-2">
                    <label
                      htmlFor="title"
                      className="block text-sm font-semibold leading-6 text-gray-700"
                    >
                      Title
                    </label>
                    <div className="mt-2.5">
                      <input
                        placeholder="Enter product title"
                        value={productData.title}
                        type="text"
                        name="title"
                        id="title"
                        required
                        onChange={handleChange}
                        className="border sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 bg-gray-100 border-gray-300 placeholder-gray-500 text-gray-800 focus:ring-cyan-800 focus:border-cyan-800"
                      />
                    </div>
                  </div>
                  <div className="sm:col-span-2">
                    <label
                      htmlFor="desc"
                      className="block text-sm font-semibold leading-6 text-gray-700"
                    >
                      Description
                    </label>
                    <div className="mt-2.5">
                      <textarea
                        placeholder="Enter product description"
                        value={productData.desc}
                        name="desc"
                        id="desc"
                        required
                        onChange={handleChange}
                        className="border sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 bg-gray-100 border-gray-300 placeholder-gray-500 text-gray-800 focus:ring-cyan-800 focus:border-cyan-800"
                      ></textarea>
                    </div>
                  </div>
                  {productData.sell_status === 'true' && (
                    <div className="sm:col-span-1">
                      <label
                        htmlFor="price"
                        className="block text-sm font-semibold leading-6 text-gray-700"
                      >
                        Price
                      </label>
                      <div className="mt-2.5">
                        <input
                          placeholder="Enter product price"
                          value={productData.price}
                          type="number"
                          name="price"
                          id="price"
                          required
                          onChange={handleChange}
                          className="border sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 bg-gray-100 border-gray-300 placeholder-gray-500 text-gray-800 focus:ring-cyan-800 focus:border-cyan-800"
                        />
                      </div>
                    </div>
                  )}
                  <div className="sm:col-span-1">
                    <label
                      htmlFor="categories"
                      className="block text-sm font-semibold leading-6 text-gray-700"
                    >
                      Categories
                    </label>
                    <div className="mt-2.5">
                      <select
                        value={productData.categories}
                        id="categories"
                        required
                        onChange={handleChange}
                        className="border sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 bg-gray-100 border-gray-300 placeholder-gray-500 text-gray-800 focus:ring-cyan-800 focus:border-cyan-800"
                      >
                        <option value="">Select a category</option>
                        {categories.map((category) => (
                          <option key={category.id} value={category.id}>
                            {category.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="mt-2">
                    <Turnstile
                      sitekey={process.env.REACT_APP_TURNSTILE_SITE_KEY}
                      onVerify={setTurnstileToken}
                      theme="light"
                    />
                    {errors.turnstile && (
                      <p className="text-xs text-red-600 mt-1">{errors.turnstile}</p>
                    )}
                  </div>
                </div>
              )}
              {step === 2 && (
                <div className="mt-4">
                  <h3 className="text-lg font-semibold mb-4 text-center">Review Your Product Details</h3>
                  <p className="mb-2"><strong>Title:</strong> {productData.title}</p>
                  <p className="mb-2"><strong>Description:</strong> {productData.desc}</p>
                  <p className="mb-2"><strong>Category:</strong> {categories.find(cat => cat.id === productData.categories)?.name}</p>
                  {productData.sell_status === 'true' && (
                    <p className="mb-2"><strong>Price:</strong> {productData.price}</p>
                  )}
                  {fileList.length > 0 && (
                    <div className="mb-4">
                      <strong>Image:</strong>
                      <img src={previewImage || URL.createObjectURL(fileList[0].originFileObj)} alt="Product" className="mt-2 w-full h-auto"/>
                    </div>
                  )}
                </div>
              )}
              <div className="mt-6 flex justify-end space-x-3">
                {step > 0 && (
                  <button
                    onClick={() => setStep(step - 1)}
                    type="button"
                    className="px-4 py-2 text-sm font-medium text-gray-600 rounded-lg bg-gray-100 focus:ring-4 focus:outline-none hover:bg-gray-200 focus:ring-gray-200"
                  >
                    Back
                  </button>
                )}
                {step < 2 && (
                  <button
                    onClick={handleNext}
                    type="button"
                    className="px-4 py-2 text-sm font-medium text-white rounded-lg bg-cyan-900 focus:ring-4 focus:outline-none hover:bg-cyan-950 focus:ring-cyan-950"
                  >
                    Next
                  </button>
                )}
                {step === 2 && (
                  <button
                    type="submit"
                    className="px-4 py-2 text-sm font-medium text-white rounded-lg bg-cyan-900 focus:ring-4 focus:outline-none hover:bg-cyan-950 focus:ring-cyan-950"
                  >
                    Request Add Product
                  </button>
                )}
              </div>
            </form>
          )}
        </>
      )}
    </Modal>
  );
};

export default CreateProduct;
