import { useState } from "react";
import { Layout, Row, Col, Typography } from "antd";
import { MdOutlineImageSearch } from "react-icons/md";

import InputImage from "./InputImage";
import Output from "./Output";

const { Header, Content } = Layout;
const { Title } = Typography;

function Recognition() {
  const [outputs, setOutputs] = useState([]);
  const [imageToPredict, setImageToPredict] = useState("");

  return (
    <Layout>
      <Header style={{ display: 'flex', alignItems: 'center', backgroundColor: '#001529' }}>
        <MdOutlineImageSearch style={{ marginLeft: '12px', marginRight: '8px', color: 'white' }} />
        <Title level={3} style={{ color: 'white', margin: 0 }}>Image Recognition</Title>
      </Header>
      <Content style={{ padding: '24px' }}>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <InputImage setOutputs={setOutputs} setImageToPredict={setImageToPredict} />
          </Col>
          <Col span={24}>
            <Output outputs={outputs} imageToPredict={imageToPredict} />
          </Col>
        </Row>
      </Content>
    </Layout>
  );
}

export default Recognition;
