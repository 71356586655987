import React, { useContext, useEffect, useState } from "react";
import ReactSelect from 'react-select';
import { Modal, notification } from "antd";
import { AddressContext } from "../../components/contexts/AddressContext";
import Cookies from "js-cookie";
import axios from 'axios';
import { api_url } from "../../api/config";
import Turnstile from 'react-turnstile';

const COUNTRIES = [
  { value: 'Cambodia', label: 'Cambodia' },
  { value: 'United States', label: 'United States' },
  { value: 'Canada', label: 'Canada' },
  { value: 'United Kingdom', label: 'United Kingdom' },
  { value: 'Australia', label: 'Australia' },
  { value: 'Germany', label: 'Germany' },
  { value: 'France', label: 'France' },
  { value: 'India', label: 'India' },
  { value: 'China', label: 'China' },
  { value: 'Japan', label: 'Japan' },
  { value: 'Brazil', label: 'Brazil' },
  // Add more countries as needed
];

export default function AddressForm() {
  const { addresses, saveAddress, updateAddress, showAddForm, setShowAddForm, addressToEdit } = useContext(AddressContext);
  const username = Cookies.get("username");

  console.log("id", addressToEdit)

  const [addressForm, setAddressForm] = useState({
    name: username,
    country: COUNTRIES[0],
    address: "",
    city: "",
    postalCode: "",
  });
  const [turnstileToken, setTurnstileToken] = useState(null); // State for Turnstile token
  const [errors, setErrors] = useState({}); // State for errors

  useEffect(() => {
    document.title = "Address | PICLAB";
    if (addressToEdit) {
      setAddressForm({
        ...addressToEdit,
        country: COUNTRIES.find(c => c.value === addressToEdit.country)
      });
    }
    if (showAddForm) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    // Cleanup when the component unmounts
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [addressToEdit, showAddForm]);

  const handleFields = (e) => {
    setAddressForm({ ...addressForm, [e.target.name]: e.target.value });
  };

  const handleCountryChange = (selectedOption) => {
    setAddressForm({ ...addressForm, country: selectedOption });
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (!turnstileToken) {
      setErrors({ turnstile: 'Please complete the Turnstile verification' });
      return;
    }

    const turnstileResponse = await axios.post(`${api_url}/turnstile/verify-turnstile`, { 
      token: turnstileToken 
    });
    if (turnstileResponse.data.success === false) {
      setErrors({ turnstile: 'Verification failed' });
      return;
    }

    const addressData = {
      ...addressForm,
      country: addressForm.country.value,
      isPrimary: addresses.length === 0,
    };

    if (addressToEdit) {
      updateAddress(addressData);
      notification.success({ message: "Address Updated" });
    } else {
      saveAddress(addressData);
      notification.success({ message: "Address Added" });
    }
    setShowAddForm(false);
  };

  const back = () => {
    setShowAddForm(false);
  };

  return (
    <Modal
      open={showAddForm}
      title={addressToEdit ? "Edit Address" : "Add Address"}
      footer={null}
      onCancel={back}
      width={520}
      centered
      Style={{ padding: '20px' }}
    >
      <form onSubmit={handleFormSubmit}>
        <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-5">
          <div className="sm:col-span-6">
            <label
              htmlFor="name"
              className="block text-sm font-semibold leading-6 text-gray-700"
            >
              Name
            </label>
            <div className="mt-2.5">
              <input
                placeholder="joe"
                value={addressForm.name}
                type="text"
                name="name"
                id="name"
                required
                autoComplete="name"
                readOnly
                className="border sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 bg-gray-100 border-gray-300 placeholder-gray-500 text-gray-800 focus:ring-cyan-800 focus:border-cyan-800"
              />
            </div>
          </div>
          <div className="sm:col-span-6">
            <label
              htmlFor="country"
              className="text-gray-700 block text-sm font-semibold leading-6"
            >
              Country
            </label>
            <div className="mt-2.5">
              <ReactSelect
                options={COUNTRIES}
                value={addressForm.country}
                onChange={handleCountryChange}
                styles={{
                  control: (provided) => ({
                    ...provided,
                    backgroundColor: "#F3F4F6",
                    borderColor: "#D1D5DB",
                    borderRadius: "0.375rem",
                    boxShadow: "none",
                    "&:hover": {
                      borderColor: "#9CA3AF",
                    },
                  }),
                  option: (provided, state) => ({
                    ...provided,
                    backgroundColor: state.isSelected ? "#2563EB" : null,
                    color: state.isSelected ? "#FFFFFF" : "#374151",
                    "&:hover": {
                      backgroundColor: "#E5E7EB",
                      color: "#374151",
                    },
                  }),
                  singleValue: (provided) => ({
                    ...provided,
                    color: "#374151",
                  }),
                }}
              />
            </div>
          </div>
          <div className="sm:col-span-6">
            <label
              htmlFor="address"
              className="block text-sm font-semibold leading-6 text-gray-700"
            >
              Address
            </label>
            <div className="mt-2.5">
              <input
                placeholder="21 street"
                onChange={handleFields}
                type="text"
                name="address"
                value={addressForm.address}
                id="address"
                required
                autoComplete="address"
                className="border sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 bg-gray-100 border-gray-300 placeholder-gray-500 text-gray-800 focus:ring-cyan-800 focus:border-cyan-800"
              />
            </div>
          </div>
          <div className="sm:col-span-3">
            <label
              htmlFor="city"
              className="block text-sm font-semibold leading-6 text-gray-700"
            >
              City
            </label>
            <div className="mt-2.5">
              <input
                onChange={handleFields}
                type="text"
                placeholder="Mumbai"
                value={addressForm.city}
                name="city"
                id="city"
                required
                autoComplete="address-level2"
                className="border sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 bg-gray-100 border-gray-300 placeholder-gray-500 text-gray-800 focus:ring-cyan-800 focus:border-cyan-800"
              />
            </div>
          </div>
          <div className="sm:col-span-3">
            <label
              htmlFor="postalCode"
              className="block text-sm font-semibold leading-6 text-gray-700"
            >
              Postal Code
            </label>
            <div className="mt-2.5">
              <input
                onChange={handleFields}
                placeholder="400002"
                type="text"
                value={addressForm.postalCode}
                name="postalCode"
                id="postalCode"
                required
                pattern="\d{4,10}"
                autoComplete="postal-code"
                className="border sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 bg-gray-100 border-gray-300 placeholder-gray-500 text-gray-800 focus:ring-cyan-800 focus:border-cyan-800"
              />
            </div>
          </div>
          <div className="sm:col-span-6">
            <Turnstile
              sitekey={process.env.REACT_APP_TURNSTILE_SITE_KEY}
              onVerify={setTurnstileToken}
              theme="light"
            />
            {errors.turnstile && (
              <p className="text-xs text-red-600 mt-1">{errors.turnstile}</p>
            )}
          </div>
        </div>
        <div className="mt-6 flex justify-end space-x-3">
          <button
            onClick={back}
            type="button"
            className="px-4 py-2 text-sm font-medium text-gray-600 rounded-lg bg-gray-100 focus:ring-4 focus:outline-none hover:bg-gray-200 focus:ring-gray-200"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="px-4 py-2 text-sm font-medium text-white rounded-lg bg-cyan-900 focus:ring-4 focus:outline-none hover:bg-cyan-950 focus:ring-cyan-950"
          >
            {addressToEdit ? "Update Address" : "Save Address"}
          </button>
        </div>
      </form>
    </Modal>
  );
}
