import hands from '../../assets/hands.svg';

const WavingHand = () => {
    return (
        <div className="fixed inset-0 flex flex-col items-center justify-center bg-transparent">
            <div className="animate-waving-hand transform translate-y-0 duration-300">
                <img src={hands} alt="Welcome" className="w-24 h-24" />
            </div>
            <div className="mt-4 text-center">
                <p className="text-lg font-semibold text-gray-700">
                    Loading...
                </p>
                <p className="text-sm text-gray-500">
                    We're preparing your content.
                </p>
            </div>
        </div>
    );
};

export default WavingHand;
