import React, { useState } from 'react';
import { MoonLoader } from 'react-spinners';
import Swal from 'sweetalert2';
import styled, { keyframes } from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { register } from '../../auth/AuthContext';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import Lottie from 'lottie-react';
import registerAsset from '../../assets/registerAsset.json';
import Turnstile from 'react-turnstile';
import axios from 'axios';
import { api_url } from '../../api/config';


const dropAnimation = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const StyledRevealWrapper = styled.div`
  opacity: 0;
  animation: ${dropAnimation} 0.5s ease forwards;
  animation-delay: ${props => props.delay || "0s"};
`;

const Register = () => {
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [turnstileToken, setTurnstileToken] = useState('');

    const navigate = useNavigate();

    const resetForm = () => {
      setUsername('');
      setEmail('');
      setPassword('');
      setConfirmPassword('');
      setShowPassword(false);
      setErrors({});
    };

    const handleSubmit = async (e) => {
      e.preventDefault();
      setIsLoading(true);
      // Validate form inputs
      const errors = {};
      if (!username.trim()) {
        setIsLoading(false);
        errors.username = 'Username is required';
      }
      if (!email.trim()) {
        setIsLoading(false);
        errors.email = 'Email is required';
      }
      if (!password.trim()) {
        setIsLoading(false);
        errors.password = 'Password is required';
      } else if (password.length < 6) {
        setIsLoading(false);
        errors.password = 'Password must be at least 6 characters';
      }
      if (password !== confirmPassword) {
        setIsLoading(false);
        errors.confirmPassword = 'Passwords do not match';
      }

      if (!turnstileToken) {
        errors.turnstile = 'Verification is required';
      }
    
      if (Object.keys(errors).length > 0) {
        setErrors(errors);
        setIsLoading(false);
        return;
      }
    
      try {
        const turnstileResponse = await axios.post(`${api_url}/turnstile/verify-turnstile`, { 
          token: turnstileToken 
        });
  
        if (!turnstileResponse.data.success) {
          setErrors({ turnstile: 'Verification failed' });
          setIsLoading(false);
          return;
        }

        // Call register function from AuthContext
        const registrationResponse = await register({ username, email, password, setIsLoading, setErrors });
  
        if (registrationResponse === true) {
          // Registration successful
          resetForm();
        }
      } catch (registrationError) {
        console.error('Registration error:', registrationError);
        // Handle registration error (existing code)
      }
    };

    return (
      <div className="flex flex-col min-h-screen bg-gray-50">
        <Header />
        <div className="flex-grow flex justify-center items-center lg:pr-10">
          <div className="hidden lg:block w-1/2 lg:pl-40">
              <Lottie animationData={registerAsset} loop={true} />
          </div>
          <StyledRevealWrapper className="relative z-10 mt-10 mb-24 sm:mx-auto sm:w-full sm:max-w-md px-2">
            <div className="w-full max-w-sm shadow-2xl bg-white py-4 px-4 border border-1 rounded-xl sm:px-10">
              <p className="flex text-gray-700 font-bold text-xl justify-center items-center mb-4 mt-2">
                Register an account
              </p>
              <form onSubmit={handleSubmit} className="relative">
              <div className="mt-5">
                <label htmlFor="username" className="block text-sm font-medium leading-5 text-gray-700">
                  Username
                </label>
                <div className="mt-1 relative rounded-md shadow-sm">
                  <input
                    id="username"
                    name="username"
                    type="text"
                    required=""
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    placeholder="Enter your username"
                    className={`mt-1 appearance-none block w-full px-3 py-2 border text-gray-800 border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:border-blue-100 transition duration-150 ease-in-out sm:text-sm sm:leading-5 ${
                      errors.username ? "border-red-500" : ""
                    }`}
                  />
                  {errors.username && (
                    <p className="text-xs text-red-600 absolute top-full left-0">{errors.username}</p>
                  )}
                </div>
              </div>

              <div className="mt-5">
                  <label htmlFor="email" className="block text-sm font-medium leading-5 text-gray-700">
                    Email address
                  </label>
                  <div className="mt-1 relative rounded-md shadow-sm">
                    <input
                      id="email"
                      name="email"
                      type="email"
                      required=""
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="Enter your email"
                      className={`mt-1 appearance-none block w-full px-3 py-2 border text-gray-800 border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:border-blue-100 transition duration-150 ease-in-out sm:text-sm sm:leading-5 ${
                        errors.email ? "border-red-500" : ""
                      }`}
                    />
                    {errors.email && (
                      <p className="text-xs text-red-600 absolute top-full left-0">{errors.email}</p>
                    )}
                  </div>
                </div>

                <div className="mt-5">
                  <label htmlFor="password" className="block text-sm font-medium leading-5 text-gray-700">
                    Password
                  </label>
                  <div className="mt-1 relative rounded-md shadow-sm">
                    <input
                      id="password"
                      name="password"
                      type={showPassword ? "text" : "password"}
                      required=""
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      placeholder="Enter your password"
                      className={`mt-1 appearance-none block w-full px-3 py-2 border text-gray-800 border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:border-blue-100 transition duration-150 ease-in-out sm:text-sm sm:leading-5 ${
                        errors.password ? "border-red-500" : ""
                      }`}
                    />
                    {errors.password && (
                      <p className="text-xs text-red-600 absolute top-full left-0">{errors.password}</p>
                    )}
                    <button
                    onClick={(e) => {
                      e.preventDefault();
                      setShowPassword(!showPassword);
                    }}
                    className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5"
                  >
                    {showPassword ? <FaEye /> : <FaEyeSlash />}
                  </button>
                  </div>
                </div>

                <div className="mt-5">
                  <label htmlFor="confirmPassword" className="block text-sm font-medium leading-5 text-gray-700">
                    Confirm Password
                  </label>
                  <div className="mt-1 relative rounded-md shadow-sm">
                    <input
                      id="confirmPassword"
                      name="confirmPassword"
                      type={showPassword ? "text" : "password"}
                      required=""
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      placeholder="Confirm your password"
                      className={`mt-1 appearance-none block w-full px-3 py-2 border text-gray-800 border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:border-blue-100 transition duration-150 ease-in-out sm:text-sm sm:leading-5 ${
                        errors.confirmPassword ? "border-red-500" : ""
                      }`}
                    />
                    {errors.confirmPassword && (
                      <p className="text-xs text-red-600 absolute top-full left-0">{errors.confirmPassword}</p>
                    )}
                  </div>
                </div>

                <div className="mt-5">
                  <Turnstile
                    sitekey={process.env.REACT_APP_TURNSTILE_SITE_KEY}
                    onVerify={setTurnstileToken}
                    theme="light"
                  />
                  {errors.turnstile && (
                    <p className="text-xs text-red-600 mt-1">{errors.turnstile}</p>
                  )}
                </div>

              <div className="mt-7">
                <span className="block w-full rounded-md shadow-sm">
                  <button
                    type="submit"
                    className="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out"
                  >
                    {isLoading ? (
                      <div className="flex flex-row justify-center items-center gap-1">
                        <MoonLoader size={20} color="#fff" loading={true} />
                        <span className="text-eee-100 text-xs">Creating...</span>
                      </div>
                    ) : (
                      "Register"
                    )}
                  </button>
                </span>
                <p className="mt-2 text-center text-sm leading-5 text-gray-500 max-w">
                    Already have an account?&nbsp;
                    <a
                      href="/login"
                      className="font-medium text-blue-600 hover:text-blue-500 focus:outline-none focus:underline transition ease-in-out duration-150"
                    >
                      Login Now
                    </a>
                  </p>
              </div>
            </form>
            </div>
          </StyledRevealWrapper>
        </div>
        <Footer />
      </div>
    );
  };
  
  export default Register;