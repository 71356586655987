import React, { useState, useEffect } from "react";
import { MoonLoader } from "react-spinners";
import Cookies from "js-cookie";
import styled, { keyframes } from "styled-components";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { api_url, decodedToken, decodedUserID } from "../api/config";
import { message, Modal } from "antd";
import ImgCrop from "antd-img-crop";
import { Upload } from "antd";

const dropAnimation = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const StyledRevealWrapper = styled.div`
  opacity: 0;
  animation: ${dropAnimation} 0.5s ease forwards;
  animation-delay: ${(props) => props.delay || "0s"};
`;

const EditProfilePage = () => {
  const [username, setUsername] = useState("");
  const [profile, setProfile] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [fileList, setFileList] = useState([]);
  const [previewImage, setPreviewImage] = useState("");
  const [previewVisible, setPreviewVisible] = useState(false);

  useEffect(() => {
    const userName = Cookies.get("username") || "";
    const userProfile = Cookies.get("profile") || "";

    setProfile(userProfile);
    setUsername(userName);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      let imageUrl = profile;

      if (fileList.length > 0) {
        const formData = new FormData();
        formData.append("file", fileList[0].originFileObj);
        formData.append("upload_preset", "n3tn2lnv"); // Your Cloudinary upload preset

        const imageResponse = await axios.post(
          "https://api.cloudinary.com/v1_1/dgv67sixb/image/upload",
          formData
        );

        imageUrl = imageResponse.data.url;
      }

      const response = await axios.put(
        `${api_url}/users/${decodedUserID}`,
        { profile: imageUrl, username },
        {
          headers: {
            Authorization: decodedToken,
          },
        }
      );

      message.success("Profile updated successfully!");
      Cookies.set("username", username);
      Cookies.set("profile", response.data.profile);
      navigate("/account");
    } catch (error) {
      console.error("Failed to update profile:", error);
      message.error("An error occurred. Please try again.");
    }

    setIsLoading(false);
  };

  const handleCancel = () => {
    navigate("/account");
  };

  const handleProfileChange = ({ fileList }) => {
    setFileList(fileList);
  };

  const handlePreview = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file.originFileObj);
    reader.onload = () => {
      setPreviewImage(reader.result);
      setPreviewVisible(true);
    };
  };

  const handlePreviewCancel = () => setPreviewVisible(false);

  return (
    <StyledRevealWrapper>
      <div className="min-h-screen bg-gray-100 flex items-center justify-center">
        <div className="bg-white p-8 sm:w-full sm:max-w-md shadow-lg rounded-lg">
          <h2 className="text-2xl font-bold text-center text-black mb-4">Edit Profile</h2>
          <div className="flex justify-center">
            <ImgCrop showReset>
              <Upload
                listType="picture-card"
                fileList={fileList}
                onChange={handleProfileChange}
                className="center"
                maxCount={1}
                onPreview={handlePreview}
              >
                {fileList.length < 1 && <img src={profile} alt="profile" className="rounded-lg" />}
              </Upload>
            </ImgCrop>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="mb-4 mt-4">
              <label htmlFor="username" className="block text-sm font-medium text-black">
                Username
              </label>
              <input
                id="username"
                name="username"
                type="text"
                required
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                onBlur={(e) => setUsername(e.target.value.trim())}
                className="text-black mt-1 block w-full px-3 py-2 border rounded-md placeholder-gray-400 focus:outline-none focus:border-blue-500 transition duration-150"
              />
            </div>

            <div className="flex flex-col items-center">
              <button
                type="submit"
                className="py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue active:bg-blue-700 transition duration-150 w-full mb-4"
              >
                {isLoading ? (
                  <div className="flex items-center justify-center">
                    <MoonLoader size={20} color="#fff" loading={true} />
                    <span className="ml-2">Saving...</span>
                  </div>
                ) : (
                  "Save Changes"
                )}
              </button>
              <button
                type="button"
                onClick={handleCancel}
                className="py-2 px-4 border border-gray-300 text-sm font-medium rounded-md text-black bg-white focus:outline-none focus:border-blue-500 focus:shadow-outline-blue active:bg-red-500 transition duration-150 w-full hover:bg-red-500"
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
      <Modal visible={previewVisible} footer={null} onCancel={handlePreviewCancel}>
        <img alt="Preview" style={{ width: "100%" }} src={previewImage} />
      </Modal>
    </StyledRevealWrapper>
  );
};

export default EditProfilePage;
