import { useContext, useEffect, useState } from "react";
import CartCheckoutDetails from "../components/CartCheckoutDetails";
import { CartContext } from "../components/CartContext";
import Center from "../components/Center";
import Header from "../components/Header";
import Footer from "../components/Footer";
import styled, { keyframes } from "styled-components";
import Address from "./User/Address";
import axios from "axios";
import { api_url } from "../api/config";
import WavingHand from "../components/loader/WavingHand";
import AddressContext from "../components/contexts/AddressContext";
import AddressForm from "./User/AddressForm";

const dropAnimation = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const StyledRevealWrapper = styled.div`
  opacity: 0;
  animation: ${dropAnimation} 0.5s ease forwards;
  animation-delay: ${(props) => props.delay || "0s"};
`;

const ColumnsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  @media screen and (min-width: 768px) {
    grid-template-columns: 1.2fr 0.8fr;
  }
  gap: 10px;
  margin-top: 40px;
  margin-bottom: 40px;
`;

const Box = styled.div`
  color: black;
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
`;

const Checkout = () => {
    const { cartProducts } = useContext(CartContext);
    const { showAddForm, setShowAddForm } = useContext(AddressContext);
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);

    console.log("products", products);

    useEffect(() => {
      document.title = "Checkout | PicLab";
      const fetchProductsByIds = async () => {
        try {
          const response = await axios.get(`${api_url}/products/`);
          const filteredProducts = response.data.filter(product =>
            cartProducts.map(item => item.productId).includes(product._id)
          );
          setProducts(filteredProducts);
          setLoading(false);
        } catch (error) {
          console.error("Error fetching products:", error);
        }
      };
  
      if (cartProducts.length > 0) {
        fetchProductsByIds();
      } else {
        setProducts([]);
      }
    }, [cartProducts]);


    let productsTotal = 0;
  for (const cartItem of cartProducts) {
    const product = products.find(p => p._id === cartItem.productId);
    if (product) {
      productsTotal += product.price * cartItem.quantity;
    }
  }

  const formattedTotal = productsTotal.toFixed(2);
  productsTotal = parseFloat(formattedTotal);

  useEffect(() => {
    document.title = "Products | PICLAB";
    const WavingHand = setTimeout(() => {
      setLoading(false);
    }, 1000);
    return () => clearTimeout(WavingHand);
  }, []);

    if (loading) return <WavingHand />;

return (
  <>
  <div className="flex flex-col min-h-screen bg-gradient-to-r from-blue-50 via-purple-50 to-pink-50">
    <Header />
    <main className="flex-grow">
      <Center>
        <ColumnsWrapper>
          <StyledRevealWrapper delay={0}>
              <Box>
                <h2 className="text-center text-lg font-semibold mb-4">Your Address</h2>
                <Address />
              </Box>
          </StyledRevealWrapper>
          {!!cartProducts?.length && (
              <CartCheckoutDetails
                products={products}
                productsTotal={productsTotal}
              />
          )}
        </ColumnsWrapper>
      </Center>
    </main>
    <Footer />
  </div>

  {/* Address Form Modal */}
  {showAddForm && <AddressForm />}

  </>
);
};

export default Checkout;
