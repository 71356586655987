import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import { api_url } from "../../api/config";
import {Link, useParams, useNavigate} from 'react-router-dom';
import categoriesId from "../category/CategoriesId";
import {CheckCircleOutlined, ClockCircleOutlined} from "@ant-design/icons";
import {Tag} from "antd";

const ProductDetail = () => {
    const { id } = useParams(); // Get product ID from route params
    const [product, setProduct] = useState(null);
    const [loading, setLoading] = useState(true);
    const org_token = Cookies.get("token");
    const token = atob(org_token);
    const navigate = useNavigate();



    const getProductOwner = async (ownerId) => {
        try {
          const response = await axios.get(`${api_url}/users/find/${ownerId}`, {
            headers: {
              Authorization: token,
            },
          });
          return response.data.username;
        } catch (err) {
          console.error("Error fetching owner:", err);
          return "Unknown Owner";
        }
      };

    const fetchCategoryName = async (categoryId) => {
        try {
            const response = await axios.get(`${api_url}/categories/${categoryId}`, {
                headers: {
                    Authorization: token,
                },
            });
            return response.data.name; // Assuming the category name is in the 'name' field
        } catch (error) {
            console.error("Error fetching category:", error);
            return "Unknown Category"; // Default category name if fetch fails
        }
    };
    const fetchProduct = async () => {
        try {
            const response = await axios.get(`${api_url}/products/find/${id}`, {
                headers: {
                    Authorization: token,
                },
            });
            const product = response.data;

            // Fetch category names for the product
            const categoryNamesPromises = product.categories.map((categoryId) =>
                fetchCategoryName(categoryId)
            );
            const categoryNames = await Promise.all(categoryNamesPromises);
            const productWithCategories = { ...product, categoryNames };
            const ownerUsername = await getProductOwner(product.owner);

            setProduct({ ...productWithCategories, ownerUsername});
            setLoading(false);
        } catch (err) {
            console.error('Error fetching products:', err);
            setLoading(false);
        }
    };

    const renderStatusTag = (status) => {
        const statusIcon = status === 'pending' ? (
            <ClockCircleOutlined style={{ color: 'orange' }} />
        ) : (
            <CheckCircleOutlined style={{ color: 'green' }} />
        );

        return status === 'pending' ? (
            <Tag icon={statusIcon} color="orange">Pending</Tag>
        ) : (
            <Tag icon={statusIcon} color="green">Approved</Tag>
        );
    };


    useEffect(() => {

        fetchProduct();
    }, []);



    const handleApprove = async () => {
        try {
            setLoading(true);
            const response = await axios.put(
                `${api_url}/products/approve/${id}`,
                {},
                {
                    headers: {
                        Authorization: token,
                    },
                }
            );
            console.log('Product approved:', response.data);
            // Update product status
            // setProduct((prevProduct) => ({
            //     ...prevProduct,
            //     status: 'Approved',
            // }));
        } catch (error) {
            console.error('Error approving product:', error.response?.data || error.message);
        } finally {
            setLoading(false);
        }
    };
    

    const handleRedirect = () => {
        navigate(`/products/${id}`);
    };

    if (loading) return <div>Loading...</div>;

    if (!product) return <div>Product not found</div>;

    return (
        <div className="container mx-auto px-4 sm:px-8 py-8">
            <nav className="bg-gray-800 p-4 rounded-md mb-6">
                <Link to="/manage-products" className="text-white hover:text-gray-300">
                    &larr; Back to Manage Products
                </Link>
            </nav>
            <div className="bg-white p-6 rounded-lg shadow-lg">
                <h2 className="text-3xl text-black font-bold mb-6">{product.title}</h2>
                <img src={product.image} alt={product.title} className="w-64 h-64 object-cover mb-4 rounded text-black" />
                <p className="mb-4 text-black"><strong>Owner:</strong> {product.ownerUsername}</p>
                <p className="mb-4 text-black"><strong>Description:</strong> {product.desc}</p>
                <p className="mb-4 text-black"><strong>Categories:</strong> {product.categoryNames.join(', ')}</p>
                <p className="mb-4 text-black"><strong>Size:</strong> {product.size}</p>
                <p className="mb-4 text-black"><strong>Price:</strong> ${product.price}</p>
                <p className="mb-4 text-black"><strong>Created at:</strong> {new Date(product.createdAt).toLocaleDateString()}</p>
                <p className="mb-4 text-black"><strong>Status:</strong> {renderStatusTag(product.status)}</p>
                {product.status === 'pending' ? (
                    <button
                        onClick={handleApprove}
                        className="px-4 py-2 bg-green-500 text-white rounded-md shadow hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500"
                    >
                        Approve
                    </button>
                ) : (
                    <button
                        onClick={handleRedirect}
                        className="px-4 py-2 bg-blue-500 text-white rounded-md shadow hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
                    >
                        View Product
                    </button>
                )}
            </div>
        </div>
    );
};

export default ProductDetail;
