import React, { useEffect, useState } from "react";
import { api_url, decodedToken, decodedUserID } from "../../api/config";
import ReactPaginate from "react-paginate";
import { GrFormNext } from "react-icons/gr";
import { GrFormPrevious } from "react-icons/gr";
import { format, set } from "date-fns";
import Swal from "sweetalert2";
import axios from "axios";
import { LuLayoutDashboard } from "react-icons/lu";
import { Link } from "react-router-dom";
import { PiGraduationCap } from "react-icons/pi";
import CryptoJs from "crypto-js";
import ScaleLoader from "react-spinners/ScaleLoader";
import { HiDotsHorizontal } from "react-icons/hi";
import Cookies from "js-cookie";
import WavingHand from "../../components/loader/WavingHand";

const ManageCategories = () => {
  const [Categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [query, setQuery] = useState("");
  const itemsPerPage = 10;
  const [showAddForm, setShowAddForm] = useState(false);

  useEffect(() => {
    fetchCategories();
  }, []);

  //const token = process.env.REACT_APP_TOKEN || decodedToken();
  const org_token = Cookies.get("token");
  const token = atob(org_token);
  const fetchCategories = async () => {
    setLoading(true);
    try {
      const res = await axios.get(`${api_url}/categories/`, {
        method: "GET",
        headers: {
          Authorization: token,
        },
      });
      setCategories(res.data);
      setLoading(false);
    } catch (err) {
      console.error(err);
    }
  };

  const handleAdd = async (e) => {
    e.preventDefault();
    console.log(document.getElementById("name").value);
    console.log(document.getElementById("description").value);

    try {
      const formData = {
        name: document.getElementById("name").value,
        description: document.getElementById("description").value,
      };
      console.log(formData);

      const response = await fetch(`${api_url}/categories`, {
        method: "POST",
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
      console.log("Response:", response);

      if (!response.ok) {
        throw new Error("Failed to add product");
      }

      // Clear form fields on successful submission
      document.getElementById("name").value = "";
      document.getElementById("description").value = "";

      setShowAddForm(false);
      window.location.reload();
      // Handle success, e.g., show success message
    } catch (error) {
      console.error("Error adding product:", error.message);
      // Handle error, e.g., show error message to user
    }
  };

  const handleSearch = (e) => {
    e.preventDefault();
    if (query.trim() === "") {
      fetchCategories();
      return;
    }
    setLoading(true);
    fetch(`${api_url}/admin/search-class/${query}`, {
      method: "GET",
      headers: {
        "auth-token": decodedToken,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setCategories(data);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  };

  const currentCategories = Categories.slice(
    currentPage * itemsPerPage,
    (currentPage + 1) * itemsPerPage
  );

  function formatDate(dateString) {
    if (!dateString) {
      return "Invalid date";
    }

    const date = new Date(dateString);

    if (isNaN(date)) {
      return "Invalid date";
    }

    return format(date, "MMMM dd, yyyy");
  }

  const handleDeleteClass = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await axios.delete(`${api_url}/categories/${id}`, {
            method: "DELETE",
            headers: {
              "auth-token": token,
            },
          });
          Swal.fire("Deleted!", "Class has been deleted.", "success");
          setCategories(Categories.filter((cls) => cls._id !== id));
        } catch (err) {
          Swal.fire({
            title: "Error!",
            text: err.response?.data?.message || "Failed to delete the class.",
            icon: "error",
            confirmButtonColor: "#3085d6",
          });
        }
      }
    });
  };

  if (loading) return <WavingHand />;

  return (
    <>
      <main className=" bg-white px-3  mb-20">
        <div className="container mx-auto px-4 sm:px-8">
          <div className="py-2">
            <div className="my-2">
              <div className=" breadcrumbs">
                <ul>
                  <li className="text-lg">
                    <PiGraduationCap className="w-6 h-6 pr-2" />
                    Categories
                  </li>
                </ul>
              </div>
              <div className="bg-white border border-1 rounded-lg shadow-md p-2 md:p-4">
                <div className="flex md:flex-row flex-col items-center">
                  <h3 className="text-xl md:mb-0 mb-4 font-medium text-black ">
                    Categories Management
                  </h3>

                  <form
                    onSubmit={handleSearch}
                    class="flex items-center max-w-sm mx-auto"
                  >
                    <label for="simple-search" class="sr-only">
                      Search
                    </label>
                    <div class="relative w-full">
                      <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                        <svg
                          class="w-4 h-4 text-gray-500"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 18 20"
                        >
                          <path
                            stroke="currentColor"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M3 5v10M3 5a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm0 10a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm12 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm0 0V6a3 3 0 0 0-3-3H9m1.5-2-2 2 2 2"
                          />
                        </svg>
                      </div>
                      <input
                        type="text"
                        id="simple-search"
                        value={query}
                        onChange={(e) => setQuery(e.target.value)}
                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5"
                        placeholder="Search..."
                        required
                      />
                    </div>
                    <button
                      type="submit"
                      class="p-2.5 ms-2 text-sm font-medium text-white bg-blue-700 rounded-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300"
                    >
                      <svg
                        class="w-4 h-4"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 20 20"
                      >
                        <path
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                        />
                      </svg>
                      <span class="sr-only">Search</span>
                    </button>
                  </form>

                  <ReactPaginate
                    previousLabel={<GrFormPrevious />}
                    nextLabel={<GrFormNext />}
                    breakLabel={".."}
                    breakClassName={"break-me"}
                    // pageCount={Math.ceil(Categories.length / itemsPerPage)}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={3}
                    onPageChange={({ selected }) => setCurrentPage(selected)}
                    containerClassName={
                      "pagination flex justify-center mt-4 mb-4"
                    }
                    pageClassName={"mx-1"}
                    pageLinkClassName={
                      "px-2 py-1 rounded bg-white text-black hover:bg-blue-500 hover:text-white transition-colors duration-200"
                    }
                    activeLinkClassName={"bg-blue-500 text-white"}
                    previousLinkClassName={
                      "px-2  flex justify-center py-1 rounded text-black hover:bg-blue-500 hover:text-white transition-colors duration-200"
                    }
                    nextLinkClassName={
                      "px-2 py-1  flex justify-center rounded  text-black hover:bg-blue-500 hover:text-white transition-colors duration-200"
                    }
                    disabledClassName={"opacity-50 cursor-not-allowed"}
                    activeClassName={"activePage"}
                  />
                </div>

                <div className="mt-2">
                  <table className="w-full table-auto">
                    <thead>
                      <tr className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
                        <th className="py-3 px-3 text-left">Category Name</th>
                        <th className="py-3 px-3 text-left">Description</th>
                        <th className="py-3 px-3 text-center desktop-only">
                          Create at
                        </th>
                        <th className="py-3 px-3 text-center">Actions</th>
                        <th className="py-1 px-3 text-left">
                          <button
                            onClick={() => setShowAddForm(true)}
                            className="px-4 py-2 bg-blue-500 text-white rounded-md shadow hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
                          >
                            Add Category
                          </button>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="text-gray-600 text-sm font-light">
                      {loading ? (
                        <tr>
                          <td colSpan="5" className="text-center py-4">
                            <div className="container mx-auto px-4 sm:px-8">
                              <div
                                style={{
                                  height: "615px",
                                }}
                                className="flex justify-center items-center"
                              >
                                <ScaleLoader
                                  color="#c4c4c4"
                                  loading={loading}
                                />
                              </div>
                            </div>
                          </td>
                        </tr>
                      ) : (
                        currentCategories.map((data, index) => (
                          <tr
                            key={index}
                            className="border-b border-gray-200 hover:bg-gray-100"
                          >
                            <td className="py-1 px-3 text-left whitespace-nowrap">
                              <div className="flex items-center">
                                <span className="font-medium">{data.name}</span>
                              </div>
                            </td>
                            <td className="py-1 px-3 text-left whitespace-nowrap">
                              <div className="flex items-center">
                                <span className="font-medium">
                                  {data.description}
                                </span>
                              </div>
                            </td>
                            {/* <td className="py-1 px-3 text-left">
                              <div className="flex items-center font-medium">
                                <span>{data.ownerName}</span>
                              </div>
                            </td> */}
                            {/* <td className="py-1 px-3 text-center desktop-only">
                              <span className="bg-purple-200 text-purple-600 py-1 px-3 rounded-full text-xs font-medium">
                                {data.students.length}
                              </span>
                            </td> */}

                            <td className="py-1 px-3 text-center desktop-only">
                              <span className="text-xs font-medium">
                                {formatDate(data.createdAt)}
                              </span>
                            </td>

                            <td className="py-1 px-3 text-center items-center">
                              <div className="dropdown dropdown-end">
                                <ul className="flex justify-center">
                                  <div className="flex gap-2">
                                    <div>
                                      <button
                                        onClick={() =>
                                          handleDeleteClass(data._id)
                                        }
                                        className="bg-red-500 w-20 text-white py-1 px-3 rounded-md text-xs font-medium"
                                      >
                                        Delete
                                      </button>
                                    </div>
                                    {/* <div>
                                      <button
                                        onClick={() =>
                                          handleDeleteClass(data._id)
                                        }
                                        className="bg-blue-500 w-20 text-white py-1 px-3 rounded-md text-xs font-medium"
                                      >
                                        Add
                                      </button>
                                    </div> */}
                                  </div>
                                </ul>
                              </div>
                            </td>
                          </tr>
                        ))
                      )}
                      <br />
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>

      {showAddForm && (
        <div className="fixed z-50 inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div
              className="fixed inset-0 transition-opacity"
              aria-hidden="true"
            >
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>

            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              {/* Add product form */}
              <div className="mt-8 flex text-3xl justify-center items-center ">Add Category </div>
              <form onSubmit={handleAdd} className="p-6">
                {/* Form fields */}
                <div className="mb-4">
                  <label
                    htmlFor="title"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Name
                  </label>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    placeholder="Enter category name"
                    className="py-2 px-4 text-black focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-500 rounded-md bg-gray-200 dark:bg-gray-200"
                  />
                </div>
                <div className="mb-4">
                  <label
                    htmlFor="desc"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Description
                  </label>
                  <input
                    type="text"
                    name="description"
                    id="description"
                    placeholder="Enter product description"
                    className="py-2 px-4 text-black focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-500 bg-gray-200 dark:bg-gray-200 rounded-md"
                  />
                </div>

                <div className="mt-4 flex justify-end">
                  <button
                    type="button"
                    onClick={() => setShowAddForm(false)}
                    className="mr-2 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-500 hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                  >
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ManageCategories;
