import React, { useEffect, useState, useCallback } from "react";
import Header from "../../components/Header";
import Center from "../../components/Center";
import styled from "styled-components";
import Spinner from "../../components/Spinner";
import ProductsGrid from "../../components/ProductsGrid";
import { useParams } from "react-router-dom";
import axios from "axios";
import { api_url } from "../../api/config";

const CategoryHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  h1 {
    color: black;
    margin-bottom: 10px;
    margin-top: 10px;
    font-size: 1.5em;
  }
`;

const FiltersWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`;

const Filter = styled.div`
  background-color: #f4f4f4;
  padding: 8px;
  border-radius: 8px;
  display: flex;
  gap: 10px;
  color: #444;
  align-items: center;
  select {
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 6px 10px;
    border-radius: 5px;
    font-size: 14px;
    color: #444;
    cursor: pointer;
    &:focus {
      outline: none;
      border-color: #333;
    }
  }
`;

const debounce = (func, delay) => {
  let timerId;
  return function (...args) {
    if (timerId) {
      clearTimeout(timerId);
    }
    timerId = setTimeout(() => {
      func(...args);
    }, delay);
  };
};

const CategoryPage = () => {
  const { id } = useParams();
  const [category, setCategory] = useState([]);
  const [products, setProducts] = useState([]);
  const [sort, setSort] = useState("_id-desc");
  const [loadingProducts, setLoadingProducts] = useState(false);
  const [filtersChanged, setFiltersChanged] = useState(false);


  const fetchCategoryAndProducts = useCallback(async () => {
    try {
      setLoadingProducts(true);
      const [categoryResponse, productsResponse] = await Promise.all([
        axios.get(`${api_url}/categories/${id}`),
        axios.get(`${api_url}/products/?categories=${id}`,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        ),
      ]);
      const fetchedCategory = categoryResponse.data;
      const fetchedProducts = productsResponse.data;
      console.log("Fetched category:", fetchedCategory);
      console.log("Fetched products:", fetchedProducts);
      const categoryProducts = fetchedProducts.filter((product) =>
        product.categories.includes(id)
      );
      const sortedProducts = sortProducts(categoryProducts, sort);
      setCategory(fetchedCategory);
      setProducts(sortedProducts);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoadingProducts(false);
    }
  }, [id, sort]);

  useEffect(() => {
    fetchCategoryAndProducts();
  }, [fetchCategoryAndProducts]);

  const debouncedFetch = useCallback(
    debounce(() => {
      fetchCategoryAndProducts();
    }, 300),
    [fetchCategoryAndProducts]
  );

  useEffect(() => {
    if (!filtersChanged) {
      return;
    }
    setLoadingProducts(true);
    debouncedFetch();
    setFiltersChanged(false);
  }, [sort, debouncedFetch, filtersChanged]);

  const sortProducts = (products, sortCriteria) => {
    const [sortField, sortOrder] = sortCriteria.split("-");
    const sortedProducts = [...products];
    
    sortedProducts.sort((a, b) => {
      if (sortField === "price") {
        const priceA = a.price;
        const priceB = b.price;
        return sortOrder === "asc" ? priceA - priceB : priceB - priceA;
      } else if (sortField === "_id") {
        const dateA = new Date(a.createdAt);
        const dateB = new Date(b.createdAt);
        return sortOrder === "asc" ? dateA - dateB : dateB - dateA;
      }
      return 0;
    });
  
    return sortedProducts;
  };
  


  return (
    <div className="flex flex-col min-h-screen bg-gradient-to-r from-blue-50 via-purple-50 to-pink-50">
      <Header />
      <Center>
        <CategoryHeader>
          <h1>{category.name}</h1>
          <FiltersWrapper>
            <Filter>
              <span>Sort:</span>
              <select
                value={sort}
                onChange={(ev) => {
                  setSort(ev.target.value);
                  setFiltersChanged(true);
                }}
              >
                <option value="price-asc">price, lowest first</option>
                <option value="price-desc">price, highest first</option>
                <option value="_id-desc">newest first</option>
                <option value="_id-asc">oldest first</option>
              </select>
            </Filter>
          </FiltersWrapper>
        </CategoryHeader>
        {loadingProducts && <Spinner fullWidth />}
        {!loadingProducts && (
          <div>
            {products.length > 0 ? (
              <ProductsGrid products={products} />
            ) : (
              <div>Sorry, no products found</div>
            )}
          </div>
        )}
      </Center>
    </div>
  );
};

export default CategoryPage;