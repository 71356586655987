import React, { useContext } from "react";
import Center from "./Center";
import ButtonLink from "./ButtonLink";
import { CartContext } from "./CartContext";
import { Carousel } from "antd";
import FlyingButton from "./FlyingButton";
import styled, { keyframes } from "styled-components";
import  bgVideo from "../assets/bgVideo.mp4";

const dropAnimation = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const StyledRevealWrapper = styled.div`
  opacity: 0;
  animation: ${dropAnimation} 0.5s ease forwards;
  animation-delay: ${(props) => props.delay || "0s"};
`;

export default function Featured({ products }) {
  const { addProduct } = useContext(CartContext);

  function addFeaturedToCart(productId) {
    addProduct(productId);
  }

  return (
    <div className="bg-gray-900 text-white py-12">
      <video src={bgVideo} autoPlay loop muted className="w-full h-full object-cover absolute top-0 left-0 z-0" />
      <StyledRevealWrapper>
      <Center>
        <Carousel autoplay>
          {products.map((product, index) => (
            <div key={index} className="mb-12 flex flex-col gap-8 md:flex-row md:gap-16">
              <div className="flex flex-col items-center justify-center text-center md:flex-row md:text-left">
                <div className="mb-8 md:mb-0">
                  <img
                    src={product.image}
                    alt={`Product Image ${index}`}
                    className="max-w-full h-60 rounded-lg shadow-lg md:max-w-sm md:mr-10"
                  />
                </div>
                <div>
                  <h1 className="font-bold text-2xl md:text-3xl text-blue-400">{product.title}</h1>
                  <p className="text-gray-300 text-lg md:text-xl mt-2">{product.desc}</p>
                  <div className="flex flex-row gap-4 mt-6 justify-center md:justify-start">
                    <ButtonLink
                      to={`/products/${product._id}`}
                      className="border border-blue-500 text-blue-500 py-2 px-4 rounded-lg hover:bg-blue-500 hover:text-white transition-colors duration-300"
                    >
                      Read more
                    </ButtonLink>
                    <FlyingButton main _id={product._id} src={product.images}/>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Carousel>
      </Center>
      </StyledRevealWrapper>
    </div>
  );
}
