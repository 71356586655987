import React, { useState, useEffect } from "react";
import Input from "../components/Input";
import WhiteBox from "../components/WhiteBox";
import Textarea from "../components/Textarea";
import Button from "../components/Button";
import axios from "axios";
import Spinner from "../components/Spinner";
import { isNotLogged } from "../auth/AuthContext";
import Swal from 'sweetalert2';
import { api_url, decodedToken, decodedUserID } from "../api/config";
import { message, Rate } from "antd";
import { FaStar, FaPen, FaListAlt } from 'react-icons/fa';
import { format } from "date-fns";
import Turnstile from 'react-turnstile';

export default function ProductReviews({ product, setTotalRating }) {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [stars, setStars] = useState(0);
  const [reviews, setReviews] = useState([]);
  const [reviewsLoading, setReviewsLoading] = useState(false);
  const [isNotLoggedIn, setIsNotLoggedIn] = useState(false);
  const [userReviews, setUserReviews] = useState([]);
  const [canSubmit, setCanSubmit] = useState(true);
  const [nextSubmissionTime, setNextSubmissionTime] = useState(null);
  const [turnstileToken, setTurnstileToken] = useState('');
  const [errors, setErrors] = useState({});

  useEffect(() => {
    loadReviews();
  }, []);

  useEffect(() => {
    if (isNotLoggedIn) {
      Swal.fire({
        title: "Please Login",
        text: "You need to login to submit a review.",
        icon: "info",
        showCancelButton: true,
        confirmButtonText: "Login",
        cancelButtonText: "Cancel",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.href = "/login";
        }
        setIsNotLoggedIn(false);
      });
    }
  }, [isNotLoggedIn]);

  function loadReviews() {
    setReviewsLoading(true);
    axios.get(`${api_url}/products/${product._id}/reviews`, {
      headers: {
        Authorization: decodedToken,
      },
    })
      .then((res) => {
        setReviews(res.data);
        const userSpecificReviews = res.data.filter(review => review.user === decodedUserID);
        setUserReviews(userSpecificReviews);
        checkSubmissionEligibility(userSpecificReviews);
        setReviewsLoading(false);
        calculateTotalRating(res.data);
      }).catch((error) => {
        console.error("Error loading reviews:", error);
      });
  }

  function calculateTotalRating(reviews) {
    if (reviews.length > 0) {
      const totalRating = reviews.reduce((acc, review) => acc + review.stars, 0) / reviews.length;
      setTotalRating(totalRating);
    } else {
      setTotalRating(0);
    }
  }

  function checkSubmissionEligibility(reviews) {
    if (reviews.length >= 2) {
      const latestReview = reviews.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))[0];
      const timeSinceLastReview = Date.now() - new Date(latestReview.createdAt).getTime();
      if (timeSinceLastReview < 3 * 60 * 60 * 1000) {
        setCanSubmit(false);
        setNextSubmissionTime(new Date(latestReview.createdAt).getTime() + 3 * 60 * 60 * 1000);
      } else {
        setCanSubmit(true);
        setNextSubmissionTime(null);
      }
    } else {
      setCanSubmit(true);
      setNextSubmissionTime(null);
    }
  }

  async function submitReview() {
    const errors = {};

    if (isNotLogged()) {
      setIsNotLoggedIn(true);
      return;
    }

    if (!canSubmit) {
      message.error("You can only submit up to 2 reviews within 3 hours.");
      return;
    }

    if (!turnstileToken) {
      errors.turnstile = "Verification is required";
      setErrors(errors);
      return;
    }

    // Add Turnstile verification
    try {
      const turnstileResponse = await axios.post(`${api_url}/turnstile/verify-turnstile`, {
        token: turnstileToken
      });
      if (turnstileResponse.data.success === false) {
        setErrors({ turnstile: 'Verification failed' });
        return;
      }
    } catch (error) {
      console.error("Error verifying Turnstile:", error);
      setErrors({ turnstile: 'Verification failed' });
      return;
    }

    const data = { title, desc: description, stars, productId: product._id, userId: decodedUserID };
    axios.post(`${api_url}/products/${product._id}/reviews`, data, {
      headers: {
        Authorization: decodedToken,
      },
    })
      .then((res) => {
        setTitle("");
        setDescription("");
        setStars(0);
        setTurnstileToken(''); // Reset the Turnstile token after successful submission
        loadReviews();
        message.success("Review submitted successfully!");
      })
      .catch((error) => {
        message.error("Failed to submit review. Please try again.");
        console.error("Error submitting review:", error);
      });
  }

  return (
    <div className="w-full mx-auto p-6">
      <h2 className="text-gray-800 text-2xl font-semibold mb-6 flex items-center">
        <FaListAlt className="mr-2" /> Reviews
      </h2>
      <div className="grid grid-cols-1 gap-8 lg:grid-cols-2">
        <div className="lg:sticky lg:top-0">
          <WhiteBox className="p-6 shadow-md rounded-lg">
            <h3 className="text-gray-700 text-lg font-medium mb-4 flex items-center">
              <FaPen className="mr-2" /> Add a review
            </h3>
            <div className="mb-2">
              <label className="block text-gray-700 text-sm font-medium mb-2">Rating</label>
              <Rate onChange={setStars} value={stars} character={<FaStar />} className="text-2xl" disabled={!canSubmit} />
            </div>
            <div className="mb-2">
              <label className="block text-gray-700 text-sm font-medium mb-2">Title</label>
              <Input
                value={title}
                onChange={(ev) => setTitle(ev.target.value)}
                placeholder="Title"
                className="w-full p-2 border border-gray-300 rounded-md text-black"
                disabled={!canSubmit}
              />
            </div>
            <div className="mb-2">
              <label className="block text-gray-700 text-sm font-medium mb-2">Comment</label>
              <Textarea
                value={description}
                onChange={(ev) => setDescription(ev.target.value)}
                placeholder="Was it good? Pros? Cons?"
                className="w-full p-2 border border-gray-300 rounded-md text-black"
                disabled={!canSubmit}
              />
            </div>

            <div className="mt-2 mb-2">
              <Turnstile
                sitekey={process.env.REACT_APP_TURNSTILE_SITE_KEY}
                onVerify={setTurnstileToken}
                theme="light"
              />
              {errors.turnstile && (
                <p className="text-xs text-red-600 mt-1">
                  {errors.turnstile}
                </p>
              )}
            </div>

            <div>
              <Button
                primary
                onClick={submitReview}
                className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
                disabled={!canSubmit}
              >
                Submit your review
              </Button>
              {!canSubmit && nextSubmissionTime && (
                <p className="text-red-500 mt-2">
                  You have reached the limit of 2 reviews in 3 hours. You can submit your next review after {format(nextSubmissionTime, 'PPPpp')}.
                </p>
              )}
            </div>
          </WhiteBox>
        </div>
        <div>
          <WhiteBox className="p-6 shadow-md rounded-lg">
            <h3 className="text-gray-700 text-lg font-medium mb-4 flex items-center">
              <FaListAlt className="mr-2" /> All reviews
            </h3>
            {reviewsLoading && <Spinner fullWidth={true} />}
            {reviews.length === 0 && <p className="text-gray-600">No reviews yet.</p>}
            {reviews.length > 0 && reviews.map((review) => (
              <div key={review._id} className="mb-2 p-4 border border-gray-200 rounded-lg shadow-sm">
                <div className="flex justify-between items-center mb-2">
                  <Rate disabled value={review.stars} character={<FaStar />} className="text-2xl" />
                  <time className="text-xs text-gray-400">{format(new Date(review.createdAt), 'PPPpp')}</time>
                </div>
                <h3 className="text-gray-800 text-lg font-medium mb-1">{review.title}</h3>
                <p className="text-gray-600 text-sm">{review.desc}</p>
              </div>
            ))}
          </WhiteBox>
        </div>
      </div>
    </div>
  );
}
