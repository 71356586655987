import React from "react";
import { ReactTyped } from "react-typed";
import Center from "./Center";
import welcomeImage from "../assets/welcome.png"; // Import your welcome image

const Welcome = () => {
  return (
    <div className="w-full px-4 py-12 bg-gradient-to-r from-gray-900 via-gray-800 to-gray-900 text-white">
      <Center>
        <div className="mx-auto max-w-screen-xl lg:grid lg:grid-cols-5 gap-8 items-center">
          <div className="lg:col-span-3">
            <h1 className="text-4xl font-extrabold mb-4">Welcome to PICLAB!</h1>
            <ReactTyped
              strings={[
                "Join a vibrant community of photographers.",
                "Share your photos and stories.",
                "Explore inspiring content.",
                "Earn from your photography."
              ]}
              typeSpeed={40}
              backSpeed={50}
              loop
              className="text-2xl font-semibold text-emerald-400 mb-6"
            />
            <p className="text-lg text-gray-300 leading-relaxed">
              Start your creative journey with <span className="text-emerald-400 font-bold">PICLAB</span>. Share your passion for photography, discover new perspectives, and connect with fellow enthusiasts.
            </p>
          </div>
          <div className="lg:col-span-2 flex justify-center items-center">
            <img src={welcomeImage} alt="Welcome" className="max-w-full h-auto rounded-lg shadow-lg" />
          </div>
        </div>
      </Center>
    </div>
  );
};

export default Welcome;
